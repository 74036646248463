/* Image carousel / OwlCarousel
  /* ========================================================================== */

.img-carousel {
	
	 .owl-nav button.owl-next, .owl-nav button.owl-prev {				 
			position: absolute;
			top: 50%;
			margin: -20px 0 0 0;
			padding: 0;
			width: 40px;
			height: 40px;
			border-radius: 0;
			font-size: 30px;
			line-height: 30px;
			border: solid 3px #14181c;
			background: transparent;
			color: #14181c;
			display: flex;
			align-items: center;
			justify-content: center;
			
			&:hover {
				background: #14181c;
				color: #ffffff;
			}
			.owl-prev {
				left: 30px;
			}
			.owl-next {
				right: 30px;
			}
		}
		
	.owl-dots {
		position: absolute;
		width: 100%;
		bottom: 0;
		.owl-dot {
			span {
				background-color: #a5abb7;
			}
		}
	}
		
}	


.parking-big-card {
	.img-carousel {		
		.owl-nav {
			.owl-prev,.owl-next{
				border: none;
				line-height: 40px;
				background: rgba(0, 0, 0, 0.5);
				color: #ffffff;
				&:hover{
					background: rgba(0, 0, 0, 0.8);
					color: #ffffff;
				}
			}

			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}			
		}
		.btn-zoom {
			position: absolute;
			padding: 0;
			line-height: 30px;
			width: 30px;
			height: 30px;
			right: 10px;
			top: 10px;
			z-index: 1;
			background-color: rgba(0, 0, 0, 0.3);
			color: #ffffff;
			&:hover {
				background-color: rgba(0, 0, 0, 0.8);
			}
			.fa {
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}
		}
	}
}
@media (max-width: 639px) {
.img-carousel.owl-theme .owl-controls {
	display: none;
}
}
