/* 5.13 */
/* Widgets
/* ========================================================================== */

.widget-title {
	margin-top: 0;
	margin-bottom: 25px;
	font-size: 15px;
	font-weight: 900;
	line-height: 20px;
	text-transform: uppercase;
	color: #7f7f7f;
	overflow: hidden;
}
.widget-title-sub {
	margin: 0 0 20px 0;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	border-bottom: dashed 1px #ececec;
	color: #6e757f;
}
.widget {
	&.shadow {
		background-color: #ffffff;
		border: solid 1px #f0f0f0;
		-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
		-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    .widget-title {
			padding: 25px 20px;
			margin: 0;
			background-color: #f5f6f8;
			color: #6c7481;
		}
		.widget-title-sub {
			padding-left: 20px;
			padding-right: 20px;
			margin-left: -20px;
			margin-right: -20px;
		}
		.widget-content {
			padding: 20px;
		}
  }	
}


  /* Widget colors
  /* ========================================================================== */

  .widget {
    &.widget-colors {
      border: solid 3px rgba(20, 24, 28, 0.1);
      .widget-title {
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 15px;
      }
      .widget-content {
        border-top: solid 1px rgba(20, 24, 28, 0.1);
        padding: 10px 15px;
      }
      ul {
        overflow: hidden;
        margin-top: -7px;

        li {          
          display: inline-block;
          margin: 7px 7px 0 0;
          a {
            display: block;
            width: 35px;
            height: 35px;
            border: solid 3px rgba(20, 24, 28, 0.1);
            padding: 3px;
            span {
              display: block;
              width: 23px;
              height: 23px;
            }
            &:hover {
              border-color: #14181c;
            }
          }
        }
      }
    }
  }

  /* Widget filter price
  /* ========================================================================== */

  .widget {
    &.widget-filter-price {
      .widget-content {
        border-top: solid 1px rgba(20, 24, 28, 0.1);
        padding: 20px 15px;
        overflow: hidden;
      }
      #slider-range {
        margin-top: 5px;
        margin-bottom: 25px;
        border-radius: 0;
        border: none;
        background-color: #e9e9e9;
        background-image: none;
        height: 6px;
        .ui-widget-header {
          background-color: var(--bs-primary);
          background-image: none;
          height: 4px;
        }
        .ui-slider-handle {
          background-image: none;
          background-color: #14181c;
          border: solid 3px #14181c;
          border-radius: 0;
          width: 8px;
          height: 15px;
          margin-left: 0;
        }
      }
      #amount {        
        text-align: right;
        padding: 0;
        margin: 0;
        background-color: transparent;
        border-color: transparent;
        border: none;
        font-size: 15px;
        font-weight: 700;
        color: #7f7f7f;
        width: 120px;
        line-height: 33px;
      }
      .btn-submit {
        margin-top: 10px;
      }
    }
    &.widget-find {
      .btn-submit {
        margin-top: 20px;
      }
      .btn-theme-dark {
        &:hover {
          background-color: var(--bs-primary);
          border-color: var(--bs-primary);
          color: #ffffff;
        }
      }
    }
  }
  
  .widget.widget-find .btn-theme-dark,
    .widget.widget-find .btn-theme-dark:focus,
    .widget.widget-find .btn-theme-dark:active {
    background-color: #14181c;
    border-color: #14181c;
    color: #ffffff;
  } 
  
  /* Widget shop deals
  /* ========================================================================== */
  .widget {
    &.widget-shop-deals {
      .countdown-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      .defaultCountdown {
        background-color: transparent;
        border-color: transparent;
        border: none;
        width: 100%;
      }
      .countdown-row {
        overflow: hidden;
        display: block;
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
      }
      .countdown-section {
        background-color: rgba(0, 0, 0, 0.5);
      }
      .countdown-show4 {
        .countdown-section {
          padding-top: 8px;
          padding-bottom: 9px;
          width: 25% !important;
        }
      }
      .countdown-amount {
        margin: 0 0 0 0;
        font-size: 15px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        color: var(--bs-primary);
      }
      .countdown-period {
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        color: #d3d3d3;
        clear: both;
        margin: 0 0 0 0;
        display: block;
        position: relative;
        overflow: hidden;
      }
    }
  }
  
  
  @media (min-width: 768px) {
    .blog-tabs {
        text-decoration: none;
        position: relative;
        &.active{
            background-color: #14181c !important;
            &::before{
                content: '';
                display: block;
                position: absolute;
                top: -5px;
                left: 0;
                width: 100%;
                height: 5px;
                border-top: solid 5px var(--bs-primary);
            }      
        }
    }    
}
  .widget {
    &.widget-tabs {
      .nav-justified {
        border: solid 3px rgba(20, 24, 28, 0.1);
         li {            
           a {
            color: #14181c;
            background-color: transparent;            
            font-size: 14px;
            font-weight: 700;            
            text-transform: uppercase;
          }
        }
      }
      .tab-content {        
        overflow: hidden;
        & + .btn-block {
          margin: 20px auto 0 auto;
        }
      }
      &.alt {
        margin-top: 9px;       

        .nav-justified {
          margin-top: 2px;
          border: none;
          & > li {
            & > a {
              color: #ffffff;
              background-color: #a5abb7;
              margin: 0;
              font-size: 14px;
              font-weight: 700;
              padding-left: 12px;
              padding-right: 12px;
              text-transform: uppercase;
            }
          }
        }
        .tab-content {
          margin-top: 20px;
          overflow: hidden;
          & + .btn-block {
            margin: 20px auto 0 auto;
          }
        }
      }
    }
    #widget-tabs{
      li{
        display: table;
        width: 100%;        
        a{
          display: table-cell;
          text-align: center;
          padding: 6px 0;   
          &.active , &:hover ,&:focus{  
            position: relative;     
            
            &::before{
                content: '';
                display: block;
                position: absolute;
                top: -3px;
                left: 0;
                width: 100%;
                height: 5px;
                border-top: solid 3px var(--bs-primary);                
              } 
            &::after{
              content: '';
              display: block;
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 5px;
              border-bottom: solid 3px var(--bs-primary);
            }     
          }       
        }
      }
    }
    #tabs{
      a{
        padding: 12px 30px;
      }
    }
  }
  .widget.widget-tabs .nav-justified > li > a.active,
  .widget.widget-tabs .nav-justified > li > a:hover,
  .widget.widget-tabs .nav-justified > li > a:focus {   
    border-color: var(--bs-primary);
    background-color: var(--bs-primary);
    color: #ffffff;
  }
  .widget.widget-tabs.alt .nav-justified > li > a.active,
    .widget.widget-tabs.alt .nav-justified > li > a:hover,
    .widget.widget-tabs.alt .nav-justified > li > a:focus {
    border-color: #14181c;
    background-color: #14181c;
    color: #ffffff;
  }
  

  @media (min-width: 768px) {
    .widget.widget-tabs.alt .nav-justified > li > a.active {
      position: relative;
    }
    .widget.widget-tabs.alt .nav-justified > li > a.active &:before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: 0;
      width: 100%;
      height: 5px;
      border-top: solid 5px var(--bs-primary);
    }
  }

  @media (min-width: 768px) {
    .widget.widget-tabs.alt .nav-justified > li + li {
      border-left: solid 1px transparent;
    }
  }


  /* Widget search
  /* ========================================================================== */
  .widget-search {
    max-width: 100%;
    position: relative;
    .form-control {
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
    button {
      position: absolute;
      right: 3px;
      top: 3px;
      background-color: #ffffff;
      border: none;
      padding: 2px 8px;
      line-height: 30px;
      font-size: 16px;
      color: #14181c;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -34px;
        width: 34px;
        height: 34px;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      }
    }
  }

  /* Widget categories
  /* ========================================================================== */
  .widget-categories {
    ul {
      li {
        & + li {
          border-top: solid 1px rgba(0, 0, 0, 0.1);
        }
        a {
          display: block;
          padding: 5px 0 5px 20px;
          position: relative;
          &:before {
            content: '\f0a9';
            font-family: 'FontAwesome';
            position: absolute;
            left: 0;
            top: 5px;
          }
          &:hover {
            &:before {
              -webkit-animation: bounceIn .4s;
              animation: bounceIn .4s;
            }
          }
        }
      }
    }
  }
  
  /* Widget tabs
  /* ========================================================================== */

  .widget {
    &.parking-categories {
      .widget-content {
        border-top: solid 1px rgba(20, 24, 28, 0.1);
        padding: 20px 15px;
      }
      ul {
        margin: -10px -15px -10px -15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        text-transform: uppercase;
        li {
          padding: 10px 15px;
          overflow: hidden;
          & + li {
            border-top: solid 1px rgba(20, 24, 28, 0.1);
          }
        }
        a {
          display: block;
          margin-right: 20px;
          color: #14181c;
          &:hover {
            color: var(--bs-primary);
          }
          .count {
            float: right;
            text-align: right;
            font-size: 12px;
            margin-right: 2px;
          }
        }
        ul {
          &.children {
            font-size: 13px;
            font-weight: 700;
            line-height: 15px;
            text-transform: none;
            margin-top: 5px;
            padding-left: 30px;
            li {
              padding: 7px 15px 7px 0;
            }
            a {
              margin-right: 0;
            }
          }
        }
        .count {
          float: right;
          font-size: 12px;
          margin-right: 2px;
        }
        .arrow {
          float: right;
          border: solid 1px #14181c;
          color: #14181c;
          width: 17px;
          height: 17px;
          cursor: pointer;
          text-align: center;
          .fa {
            font-size: 17px;
            line-height: 14px;
          }
        }
      }
    }
  }  

  /* Widget Details Reservation
  /* ========================================================================== */
  .widget {
    &.widget-details-reservation {
      .widget-content {
        padding-top: 0;
      }
      .media {
        .media-object {
          font-size: 14px;
          .fa {
            font-size: 13px;
            vertical-align: top;
            margin-top: 5px;
          }
        }
        & + .media {
          margin-top: 0;
        }
      }
      .widget-title-sub {
        padding-top: 30px;
      }
      .button {
        margin-top: 40px;
      }
    }
  }
  
  /* Widget Helping Center
  /* ========================================================================== */
  .widget {
    &.widget-helping-center {
      .widget-title-sub {
        border-top: dashed 1px #ececec;
        font-size: 24px;
        font-weight: 400;
      }
      a {
        &:not(.btn) {
          color: #7f7f7f;
        }
        &:not(.btn):hover {
          color: #3c3c3c;
        }
      }
    }
  }
  .helping-center-line {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    background-color: #f5f6f8;
    h4 {
      display: inline-block;
      font-size: 16px;
      font-weight: 900;
      text-transform: uppercase;
      color: #6d737f;
      float: left;
      margin-top: 15px;
    }
    span {
      display: inline-block;
      font-size: 20px;
      margin-top: 5px;
    }
    
  }   

  /* Widget tag cloud
  /* ========================================================================== */
  .widget-tag-cloud {
    ul {
      overflow: hidden;
      margin-top: -7px;
      li {
        display: inline-block;
        margin-top: 7px;
        margin-right: 5px;
        a {
          display: block;
          padding: 4px 11px;
          font-size: 14px;
          line-height: 22px;
          color: #14181c;
          border: solid 3px rgba(20, 24, 28, 0.1);
          &:hover {
            color: #ffffff;
            background-color: #14181c;
            border-color: #14181c;
          }
        }
      }
    }
  }
  
  

  .widget-flickr-feed {
    ul {
      overflow: hidden;
      margin-left: -10px;
      margin-bottom: -10px;
      li {
        display: inline-block;
        margin: 0 0 10px 10px;
      }
      a {
        display: block;
        border: solid 3px rgba(20, 24, 28, 0.1);
        overflow: hidden;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: transparent;
        }
        &:after {
          content: '\f002';
          font-size: 14px;
          font-family: 'FontAwesome';
          position: absolute;
          width: 14px;
          height: 14px;
          margin-left: -7px;
          margin-top: -9px;
          text-align: center;
          top: 50%;
          left: 50%;
          color: #ffffff;
          opacity: 0;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }
        &:hover {
          border-color: var(--bs-primary);
          &:before {
            background-color: rgba(230, 0, 0, 0.7);
          }
          &:after {
            opacity: 1;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
          }
        }
        img {
          width: 74px;
          height: auto;
        }
      }
    }
  }
  
  @media (max-width: 1199px) {
    .widget-flickr-feed ul a img {
      width: 58px;
    }
  }
  @media (max-width: 991px) {
    .widget-flickr-feed ul a img {
      width: 70px;
    }
  }
  @media (max-width: 767px) {
    .widget-flickr-feed ul a img {
      width: 70px;
    }
  }
  /* Recent Tweets Widget
  /* ========================================================================== */
  /* ========================================================================== */
  .recent-tweets {
    .media {
      .fa {
        color: var(--bs-primary);
      }
      p {
        margin-bottom: 0;
      }
      small {
        color: #d3d3d3;
      }
    }
  }