/* --------------------------------------------------------------------------
 * jThemes Studio : RENT IT - Car Rental Management Template
 *
 * file           : theme.css
 * Desc           : RENT IT Template - Stylesheet
 * Version        : 1.3.1
 * Date           : 2016-04-01
 * Author         : jThemes Studio
 * Author URI     : http://jakjim.com
 * Email          : jakjim@gmail.com
 *
 * jThemes Studio. Copyright 2015. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 *  RENT IT Template - Table of Content

  1 - General
    1.1 - Preloader
    1.2 - Global properties (body, common classes, structure etc)
    1.3 - Page section block
    1.4 - Typography (section title, links, page dividers)
    1.5 - Buttons
    1.6 - Form / Input / Textarea
    1.7 - Top Bar

  2 - Header
    2.1 - Logo
    2.2 - Navigation

  3 - Footer
    3.1 - Footer widgets
    3.2 - Footer meta

  4 - Sliders / OwlCarousel
    4.1 - Main slider
    4.2 - Partners carousel
    4.3 - Testimonials carousel
    4.4 - Products carousel

  5 - Components / Shortcodes / Widget
    5.1 - Breadcrumbs
    5.2 - Pagination
    5.3 - Message box
    5.4 - Social icons line
    5.5 - Rating stars
    5.6 - Content tabs
    5.7 - Accordion
    5.8 - Blog post
    5.9 - Recent post
    5.10 - Comments
    5.11 - Thumbnails
    5.12 - Portfolio
    5.13 - Widgets
    5.14 - Contacts form
    5.15 - Google map
    5.16 - Back to top button

  6 - Helper Classes

 * -------------------------------------------------------------------------- */
/*!
   * Bootstrap  v5.2.3 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root {
  --bs-blue: #1f497d;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #1f497d;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-default: #e60000;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #e60000;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #1f497d;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 230, 0, 0;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #e60000;
  --bs-link-hover-color: #b80000;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 0.625rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 0.625rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 1.25rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 1.25rem;
}

.g-30,
.gx-30 {
  --bs-gutter-x: 1.875rem;
}

.g-30,
.gy-30 {
  --bs-gutter-y: 1.875rem;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 2.5rem;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 2.5rem;
}

.g-60,
.gx-60 {
  --bs-gutter-x: 3.75rem;
}

.g-60,
.gy-60 {
  --bs-gutter-y: 3.75rem;
}

.g-70,
.gx-70 {
  --bs-gutter-x: 4.375rem;
}

.g-70,
.gy-70 {
  --bs-gutter-y: 4.375rem;
}

.g-80,
.gx-80 {
  --bs-gutter-x: 5rem;
}

.g-80,
.gy-80 {
  --bs-gutter-y: 5rem;
}

.g-90,
.gx-90 {
  --bs-gutter-x: 5.625rem;
}

.g-90,
.gy-90 {
  --bs-gutter-y: 5.625rem;
}

.g-100,
.gx-100 {
  --bs-gutter-x: 6.25rem;
}

.g-100,
.gy-100 {
  --bs-gutter-y: 6.25rem;
}

.g-120,
.gx-120 {
  --bs-gutter-x: 7.5rem;
}

.g-120,
.gy-120 {
  --bs-gutter-y: 7.5rem;
}

.g-140,
.gx-140 {
  --bs-gutter-x: 8.75rem;
}

.g-140,
.gy-140 {
  --bs-gutter-y: 8.75rem;
}

.g-150,
.gx-150 {
  --bs-gutter-x: 9.375rem;
}

.g-150,
.gy-150 {
  --bs-gutter-y: 9.375rem;
}

.g-180,
.gx-180 {
  --bs-gutter-x: 11.25rem;
}

.g-180,
.gy-180 {
  --bs-gutter-y: 11.25rem;
}

.g-200,
.gx-200 {
  --bs-gutter-x: 12.5rem;
}

.g-200,
.gy-200 {
  --bs-gutter-y: 12.5rem;
}

.g-230,
.gx-230 {
  --bs-gutter-x: 14.375rem;
}

.g-230,
.gy-230 {
  --bs-gutter-y: 14.375rem;
}

.g-250,
.gx-250 {
  --bs-gutter-x: 15.625rem;
}

.g-250,
.gy-250 {
  --bs-gutter-y: 15.625rem;
}

.g-300,
.gx-300 {
  --bs-gutter-x: 18.75rem;
}

.g-300,
.gy-300 {
  --bs-gutter-y: 18.75rem;
}

.g-350,
.gx-350 {
  --bs-gutter-x: 21.87rem;
}

.g-350,
.gy-350 {
  --bs-gutter-y: 21.87rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-30,
  .gx-sm-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-30,
  .gy-sm-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-40,
  .gx-sm-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-40,
  .gy-sm-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-60,
  .gx-sm-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-60,
  .gy-sm-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-70,
  .gx-sm-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-sm-70,
  .gy-sm-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-sm-80,
  .gx-sm-80 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-80,
  .gy-sm-80 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-90,
  .gx-sm-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-sm-90,
  .gy-sm-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-sm-100,
  .gx-sm-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-sm-100,
  .gy-sm-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-sm-120,
  .gx-sm-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-sm-120,
  .gy-sm-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-sm-140,
  .gx-sm-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-sm-140,
  .gy-sm-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-sm-150,
  .gx-sm-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-sm-150,
  .gy-sm-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-sm-180,
  .gx-sm-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-sm-180,
  .gy-sm-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-sm-200,
  .gx-sm-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-sm-200,
  .gy-sm-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-sm-230,
  .gx-sm-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-sm-230,
  .gy-sm-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-sm-250,
  .gx-sm-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-sm-250,
  .gy-sm-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-sm-300,
  .gx-sm-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-sm-300,
  .gy-sm-300 {
    --bs-gutter-y: 18.75rem;
  }
  .g-sm-350,
  .gx-sm-350 {
    --bs-gutter-x: 21.87rem;
  }
  .g-sm-350,
  .gy-sm-350 {
    --bs-gutter-y: 21.87rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-30,
  .gx-md-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-30,
  .gy-md-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-40,
  .gx-md-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-40,
  .gy-md-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-60,
  .gx-md-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-60,
  .gy-md-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-70,
  .gx-md-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-md-70,
  .gy-md-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-md-80,
  .gx-md-80 {
    --bs-gutter-x: 5rem;
  }
  .g-md-80,
  .gy-md-80 {
    --bs-gutter-y: 5rem;
  }
  .g-md-90,
  .gx-md-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-md-90,
  .gy-md-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-md-100,
  .gx-md-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-md-100,
  .gy-md-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-md-120,
  .gx-md-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-md-120,
  .gy-md-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-md-140,
  .gx-md-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-md-140,
  .gy-md-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-md-150,
  .gx-md-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-md-150,
  .gy-md-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-md-180,
  .gx-md-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-md-180,
  .gy-md-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-md-200,
  .gx-md-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-md-200,
  .gy-md-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-md-230,
  .gx-md-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-md-230,
  .gy-md-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-md-250,
  .gx-md-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-md-250,
  .gy-md-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-md-300,
  .gx-md-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-md-300,
  .gy-md-300 {
    --bs-gutter-y: 18.75rem;
  }
  .g-md-350,
  .gx-md-350 {
    --bs-gutter-x: 21.87rem;
  }
  .g-md-350,
  .gy-md-350 {
    --bs-gutter-y: 21.87rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-30,
  .gx-lg-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-30,
  .gy-lg-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-40,
  .gx-lg-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-40,
  .gy-lg-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-60,
  .gx-lg-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-60,
  .gy-lg-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-70,
  .gx-lg-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-lg-70,
  .gy-lg-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-lg-80,
  .gx-lg-80 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-80,
  .gy-lg-80 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-90,
  .gx-lg-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-lg-90,
  .gy-lg-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-lg-100,
  .gx-lg-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-lg-100,
  .gy-lg-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-lg-120,
  .gx-lg-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-lg-120,
  .gy-lg-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-lg-140,
  .gx-lg-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-lg-140,
  .gy-lg-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-lg-150,
  .gx-lg-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-lg-150,
  .gy-lg-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-lg-180,
  .gx-lg-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-lg-180,
  .gy-lg-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-lg-200,
  .gx-lg-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-lg-200,
  .gy-lg-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-lg-230,
  .gx-lg-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-lg-230,
  .gy-lg-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-lg-250,
  .gx-lg-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-lg-250,
  .gy-lg-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-lg-300,
  .gx-lg-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-lg-300,
  .gy-lg-300 {
    --bs-gutter-y: 18.75rem;
  }
  .g-lg-350,
  .gx-lg-350 {
    --bs-gutter-x: 21.87rem;
  }
  .g-lg-350,
  .gy-lg-350 {
    --bs-gutter-y: 21.87rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-30,
  .gx-xl-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-30,
  .gy-xl-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-40,
  .gx-xl-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-40,
  .gy-xl-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-60,
  .gx-xl-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-60,
  .gy-xl-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xl-70,
  .gx-xl-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xl-70,
  .gy-xl-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xl-80,
  .gx-xl-80 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-80,
  .gy-xl-80 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-90,
  .gx-xl-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xl-90,
  .gy-xl-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xl-100,
  .gx-xl-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xl-100,
  .gy-xl-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xl-120,
  .gx-xl-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xl-120,
  .gy-xl-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xl-140,
  .gx-xl-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xl-140,
  .gy-xl-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xl-150,
  .gx-xl-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xl-150,
  .gy-xl-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xl-180,
  .gx-xl-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-xl-180,
  .gy-xl-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-xl-200,
  .gx-xl-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xl-200,
  .gy-xl-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-xl-230,
  .gx-xl-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-xl-230,
  .gy-xl-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-xl-250,
  .gx-xl-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-xl-250,
  .gy-xl-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-xl-300,
  .gx-xl-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-xl-300,
  .gy-xl-300 {
    --bs-gutter-y: 18.75rem;
  }
  .g-xl-350,
  .gx-xl-350 {
    --bs-gutter-x: 21.87rem;
  }
  .g-xl-350,
  .gy-xl-350 {
    --bs-gutter-y: 21.87rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-30,
  .gx-xxl-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-30,
  .gy-xxl-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-40,
  .gx-xxl-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-40,
  .gy-xxl-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-60,
  .gx-xxl-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-60,
  .gy-xxl-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxl-70,
  .gx-xxl-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xxl-70,
  .gy-xxl-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xxl-80,
  .gx-xxl-80 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-80,
  .gy-xxl-80 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-90,
  .gx-xxl-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xxl-90,
  .gy-xxl-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xxl-100,
  .gx-xxl-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xxl-100,
  .gy-xxl-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xxl-120,
  .gx-xxl-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xxl-120,
  .gy-xxl-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xxl-140,
  .gx-xxl-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xxl-140,
  .gy-xxl-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xxl-150,
  .gx-xxl-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xxl-150,
  .gy-xxl-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xxl-180,
  .gx-xxl-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-xxl-180,
  .gy-xxl-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-xxl-200,
  .gx-xxl-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xxl-200,
  .gy-xxl-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-xxl-230,
  .gx-xxl-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-xxl-230,
  .gy-xxl-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-xxl-250,
  .gx-xxl-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-xxl-250,
  .gy-xxl-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-xxl-300,
  .gx-xxl-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-xxl-300,
  .gy-xxl-300 {
    --bs-gutter-y: 18.75rem;
  }
  .g-xxl-350,
  .gx-xxl-350 {
    --bs-gutter-x: 21.87rem;
  }
  .g-xxl-350,
  .gy-xxl-350 {
    --bs-gutter-y: 21.87rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #facccc;
  --bs-table-border-color: #e1b8b8;
  --bs-table-striped-bg: #eec2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1b8b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7bdbd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #f38080;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(230, 0, 0, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #f38080;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(230, 0, 0, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #f38080;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(230, 0, 0, 0.25);
}

.form-check-input:checked {
  background-color: #e60000;
  border-color: #e60000;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #e60000;
  border-color: #e60000;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f38080'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(230, 0, 0, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(230, 0, 0, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #e60000;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #f8b3b3;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #e60000;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #f8b3b3;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #1f497d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #1f497d;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #1f497d;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #1f497d;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #1f497d;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #1f497d;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #1f497d;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #1f497d;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e60000;
  --bs-btn-border-color: #e60000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c40000;
  --bs-btn-hover-border-color: #b80000;
  --bs-btn-focus-shadow-rgb: 234, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b80000;
  --bs-btn-active-border-color: #ad0000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e60000;
  --bs-btn-disabled-border-color: #e60000;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1f497d;
  --bs-btn-border-color: #1f497d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1f497d;
  --bs-btn-disabled-border-color: #1f497d;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #e60000;
  --bs-btn-border-color: #e60000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e60000;
  --bs-btn-hover-border-color: #e60000;
  --bs-btn-focus-shadow-rgb: 230, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e60000;
  --bs-btn-active-border-color: #e60000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e60000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e60000;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #1f497d;
  --bs-btn-border-color: #1f497d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1f497d;
  --bs-btn-hover-border-color: #1f497d;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1f497d;
  --bs-btn-active-border-color: #1f497d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1f497d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1f497d;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 234, 38, 38;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #e60000;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #e60000;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #e60000;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23cf0000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #f38080;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(230, 0, 0, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #cf0000;
  --bs-accordion-active-bg: #fde6e6;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(230, 0, 0, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #e60000;
  --bs-pagination-active-border-color: #e60000;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #8a0000;
  --bs-alert-bg: #facccc;
  --bs-alert-border-color: #f8b3b3;
}

.alert-primary .alert-link {
  color: #6e0000;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #e60000;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #e60000;
  --bs-list-group-active-border-color: #e60000;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #8a0000;
  background-color: #facccc;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #8a0000;
  background-color: #e1b8b8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #8a0000;
  border-color: #8a0000;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(230, 0, 0, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(230, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #e60000 !important;
}

.link-primary:hover, .link-primary:focus {
  color: #b80000 !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}

.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #1f497d !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-10 {
  margin: 0.625rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}

.m-30 {
  margin: 1.875rem !important;
}

.m-40 {
  margin: 2.5rem !important;
}

.m-60 {
  margin: 3.75rem !important;
}

.m-70 {
  margin: 4.375rem !important;
}

.m-80 {
  margin: 5rem !important;
}

.m-90 {
  margin: 5.625rem !important;
}

.m-100 {
  margin: 6.25rem !important;
}

.m-120 {
  margin: 7.5rem !important;
}

.m-140 {
  margin: 8.75rem !important;
}

.m-150 {
  margin: 9.375rem !important;
}

.m-180 {
  margin: 11.25rem !important;
}

.m-200 {
  margin: 12.5rem !important;
}

.m-230 {
  margin: 14.375rem !important;
}

.m-250 {
  margin: 15.625rem !important;
}

.m-300 {
  margin: 18.75rem !important;
}

.m-350 {
  margin: 21.87rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-10 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-20 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-30 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-40 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-60 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-70 {
  margin-right: 4.375rem !important;
  margin-left: 4.375rem !important;
}

.mx-80 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-90 {
  margin-right: 5.625rem !important;
  margin-left: 5.625rem !important;
}

.mx-100 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important;
}

.mx-120 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-140 {
  margin-right: 8.75rem !important;
  margin-left: 8.75rem !important;
}

.mx-150 {
  margin-right: 9.375rem !important;
  margin-left: 9.375rem !important;
}

.mx-180 {
  margin-right: 11.25rem !important;
  margin-left: 11.25rem !important;
}

.mx-200 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important;
}

.mx-230 {
  margin-right: 14.375rem !important;
  margin-left: 14.375rem !important;
}

.mx-250 {
  margin-right: 15.625rem !important;
  margin-left: 15.625rem !important;
}

.mx-300 {
  margin-right: 18.75rem !important;
  margin-left: 18.75rem !important;
}

.mx-350 {
  margin-right: 21.87rem !important;
  margin-left: 21.87rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-10 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-30 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-60 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-70 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important;
}

.my-80 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-90 {
  margin-top: 5.625rem !important;
  margin-bottom: 5.625rem !important;
}

.my-100 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important;
}

.my-120 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-140 {
  margin-top: 8.75rem !important;
  margin-bottom: 8.75rem !important;
}

.my-150 {
  margin-top: 9.375rem !important;
  margin-bottom: 9.375rem !important;
}

.my-180 {
  margin-top: 11.25rem !important;
  margin-bottom: 11.25rem !important;
}

.my-200 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.my-230 {
  margin-top: 14.375rem !important;
  margin-bottom: 14.375rem !important;
}

.my-250 {
  margin-top: 15.625rem !important;
  margin-bottom: 15.625rem !important;
}

.my-300 {
  margin-top: 18.75rem !important;
  margin-bottom: 18.75rem !important;
}

.my-350 {
  margin-top: 21.87rem !important;
  margin-bottom: 21.87rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mt-60 {
  margin-top: 3.75rem !important;
}

.mt-70 {
  margin-top: 4.375rem !important;
}

.mt-80 {
  margin-top: 5rem !important;
}

.mt-90 {
  margin-top: 5.625rem !important;
}

.mt-100 {
  margin-top: 6.25rem !important;
}

.mt-120 {
  margin-top: 7.5rem !important;
}

.mt-140 {
  margin-top: 8.75rem !important;
}

.mt-150 {
  margin-top: 9.375rem !important;
}

.mt-180 {
  margin-top: 11.25rem !important;
}

.mt-200 {
  margin-top: 12.5rem !important;
}

.mt-230 {
  margin-top: 14.375rem !important;
}

.mt-250 {
  margin-top: 15.625rem !important;
}

.mt-300 {
  margin-top: 18.75rem !important;
}

.mt-350 {
  margin-top: 21.87rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-10 {
  margin-right: 0.625rem !important;
}

.me-20 {
  margin-right: 1.25rem !important;
}

.me-30 {
  margin-right: 1.875rem !important;
}

.me-40 {
  margin-right: 2.5rem !important;
}

.me-60 {
  margin-right: 3.75rem !important;
}

.me-70 {
  margin-right: 4.375rem !important;
}

.me-80 {
  margin-right: 5rem !important;
}

.me-90 {
  margin-right: 5.625rem !important;
}

.me-100 {
  margin-right: 6.25rem !important;
}

.me-120 {
  margin-right: 7.5rem !important;
}

.me-140 {
  margin-right: 8.75rem !important;
}

.me-150 {
  margin-right: 9.375rem !important;
}

.me-180 {
  margin-right: 11.25rem !important;
}

.me-200 {
  margin-right: 12.5rem !important;
}

.me-230 {
  margin-right: 14.375rem !important;
}

.me-250 {
  margin-right: 15.625rem !important;
}

.me-300 {
  margin-right: 18.75rem !important;
}

.me-350 {
  margin-right: 21.87rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.mb-60 {
  margin-bottom: 3.75rem !important;
}

.mb-70 {
  margin-bottom: 4.375rem !important;
}

.mb-80 {
  margin-bottom: 5rem !important;
}

.mb-90 {
  margin-bottom: 5.625rem !important;
}

.mb-100 {
  margin-bottom: 6.25rem !important;
}

.mb-120 {
  margin-bottom: 7.5rem !important;
}

.mb-140 {
  margin-bottom: 8.75rem !important;
}

.mb-150 {
  margin-bottom: 9.375rem !important;
}

.mb-180 {
  margin-bottom: 11.25rem !important;
}

.mb-200 {
  margin-bottom: 12.5rem !important;
}

.mb-230 {
  margin-bottom: 14.375rem !important;
}

.mb-250 {
  margin-bottom: 15.625rem !important;
}

.mb-300 {
  margin-bottom: 18.75rem !important;
}

.mb-350 {
  margin-bottom: 21.87rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-10 {
  margin-left: 0.625rem !important;
}

.ms-20 {
  margin-left: 1.25rem !important;
}

.ms-30 {
  margin-left: 1.875rem !important;
}

.ms-40 {
  margin-left: 2.5rem !important;
}

.ms-60 {
  margin-left: 3.75rem !important;
}

.ms-70 {
  margin-left: 4.375rem !important;
}

.ms-80 {
  margin-left: 5rem !important;
}

.ms-90 {
  margin-left: 5.625rem !important;
}

.ms-100 {
  margin-left: 6.25rem !important;
}

.ms-120 {
  margin-left: 7.5rem !important;
}

.ms-140 {
  margin-left: 8.75rem !important;
}

.ms-150 {
  margin-left: 9.375rem !important;
}

.ms-180 {
  margin-left: 11.25rem !important;
}

.ms-200 {
  margin-left: 12.5rem !important;
}

.ms-230 {
  margin-left: 14.375rem !important;
}

.ms-250 {
  margin-left: 15.625rem !important;
}

.ms-300 {
  margin-left: 18.75rem !important;
}

.ms-350 {
  margin-left: 21.87rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n10 {
  margin: -0.625rem !important;
}

.m-n20 {
  margin: -1.25rem !important;
}

.m-n30 {
  margin: -1.875rem !important;
}

.m-n40 {
  margin: -2.5rem !important;
}

.m-n60 {
  margin: -3.75rem !important;
}

.m-n70 {
  margin: -4.375rem !important;
}

.m-n80 {
  margin: -5rem !important;
}

.m-n90 {
  margin: -5.625rem !important;
}

.m-n100 {
  margin: -6.25rem !important;
}

.m-n120 {
  margin: -7.5rem !important;
}

.m-n140 {
  margin: -8.75rem !important;
}

.m-n150 {
  margin: -9.375rem !important;
}

.m-n180 {
  margin: -11.25rem !important;
}

.m-n200 {
  margin: -12.5rem !important;
}

.m-n230 {
  margin: -14.375rem !important;
}

.m-n250 {
  margin: -15.625rem !important;
}

.m-n300 {
  margin: -18.75rem !important;
}

.m-n350 {
  margin: -21.87rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n10 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n20 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n30 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n40 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n60 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n70 {
  margin-right: -4.375rem !important;
  margin-left: -4.375rem !important;
}

.mx-n80 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n90 {
  margin-right: -5.625rem !important;
  margin-left: -5.625rem !important;
}

.mx-n100 {
  margin-right: -6.25rem !important;
  margin-left: -6.25rem !important;
}

.mx-n120 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

.mx-n140 {
  margin-right: -8.75rem !important;
  margin-left: -8.75rem !important;
}

.mx-n150 {
  margin-right: -9.375rem !important;
  margin-left: -9.375rem !important;
}

.mx-n180 {
  margin-right: -11.25rem !important;
  margin-left: -11.25rem !important;
}

.mx-n200 {
  margin-right: -12.5rem !important;
  margin-left: -12.5rem !important;
}

.mx-n230 {
  margin-right: -14.375rem !important;
  margin-left: -14.375rem !important;
}

.mx-n250 {
  margin-right: -15.625rem !important;
  margin-left: -15.625rem !important;
}

.mx-n300 {
  margin-right: -18.75rem !important;
  margin-left: -18.75rem !important;
}

.mx-n350 {
  margin-right: -21.87rem !important;
  margin-left: -21.87rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n10 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n20 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n30 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n40 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n60 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n70 {
  margin-top: -4.375rem !important;
  margin-bottom: -4.375rem !important;
}

.my-n80 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n90 {
  margin-top: -5.625rem !important;
  margin-bottom: -5.625rem !important;
}

.my-n100 {
  margin-top: -6.25rem !important;
  margin-bottom: -6.25rem !important;
}

.my-n120 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.my-n140 {
  margin-top: -8.75rem !important;
  margin-bottom: -8.75rem !important;
}

.my-n150 {
  margin-top: -9.375rem !important;
  margin-bottom: -9.375rem !important;
}

.my-n180 {
  margin-top: -11.25rem !important;
  margin-bottom: -11.25rem !important;
}

.my-n200 {
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important;
}

.my-n230 {
  margin-top: -14.375rem !important;
  margin-bottom: -14.375rem !important;
}

.my-n250 {
  margin-top: -15.625rem !important;
  margin-bottom: -15.625rem !important;
}

.my-n300 {
  margin-top: -18.75rem !important;
  margin-bottom: -18.75rem !important;
}

.my-n350 {
  margin-top: -21.87rem !important;
  margin-bottom: -21.87rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n10 {
  margin-top: -0.625rem !important;
}

.mt-n20 {
  margin-top: -1.25rem !important;
}

.mt-n30 {
  margin-top: -1.875rem !important;
}

.mt-n40 {
  margin-top: -2.5rem !important;
}

.mt-n60 {
  margin-top: -3.75rem !important;
}

.mt-n70 {
  margin-top: -4.375rem !important;
}

.mt-n80 {
  margin-top: -5rem !important;
}

.mt-n90 {
  margin-top: -5.625rem !important;
}

.mt-n100 {
  margin-top: -6.25rem !important;
}

.mt-n120 {
  margin-top: -7.5rem !important;
}

.mt-n140 {
  margin-top: -8.75rem !important;
}

.mt-n150 {
  margin-top: -9.375rem !important;
}

.mt-n180 {
  margin-top: -11.25rem !important;
}

.mt-n200 {
  margin-top: -12.5rem !important;
}

.mt-n230 {
  margin-top: -14.375rem !important;
}

.mt-n250 {
  margin-top: -15.625rem !important;
}

.mt-n300 {
  margin-top: -18.75rem !important;
}

.mt-n350 {
  margin-top: -21.87rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n10 {
  margin-right: -0.625rem !important;
}

.me-n20 {
  margin-right: -1.25rem !important;
}

.me-n30 {
  margin-right: -1.875rem !important;
}

.me-n40 {
  margin-right: -2.5rem !important;
}

.me-n60 {
  margin-right: -3.75rem !important;
}

.me-n70 {
  margin-right: -4.375rem !important;
}

.me-n80 {
  margin-right: -5rem !important;
}

.me-n90 {
  margin-right: -5.625rem !important;
}

.me-n100 {
  margin-right: -6.25rem !important;
}

.me-n120 {
  margin-right: -7.5rem !important;
}

.me-n140 {
  margin-right: -8.75rem !important;
}

.me-n150 {
  margin-right: -9.375rem !important;
}

.me-n180 {
  margin-right: -11.25rem !important;
}

.me-n200 {
  margin-right: -12.5rem !important;
}

.me-n230 {
  margin-right: -14.375rem !important;
}

.me-n250 {
  margin-right: -15.625rem !important;
}

.me-n300 {
  margin-right: -18.75rem !important;
}

.me-n350 {
  margin-right: -21.87rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n10 {
  margin-bottom: -0.625rem !important;
}

.mb-n20 {
  margin-bottom: -1.25rem !important;
}

.mb-n30 {
  margin-bottom: -1.875rem !important;
}

.mb-n40 {
  margin-bottom: -2.5rem !important;
}

.mb-n60 {
  margin-bottom: -3.75rem !important;
}

.mb-n70 {
  margin-bottom: -4.375rem !important;
}

.mb-n80 {
  margin-bottom: -5rem !important;
}

.mb-n90 {
  margin-bottom: -5.625rem !important;
}

.mb-n100 {
  margin-bottom: -6.25rem !important;
}

.mb-n120 {
  margin-bottom: -7.5rem !important;
}

.mb-n140 {
  margin-bottom: -8.75rem !important;
}

.mb-n150 {
  margin-bottom: -9.375rem !important;
}

.mb-n180 {
  margin-bottom: -11.25rem !important;
}

.mb-n200 {
  margin-bottom: -12.5rem !important;
}

.mb-n230 {
  margin-bottom: -14.375rem !important;
}

.mb-n250 {
  margin-bottom: -15.625rem !important;
}

.mb-n300 {
  margin-bottom: -18.75rem !important;
}

.mb-n350 {
  margin-bottom: -21.87rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n10 {
  margin-left: -0.625rem !important;
}

.ms-n20 {
  margin-left: -1.25rem !important;
}

.ms-n30 {
  margin-left: -1.875rem !important;
}

.ms-n40 {
  margin-left: -2.5rem !important;
}

.ms-n60 {
  margin-left: -3.75rem !important;
}

.ms-n70 {
  margin-left: -4.375rem !important;
}

.ms-n80 {
  margin-left: -5rem !important;
}

.ms-n90 {
  margin-left: -5.625rem !important;
}

.ms-n100 {
  margin-left: -6.25rem !important;
}

.ms-n120 {
  margin-left: -7.5rem !important;
}

.ms-n140 {
  margin-left: -8.75rem !important;
}

.ms-n150 {
  margin-left: -9.375rem !important;
}

.ms-n180 {
  margin-left: -11.25rem !important;
}

.ms-n200 {
  margin-left: -12.5rem !important;
}

.ms-n230 {
  margin-left: -14.375rem !important;
}

.ms-n250 {
  margin-left: -15.625rem !important;
}

.ms-n300 {
  margin-left: -18.75rem !important;
}

.ms-n350 {
  margin-left: -21.87rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-10 {
  padding: 0.625rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}

.p-30 {
  padding: 1.875rem !important;
}

.p-40 {
  padding: 2.5rem !important;
}

.p-60 {
  padding: 3.75rem !important;
}

.p-70 {
  padding: 4.375rem !important;
}

.p-80 {
  padding: 5rem !important;
}

.p-90 {
  padding: 5.625rem !important;
}

.p-100 {
  padding: 6.25rem !important;
}

.p-120 {
  padding: 7.5rem !important;
}

.p-140 {
  padding: 8.75rem !important;
}

.p-150 {
  padding: 9.375rem !important;
}

.p-180 {
  padding: 11.25rem !important;
}

.p-200 {
  padding: 12.5rem !important;
}

.p-230 {
  padding: 14.375rem !important;
}

.p-250 {
  padding: 15.625rem !important;
}

.p-300 {
  padding: 18.75rem !important;
}

.p-350 {
  padding: 21.87rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-10 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-20 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-30 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-40 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-60 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-70 {
  padding-right: 4.375rem !important;
  padding-left: 4.375rem !important;
}

.px-80 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-90 {
  padding-right: 5.625rem !important;
  padding-left: 5.625rem !important;
}

.px-100 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important;
}

.px-120 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-140 {
  padding-right: 8.75rem !important;
  padding-left: 8.75rem !important;
}

.px-150 {
  padding-right: 9.375rem !important;
  padding-left: 9.375rem !important;
}

.px-180 {
  padding-right: 11.25rem !important;
  padding-left: 11.25rem !important;
}

.px-200 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important;
}

.px-230 {
  padding-right: 14.375rem !important;
  padding-left: 14.375rem !important;
}

.px-250 {
  padding-right: 15.625rem !important;
  padding-left: 15.625rem !important;
}

.px-300 {
  padding-right: 18.75rem !important;
  padding-left: 18.75rem !important;
}

.px-350 {
  padding-right: 21.87rem !important;
  padding-left: 21.87rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-70 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-90 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.py-120 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-140 {
  padding-top: 8.75rem !important;
  padding-bottom: 8.75rem !important;
}

.py-150 {
  padding-top: 9.375rem !important;
  padding-bottom: 9.375rem !important;
}

.py-180 {
  padding-top: 11.25rem !important;
  padding-bottom: 11.25rem !important;
}

.py-200 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.py-230 {
  padding-top: 14.375rem !important;
  padding-bottom: 14.375rem !important;
}

.py-250 {
  padding-top: 15.625rem !important;
  padding-bottom: 15.625rem !important;
}

.py-300 {
  padding-top: 18.75rem !important;
  padding-bottom: 18.75rem !important;
}

.py-350 {
  padding-top: 21.87rem !important;
  padding-bottom: 21.87rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-10 {
  padding-top: 0.625rem !important;
}

.pt-20 {
  padding-top: 1.25rem !important;
}

.pt-30 {
  padding-top: 1.875rem !important;
}

.pt-40 {
  padding-top: 2.5rem !important;
}

.pt-60 {
  padding-top: 3.75rem !important;
}

.pt-70 {
  padding-top: 4.375rem !important;
}

.pt-80 {
  padding-top: 5rem !important;
}

.pt-90 {
  padding-top: 5.625rem !important;
}

.pt-100 {
  padding-top: 6.25rem !important;
}

.pt-120 {
  padding-top: 7.5rem !important;
}

.pt-140 {
  padding-top: 8.75rem !important;
}

.pt-150 {
  padding-top: 9.375rem !important;
}

.pt-180 {
  padding-top: 11.25rem !important;
}

.pt-200 {
  padding-top: 12.5rem !important;
}

.pt-230 {
  padding-top: 14.375rem !important;
}

.pt-250 {
  padding-top: 15.625rem !important;
}

.pt-300 {
  padding-top: 18.75rem !important;
}

.pt-350 {
  padding-top: 21.87rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-10 {
  padding-right: 0.625rem !important;
}

.pe-20 {
  padding-right: 1.25rem !important;
}

.pe-30 {
  padding-right: 1.875rem !important;
}

.pe-40 {
  padding-right: 2.5rem !important;
}

.pe-60 {
  padding-right: 3.75rem !important;
}

.pe-70 {
  padding-right: 4.375rem !important;
}

.pe-80 {
  padding-right: 5rem !important;
}

.pe-90 {
  padding-right: 5.625rem !important;
}

.pe-100 {
  padding-right: 6.25rem !important;
}

.pe-120 {
  padding-right: 7.5rem !important;
}

.pe-140 {
  padding-right: 8.75rem !important;
}

.pe-150 {
  padding-right: 9.375rem !important;
}

.pe-180 {
  padding-right: 11.25rem !important;
}

.pe-200 {
  padding-right: 12.5rem !important;
}

.pe-230 {
  padding-right: 14.375rem !important;
}

.pe-250 {
  padding-right: 15.625rem !important;
}

.pe-300 {
  padding-right: 18.75rem !important;
}

.pe-350 {
  padding-right: 21.87rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-10 {
  padding-bottom: 0.625rem !important;
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

.pb-30 {
  padding-bottom: 1.875rem !important;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.pb-60 {
  padding-bottom: 3.75rem !important;
}

.pb-70 {
  padding-bottom: 4.375rem !important;
}

.pb-80 {
  padding-bottom: 5rem !important;
}

.pb-90 {
  padding-bottom: 5.625rem !important;
}

.pb-100 {
  padding-bottom: 6.25rem !important;
}

.pb-120 {
  padding-bottom: 7.5rem !important;
}

.pb-140 {
  padding-bottom: 8.75rem !important;
}

.pb-150 {
  padding-bottom: 9.375rem !important;
}

.pb-180 {
  padding-bottom: 11.25rem !important;
}

.pb-200 {
  padding-bottom: 12.5rem !important;
}

.pb-230 {
  padding-bottom: 14.375rem !important;
}

.pb-250 {
  padding-bottom: 15.625rem !important;
}

.pb-300 {
  padding-bottom: 18.75rem !important;
}

.pb-350 {
  padding-bottom: 21.87rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-10 {
  padding-left: 0.625rem !important;
}

.ps-20 {
  padding-left: 1.25rem !important;
}

.ps-30 {
  padding-left: 1.875rem !important;
}

.ps-40 {
  padding-left: 2.5rem !important;
}

.ps-60 {
  padding-left: 3.75rem !important;
}

.ps-70 {
  padding-left: 4.375rem !important;
}

.ps-80 {
  padding-left: 5rem !important;
}

.ps-90 {
  padding-left: 5.625rem !important;
}

.ps-100 {
  padding-left: 6.25rem !important;
}

.ps-120 {
  padding-left: 7.5rem !important;
}

.ps-140 {
  padding-left: 8.75rem !important;
}

.ps-150 {
  padding-left: 9.375rem !important;
}

.ps-180 {
  padding-left: 11.25rem !important;
}

.ps-200 {
  padding-left: 12.5rem !important;
}

.ps-230 {
  padding-left: 14.375rem !important;
}

.ps-250 {
  padding-left: 15.625rem !important;
}

.ps-300 {
  padding-left: 18.75rem !important;
}

.ps-350 {
  padding-left: 21.87rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-10 {
  gap: 0.625rem !important;
}

.gap-20 {
  gap: 1.25rem !important;
}

.gap-30 {
  gap: 1.875rem !important;
}

.gap-40 {
  gap: 2.5rem !important;
}

.gap-60 {
  gap: 3.75rem !important;
}

.gap-70 {
  gap: 4.375rem !important;
}

.gap-80 {
  gap: 5rem !important;
}

.gap-90 {
  gap: 5.625rem !important;
}

.gap-100 {
  gap: 6.25rem !important;
}

.gap-120 {
  gap: 7.5rem !important;
}

.gap-140 {
  gap: 8.75rem !important;
}

.gap-150 {
  gap: 9.375rem !important;
}

.gap-180 {
  gap: 11.25rem !important;
}

.gap-200 {
  gap: 12.5rem !important;
}

.gap-230 {
  gap: 14.375rem !important;
}

.gap-250 {
  gap: 15.625rem !important;
}

.gap-300 {
  gap: 18.75rem !important;
}

.gap-350 {
  gap: 21.87rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-10 {
    margin: 0.625rem !important;
  }
  .m-sm-20 {
    margin: 1.25rem !important;
  }
  .m-sm-30 {
    margin: 1.875rem !important;
  }
  .m-sm-40 {
    margin: 2.5rem !important;
  }
  .m-sm-60 {
    margin: 3.75rem !important;
  }
  .m-sm-70 {
    margin: 4.375rem !important;
  }
  .m-sm-80 {
    margin: 5rem !important;
  }
  .m-sm-90 {
    margin: 5.625rem !important;
  }
  .m-sm-100 {
    margin: 6.25rem !important;
  }
  .m-sm-120 {
    margin: 7.5rem !important;
  }
  .m-sm-140 {
    margin: 8.75rem !important;
  }
  .m-sm-150 {
    margin: 9.375rem !important;
  }
  .m-sm-180 {
    margin: 11.25rem !important;
  }
  .m-sm-200 {
    margin: 12.5rem !important;
  }
  .m-sm-230 {
    margin: 14.375rem !important;
  }
  .m-sm-250 {
    margin: 15.625rem !important;
  }
  .m-sm-300 {
    margin: 18.75rem !important;
  }
  .m-sm-350 {
    margin: 21.87rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-sm-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-sm-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-sm-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-sm-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-sm-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-sm-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-sm-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-sm-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-sm-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-sm-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-sm-350 {
    margin-right: 21.87rem !important;
    margin-left: 21.87rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-sm-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-sm-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-sm-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-sm-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-sm-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-sm-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-sm-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-sm-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-sm-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-sm-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-sm-350 {
    margin-top: 21.87rem !important;
    margin-bottom: 21.87rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-10 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-20 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-30 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-40 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-60 {
    margin-top: 3.75rem !important;
  }
  .mt-sm-70 {
    margin-top: 4.375rem !important;
  }
  .mt-sm-80 {
    margin-top: 5rem !important;
  }
  .mt-sm-90 {
    margin-top: 5.625rem !important;
  }
  .mt-sm-100 {
    margin-top: 6.25rem !important;
  }
  .mt-sm-120 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-140 {
    margin-top: 8.75rem !important;
  }
  .mt-sm-150 {
    margin-top: 9.375rem !important;
  }
  .mt-sm-180 {
    margin-top: 11.25rem !important;
  }
  .mt-sm-200 {
    margin-top: 12.5rem !important;
  }
  .mt-sm-230 {
    margin-top: 14.375rem !important;
  }
  .mt-sm-250 {
    margin-top: 15.625rem !important;
  }
  .mt-sm-300 {
    margin-top: 18.75rem !important;
  }
  .mt-sm-350 {
    margin-top: 21.87rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-10 {
    margin-right: 0.625rem !important;
  }
  .me-sm-20 {
    margin-right: 1.25rem !important;
  }
  .me-sm-30 {
    margin-right: 1.875rem !important;
  }
  .me-sm-40 {
    margin-right: 2.5rem !important;
  }
  .me-sm-60 {
    margin-right: 3.75rem !important;
  }
  .me-sm-70 {
    margin-right: 4.375rem !important;
  }
  .me-sm-80 {
    margin-right: 5rem !important;
  }
  .me-sm-90 {
    margin-right: 5.625rem !important;
  }
  .me-sm-100 {
    margin-right: 6.25rem !important;
  }
  .me-sm-120 {
    margin-right: 7.5rem !important;
  }
  .me-sm-140 {
    margin-right: 8.75rem !important;
  }
  .me-sm-150 {
    margin-right: 9.375rem !important;
  }
  .me-sm-180 {
    margin-right: 11.25rem !important;
  }
  .me-sm-200 {
    margin-right: 12.5rem !important;
  }
  .me-sm-230 {
    margin-right: 14.375rem !important;
  }
  .me-sm-250 {
    margin-right: 15.625rem !important;
  }
  .me-sm-300 {
    margin-right: 18.75rem !important;
  }
  .me-sm-350 {
    margin-right: 21.87rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-sm-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-sm-80 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-sm-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-sm-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-sm-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-sm-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-sm-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-sm-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-sm-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-sm-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-sm-350 {
    margin-bottom: 21.87rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-10 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-20 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-30 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-40 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-60 {
    margin-left: 3.75rem !important;
  }
  .ms-sm-70 {
    margin-left: 4.375rem !important;
  }
  .ms-sm-80 {
    margin-left: 5rem !important;
  }
  .ms-sm-90 {
    margin-left: 5.625rem !important;
  }
  .ms-sm-100 {
    margin-left: 6.25rem !important;
  }
  .ms-sm-120 {
    margin-left: 7.5rem !important;
  }
  .ms-sm-140 {
    margin-left: 8.75rem !important;
  }
  .ms-sm-150 {
    margin-left: 9.375rem !important;
  }
  .ms-sm-180 {
    margin-left: 11.25rem !important;
  }
  .ms-sm-200 {
    margin-left: 12.5rem !important;
  }
  .ms-sm-230 {
    margin-left: 14.375rem !important;
  }
  .ms-sm-250 {
    margin-left: 15.625rem !important;
  }
  .ms-sm-300 {
    margin-left: 18.75rem !important;
  }
  .ms-sm-350 {
    margin-left: 21.87rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n10 {
    margin: -0.625rem !important;
  }
  .m-sm-n20 {
    margin: -1.25rem !important;
  }
  .m-sm-n30 {
    margin: -1.875rem !important;
  }
  .m-sm-n40 {
    margin: -2.5rem !important;
  }
  .m-sm-n60 {
    margin: -3.75rem !important;
  }
  .m-sm-n70 {
    margin: -4.375rem !important;
  }
  .m-sm-n80 {
    margin: -5rem !important;
  }
  .m-sm-n90 {
    margin: -5.625rem !important;
  }
  .m-sm-n100 {
    margin: -6.25rem !important;
  }
  .m-sm-n120 {
    margin: -7.5rem !important;
  }
  .m-sm-n140 {
    margin: -8.75rem !important;
  }
  .m-sm-n150 {
    margin: -9.375rem !important;
  }
  .m-sm-n180 {
    margin: -11.25rem !important;
  }
  .m-sm-n200 {
    margin: -12.5rem !important;
  }
  .m-sm-n230 {
    margin: -14.375rem !important;
  }
  .m-sm-n250 {
    margin: -15.625rem !important;
  }
  .m-sm-n300 {
    margin: -18.75rem !important;
  }
  .m-sm-n350 {
    margin: -21.87rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-sm-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-sm-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-sm-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-sm-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-sm-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-sm-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-sm-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-sm-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-sm-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-sm-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-sm-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .mx-sm-n350 {
    margin-right: -21.87rem !important;
    margin-left: -21.87rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-sm-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-sm-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-sm-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-sm-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-sm-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-sm-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-sm-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-sm-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-sm-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-sm-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-sm-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .my-sm-n350 {
    margin-top: -21.87rem !important;
    margin-bottom: -21.87rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-sm-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-sm-n80 {
    margin-top: -5rem !important;
  }
  .mt-sm-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-sm-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-sm-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-sm-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-sm-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-sm-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-sm-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-sm-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-sm-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-sm-n300 {
    margin-top: -18.75rem !important;
  }
  .mt-sm-n350 {
    margin-top: -21.87rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n10 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n20 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n30 {
    margin-right: -1.875rem !important;
  }
  .me-sm-n40 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n60 {
    margin-right: -3.75rem !important;
  }
  .me-sm-n70 {
    margin-right: -4.375rem !important;
  }
  .me-sm-n80 {
    margin-right: -5rem !important;
  }
  .me-sm-n90 {
    margin-right: -5.625rem !important;
  }
  .me-sm-n100 {
    margin-right: -6.25rem !important;
  }
  .me-sm-n120 {
    margin-right: -7.5rem !important;
  }
  .me-sm-n140 {
    margin-right: -8.75rem !important;
  }
  .me-sm-n150 {
    margin-right: -9.375rem !important;
  }
  .me-sm-n180 {
    margin-right: -11.25rem !important;
  }
  .me-sm-n200 {
    margin-right: -12.5rem !important;
  }
  .me-sm-n230 {
    margin-right: -14.375rem !important;
  }
  .me-sm-n250 {
    margin-right: -15.625rem !important;
  }
  .me-sm-n300 {
    margin-right: -18.75rem !important;
  }
  .me-sm-n350 {
    margin-right: -21.87rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-sm-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-sm-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-sm-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-sm-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-sm-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-sm-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-sm-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-sm-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-sm-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-sm-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-sm-n300 {
    margin-bottom: -18.75rem !important;
  }
  .mb-sm-n350 {
    margin-bottom: -21.87rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-sm-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-sm-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-sm-n80 {
    margin-left: -5rem !important;
  }
  .ms-sm-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-sm-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-sm-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-sm-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-sm-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-sm-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-sm-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-sm-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-sm-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-sm-n300 {
    margin-left: -18.75rem !important;
  }
  .ms-sm-n350 {
    margin-left: -21.87rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-10 {
    padding: 0.625rem !important;
  }
  .p-sm-20 {
    padding: 1.25rem !important;
  }
  .p-sm-30 {
    padding: 1.875rem !important;
  }
  .p-sm-40 {
    padding: 2.5rem !important;
  }
  .p-sm-60 {
    padding: 3.75rem !important;
  }
  .p-sm-70 {
    padding: 4.375rem !important;
  }
  .p-sm-80 {
    padding: 5rem !important;
  }
  .p-sm-90 {
    padding: 5.625rem !important;
  }
  .p-sm-100 {
    padding: 6.25rem !important;
  }
  .p-sm-120 {
    padding: 7.5rem !important;
  }
  .p-sm-140 {
    padding: 8.75rem !important;
  }
  .p-sm-150 {
    padding: 9.375rem !important;
  }
  .p-sm-180 {
    padding: 11.25rem !important;
  }
  .p-sm-200 {
    padding: 12.5rem !important;
  }
  .p-sm-230 {
    padding: 14.375rem !important;
  }
  .p-sm-250 {
    padding: 15.625rem !important;
  }
  .p-sm-300 {
    padding: 18.75rem !important;
  }
  .p-sm-350 {
    padding: 21.87rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-sm-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-sm-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-sm-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-sm-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-sm-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-sm-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-sm-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-sm-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-sm-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-sm-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-sm-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .px-sm-350 {
    padding-right: 21.87rem !important;
    padding-left: 21.87rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-sm-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-sm-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-sm-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-sm-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-sm-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-sm-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-sm-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-sm-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-sm-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-sm-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-sm-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .py-sm-350 {
    padding-top: 21.87rem !important;
    padding-bottom: 21.87rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-10 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-20 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-30 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-40 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-60 {
    padding-top: 3.75rem !important;
  }
  .pt-sm-70 {
    padding-top: 4.375rem !important;
  }
  .pt-sm-80 {
    padding-top: 5rem !important;
  }
  .pt-sm-90 {
    padding-top: 5.625rem !important;
  }
  .pt-sm-100 {
    padding-top: 6.25rem !important;
  }
  .pt-sm-120 {
    padding-top: 7.5rem !important;
  }
  .pt-sm-140 {
    padding-top: 8.75rem !important;
  }
  .pt-sm-150 {
    padding-top: 9.375rem !important;
  }
  .pt-sm-180 {
    padding-top: 11.25rem !important;
  }
  .pt-sm-200 {
    padding-top: 12.5rem !important;
  }
  .pt-sm-230 {
    padding-top: 14.375rem !important;
  }
  .pt-sm-250 {
    padding-top: 15.625rem !important;
  }
  .pt-sm-300 {
    padding-top: 18.75rem !important;
  }
  .pt-sm-350 {
    padding-top: 21.87rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-10 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-20 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-30 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-40 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-60 {
    padding-right: 3.75rem !important;
  }
  .pe-sm-70 {
    padding-right: 4.375rem !important;
  }
  .pe-sm-80 {
    padding-right: 5rem !important;
  }
  .pe-sm-90 {
    padding-right: 5.625rem !important;
  }
  .pe-sm-100 {
    padding-right: 6.25rem !important;
  }
  .pe-sm-120 {
    padding-right: 7.5rem !important;
  }
  .pe-sm-140 {
    padding-right: 8.75rem !important;
  }
  .pe-sm-150 {
    padding-right: 9.375rem !important;
  }
  .pe-sm-180 {
    padding-right: 11.25rem !important;
  }
  .pe-sm-200 {
    padding-right: 12.5rem !important;
  }
  .pe-sm-230 {
    padding-right: 14.375rem !important;
  }
  .pe-sm-250 {
    padding-right: 15.625rem !important;
  }
  .pe-sm-300 {
    padding-right: 18.75rem !important;
  }
  .pe-sm-350 {
    padding-right: 21.87rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-sm-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-sm-80 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-sm-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-sm-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-sm-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-sm-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-sm-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-sm-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-sm-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-sm-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-sm-300 {
    padding-bottom: 18.75rem !important;
  }
  .pb-sm-350 {
    padding-bottom: 21.87rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-10 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-20 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-30 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-40 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-60 {
    padding-left: 3.75rem !important;
  }
  .ps-sm-70 {
    padding-left: 4.375rem !important;
  }
  .ps-sm-80 {
    padding-left: 5rem !important;
  }
  .ps-sm-90 {
    padding-left: 5.625rem !important;
  }
  .ps-sm-100 {
    padding-left: 6.25rem !important;
  }
  .ps-sm-120 {
    padding-left: 7.5rem !important;
  }
  .ps-sm-140 {
    padding-left: 8.75rem !important;
  }
  .ps-sm-150 {
    padding-left: 9.375rem !important;
  }
  .ps-sm-180 {
    padding-left: 11.25rem !important;
  }
  .ps-sm-200 {
    padding-left: 12.5rem !important;
  }
  .ps-sm-230 {
    padding-left: 14.375rem !important;
  }
  .ps-sm-250 {
    padding-left: 15.625rem !important;
  }
  .ps-sm-300 {
    padding-left: 18.75rem !important;
  }
  .ps-sm-350 {
    padding-left: 21.87rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-10 {
    gap: 0.625rem !important;
  }
  .gap-sm-20 {
    gap: 1.25rem !important;
  }
  .gap-sm-30 {
    gap: 1.875rem !important;
  }
  .gap-sm-40 {
    gap: 2.5rem !important;
  }
  .gap-sm-60 {
    gap: 3.75rem !important;
  }
  .gap-sm-70 {
    gap: 4.375rem !important;
  }
  .gap-sm-80 {
    gap: 5rem !important;
  }
  .gap-sm-90 {
    gap: 5.625rem !important;
  }
  .gap-sm-100 {
    gap: 6.25rem !important;
  }
  .gap-sm-120 {
    gap: 7.5rem !important;
  }
  .gap-sm-140 {
    gap: 8.75rem !important;
  }
  .gap-sm-150 {
    gap: 9.375rem !important;
  }
  .gap-sm-180 {
    gap: 11.25rem !important;
  }
  .gap-sm-200 {
    gap: 12.5rem !important;
  }
  .gap-sm-230 {
    gap: 14.375rem !important;
  }
  .gap-sm-250 {
    gap: 15.625rem !important;
  }
  .gap-sm-300 {
    gap: 18.75rem !important;
  }
  .gap-sm-350 {
    gap: 21.87rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-10 {
    margin: 0.625rem !important;
  }
  .m-md-20 {
    margin: 1.25rem !important;
  }
  .m-md-30 {
    margin: 1.875rem !important;
  }
  .m-md-40 {
    margin: 2.5rem !important;
  }
  .m-md-60 {
    margin: 3.75rem !important;
  }
  .m-md-70 {
    margin: 4.375rem !important;
  }
  .m-md-80 {
    margin: 5rem !important;
  }
  .m-md-90 {
    margin: 5.625rem !important;
  }
  .m-md-100 {
    margin: 6.25rem !important;
  }
  .m-md-120 {
    margin: 7.5rem !important;
  }
  .m-md-140 {
    margin: 8.75rem !important;
  }
  .m-md-150 {
    margin: 9.375rem !important;
  }
  .m-md-180 {
    margin: 11.25rem !important;
  }
  .m-md-200 {
    margin: 12.5rem !important;
  }
  .m-md-230 {
    margin: 14.375rem !important;
  }
  .m-md-250 {
    margin: 15.625rem !important;
  }
  .m-md-300 {
    margin: 18.75rem !important;
  }
  .m-md-350 {
    margin: 21.87rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-md-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-md-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-md-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-md-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-md-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-md-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-md-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-md-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-md-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-md-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-md-350 {
    margin-right: 21.87rem !important;
    margin-left: 21.87rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-md-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-md-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-md-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-md-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-md-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-md-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-md-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-md-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-md-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-md-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-md-350 {
    margin-top: 21.87rem !important;
    margin-bottom: 21.87rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-10 {
    margin-top: 0.625rem !important;
  }
  .mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .mt-md-30 {
    margin-top: 1.875rem !important;
  }
  .mt-md-40 {
    margin-top: 2.5rem !important;
  }
  .mt-md-60 {
    margin-top: 3.75rem !important;
  }
  .mt-md-70 {
    margin-top: 4.375rem !important;
  }
  .mt-md-80 {
    margin-top: 5rem !important;
  }
  .mt-md-90 {
    margin-top: 5.625rem !important;
  }
  .mt-md-100 {
    margin-top: 6.25rem !important;
  }
  .mt-md-120 {
    margin-top: 7.5rem !important;
  }
  .mt-md-140 {
    margin-top: 8.75rem !important;
  }
  .mt-md-150 {
    margin-top: 9.375rem !important;
  }
  .mt-md-180 {
    margin-top: 11.25rem !important;
  }
  .mt-md-200 {
    margin-top: 12.5rem !important;
  }
  .mt-md-230 {
    margin-top: 14.375rem !important;
  }
  .mt-md-250 {
    margin-top: 15.625rem !important;
  }
  .mt-md-300 {
    margin-top: 18.75rem !important;
  }
  .mt-md-350 {
    margin-top: 21.87rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-10 {
    margin-right: 0.625rem !important;
  }
  .me-md-20 {
    margin-right: 1.25rem !important;
  }
  .me-md-30 {
    margin-right: 1.875rem !important;
  }
  .me-md-40 {
    margin-right: 2.5rem !important;
  }
  .me-md-60 {
    margin-right: 3.75rem !important;
  }
  .me-md-70 {
    margin-right: 4.375rem !important;
  }
  .me-md-80 {
    margin-right: 5rem !important;
  }
  .me-md-90 {
    margin-right: 5.625rem !important;
  }
  .me-md-100 {
    margin-right: 6.25rem !important;
  }
  .me-md-120 {
    margin-right: 7.5rem !important;
  }
  .me-md-140 {
    margin-right: 8.75rem !important;
  }
  .me-md-150 {
    margin-right: 9.375rem !important;
  }
  .me-md-180 {
    margin-right: 11.25rem !important;
  }
  .me-md-200 {
    margin-right: 12.5rem !important;
  }
  .me-md-230 {
    margin-right: 14.375rem !important;
  }
  .me-md-250 {
    margin-right: 15.625rem !important;
  }
  .me-md-300 {
    margin-right: 18.75rem !important;
  }
  .me-md-350 {
    margin-right: 21.87rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-md-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-md-80 {
    margin-bottom: 5rem !important;
  }
  .mb-md-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-md-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-md-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-md-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-md-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-md-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-md-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-md-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-md-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-md-350 {
    margin-bottom: 21.87rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-10 {
    margin-left: 0.625rem !important;
  }
  .ms-md-20 {
    margin-left: 1.25rem !important;
  }
  .ms-md-30 {
    margin-left: 1.875rem !important;
  }
  .ms-md-40 {
    margin-left: 2.5rem !important;
  }
  .ms-md-60 {
    margin-left: 3.75rem !important;
  }
  .ms-md-70 {
    margin-left: 4.375rem !important;
  }
  .ms-md-80 {
    margin-left: 5rem !important;
  }
  .ms-md-90 {
    margin-left: 5.625rem !important;
  }
  .ms-md-100 {
    margin-left: 6.25rem !important;
  }
  .ms-md-120 {
    margin-left: 7.5rem !important;
  }
  .ms-md-140 {
    margin-left: 8.75rem !important;
  }
  .ms-md-150 {
    margin-left: 9.375rem !important;
  }
  .ms-md-180 {
    margin-left: 11.25rem !important;
  }
  .ms-md-200 {
    margin-left: 12.5rem !important;
  }
  .ms-md-230 {
    margin-left: 14.375rem !important;
  }
  .ms-md-250 {
    margin-left: 15.625rem !important;
  }
  .ms-md-300 {
    margin-left: 18.75rem !important;
  }
  .ms-md-350 {
    margin-left: 21.87rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n10 {
    margin: -0.625rem !important;
  }
  .m-md-n20 {
    margin: -1.25rem !important;
  }
  .m-md-n30 {
    margin: -1.875rem !important;
  }
  .m-md-n40 {
    margin: -2.5rem !important;
  }
  .m-md-n60 {
    margin: -3.75rem !important;
  }
  .m-md-n70 {
    margin: -4.375rem !important;
  }
  .m-md-n80 {
    margin: -5rem !important;
  }
  .m-md-n90 {
    margin: -5.625rem !important;
  }
  .m-md-n100 {
    margin: -6.25rem !important;
  }
  .m-md-n120 {
    margin: -7.5rem !important;
  }
  .m-md-n140 {
    margin: -8.75rem !important;
  }
  .m-md-n150 {
    margin: -9.375rem !important;
  }
  .m-md-n180 {
    margin: -11.25rem !important;
  }
  .m-md-n200 {
    margin: -12.5rem !important;
  }
  .m-md-n230 {
    margin: -14.375rem !important;
  }
  .m-md-n250 {
    margin: -15.625rem !important;
  }
  .m-md-n300 {
    margin: -18.75rem !important;
  }
  .m-md-n350 {
    margin: -21.87rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-md-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-md-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-md-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-md-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-md-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-md-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-md-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-md-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-md-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-md-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-md-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .mx-md-n350 {
    margin-right: -21.87rem !important;
    margin-left: -21.87rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-md-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-md-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-md-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-md-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-md-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-md-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-md-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-md-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-md-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-md-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-md-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .my-md-n350 {
    margin-top: -21.87rem !important;
    margin-bottom: -21.87rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-md-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-md-n80 {
    margin-top: -5rem !important;
  }
  .mt-md-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-md-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-md-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-md-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-md-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-md-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-md-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-md-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-md-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-md-n300 {
    margin-top: -18.75rem !important;
  }
  .mt-md-n350 {
    margin-top: -21.87rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n10 {
    margin-right: -0.625rem !important;
  }
  .me-md-n20 {
    margin-right: -1.25rem !important;
  }
  .me-md-n30 {
    margin-right: -1.875rem !important;
  }
  .me-md-n40 {
    margin-right: -2.5rem !important;
  }
  .me-md-n60 {
    margin-right: -3.75rem !important;
  }
  .me-md-n70 {
    margin-right: -4.375rem !important;
  }
  .me-md-n80 {
    margin-right: -5rem !important;
  }
  .me-md-n90 {
    margin-right: -5.625rem !important;
  }
  .me-md-n100 {
    margin-right: -6.25rem !important;
  }
  .me-md-n120 {
    margin-right: -7.5rem !important;
  }
  .me-md-n140 {
    margin-right: -8.75rem !important;
  }
  .me-md-n150 {
    margin-right: -9.375rem !important;
  }
  .me-md-n180 {
    margin-right: -11.25rem !important;
  }
  .me-md-n200 {
    margin-right: -12.5rem !important;
  }
  .me-md-n230 {
    margin-right: -14.375rem !important;
  }
  .me-md-n250 {
    margin-right: -15.625rem !important;
  }
  .me-md-n300 {
    margin-right: -18.75rem !important;
  }
  .me-md-n350 {
    margin-right: -21.87rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-md-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-md-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-md-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-md-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-md-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-md-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-md-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-md-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-md-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-md-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-md-n300 {
    margin-bottom: -18.75rem !important;
  }
  .mb-md-n350 {
    margin-bottom: -21.87rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-md-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-md-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-md-n80 {
    margin-left: -5rem !important;
  }
  .ms-md-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-md-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-md-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-md-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-md-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-md-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-md-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-md-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-md-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-md-n300 {
    margin-left: -18.75rem !important;
  }
  .ms-md-n350 {
    margin-left: -21.87rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-10 {
    padding: 0.625rem !important;
  }
  .p-md-20 {
    padding: 1.25rem !important;
  }
  .p-md-30 {
    padding: 1.875rem !important;
  }
  .p-md-40 {
    padding: 2.5rem !important;
  }
  .p-md-60 {
    padding: 3.75rem !important;
  }
  .p-md-70 {
    padding: 4.375rem !important;
  }
  .p-md-80 {
    padding: 5rem !important;
  }
  .p-md-90 {
    padding: 5.625rem !important;
  }
  .p-md-100 {
    padding: 6.25rem !important;
  }
  .p-md-120 {
    padding: 7.5rem !important;
  }
  .p-md-140 {
    padding: 8.75rem !important;
  }
  .p-md-150 {
    padding: 9.375rem !important;
  }
  .p-md-180 {
    padding: 11.25rem !important;
  }
  .p-md-200 {
    padding: 12.5rem !important;
  }
  .p-md-230 {
    padding: 14.375rem !important;
  }
  .p-md-250 {
    padding: 15.625rem !important;
  }
  .p-md-300 {
    padding: 18.75rem !important;
  }
  .p-md-350 {
    padding: 21.87rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-md-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-md-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-md-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-md-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-md-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-md-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-md-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-md-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-md-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-md-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-md-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .px-md-350 {
    padding-right: 21.87rem !important;
    padding-left: 21.87rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-md-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-md-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-md-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-md-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-md-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-md-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-md-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-md-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-md-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-md-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-md-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .py-md-350 {
    padding-top: 21.87rem !important;
    padding-bottom: 21.87rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-10 {
    padding-top: 0.625rem !important;
  }
  .pt-md-20 {
    padding-top: 1.25rem !important;
  }
  .pt-md-30 {
    padding-top: 1.875rem !important;
  }
  .pt-md-40 {
    padding-top: 2.5rem !important;
  }
  .pt-md-60 {
    padding-top: 3.75rem !important;
  }
  .pt-md-70 {
    padding-top: 4.375rem !important;
  }
  .pt-md-80 {
    padding-top: 5rem !important;
  }
  .pt-md-90 {
    padding-top: 5.625rem !important;
  }
  .pt-md-100 {
    padding-top: 6.25rem !important;
  }
  .pt-md-120 {
    padding-top: 7.5rem !important;
  }
  .pt-md-140 {
    padding-top: 8.75rem !important;
  }
  .pt-md-150 {
    padding-top: 9.375rem !important;
  }
  .pt-md-180 {
    padding-top: 11.25rem !important;
  }
  .pt-md-200 {
    padding-top: 12.5rem !important;
  }
  .pt-md-230 {
    padding-top: 14.375rem !important;
  }
  .pt-md-250 {
    padding-top: 15.625rem !important;
  }
  .pt-md-300 {
    padding-top: 18.75rem !important;
  }
  .pt-md-350 {
    padding-top: 21.87rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-10 {
    padding-right: 0.625rem !important;
  }
  .pe-md-20 {
    padding-right: 1.25rem !important;
  }
  .pe-md-30 {
    padding-right: 1.875rem !important;
  }
  .pe-md-40 {
    padding-right: 2.5rem !important;
  }
  .pe-md-60 {
    padding-right: 3.75rem !important;
  }
  .pe-md-70 {
    padding-right: 4.375rem !important;
  }
  .pe-md-80 {
    padding-right: 5rem !important;
  }
  .pe-md-90 {
    padding-right: 5.625rem !important;
  }
  .pe-md-100 {
    padding-right: 6.25rem !important;
  }
  .pe-md-120 {
    padding-right: 7.5rem !important;
  }
  .pe-md-140 {
    padding-right: 8.75rem !important;
  }
  .pe-md-150 {
    padding-right: 9.375rem !important;
  }
  .pe-md-180 {
    padding-right: 11.25rem !important;
  }
  .pe-md-200 {
    padding-right: 12.5rem !important;
  }
  .pe-md-230 {
    padding-right: 14.375rem !important;
  }
  .pe-md-250 {
    padding-right: 15.625rem !important;
  }
  .pe-md-300 {
    padding-right: 18.75rem !important;
  }
  .pe-md-350 {
    padding-right: 21.87rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-md-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-md-80 {
    padding-bottom: 5rem !important;
  }
  .pb-md-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-md-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-md-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-md-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-md-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-md-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-md-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-md-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-md-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-md-300 {
    padding-bottom: 18.75rem !important;
  }
  .pb-md-350 {
    padding-bottom: 21.87rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-10 {
    padding-left: 0.625rem !important;
  }
  .ps-md-20 {
    padding-left: 1.25rem !important;
  }
  .ps-md-30 {
    padding-left: 1.875rem !important;
  }
  .ps-md-40 {
    padding-left: 2.5rem !important;
  }
  .ps-md-60 {
    padding-left: 3.75rem !important;
  }
  .ps-md-70 {
    padding-left: 4.375rem !important;
  }
  .ps-md-80 {
    padding-left: 5rem !important;
  }
  .ps-md-90 {
    padding-left: 5.625rem !important;
  }
  .ps-md-100 {
    padding-left: 6.25rem !important;
  }
  .ps-md-120 {
    padding-left: 7.5rem !important;
  }
  .ps-md-140 {
    padding-left: 8.75rem !important;
  }
  .ps-md-150 {
    padding-left: 9.375rem !important;
  }
  .ps-md-180 {
    padding-left: 11.25rem !important;
  }
  .ps-md-200 {
    padding-left: 12.5rem !important;
  }
  .ps-md-230 {
    padding-left: 14.375rem !important;
  }
  .ps-md-250 {
    padding-left: 15.625rem !important;
  }
  .ps-md-300 {
    padding-left: 18.75rem !important;
  }
  .ps-md-350 {
    padding-left: 21.87rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-10 {
    gap: 0.625rem !important;
  }
  .gap-md-20 {
    gap: 1.25rem !important;
  }
  .gap-md-30 {
    gap: 1.875rem !important;
  }
  .gap-md-40 {
    gap: 2.5rem !important;
  }
  .gap-md-60 {
    gap: 3.75rem !important;
  }
  .gap-md-70 {
    gap: 4.375rem !important;
  }
  .gap-md-80 {
    gap: 5rem !important;
  }
  .gap-md-90 {
    gap: 5.625rem !important;
  }
  .gap-md-100 {
    gap: 6.25rem !important;
  }
  .gap-md-120 {
    gap: 7.5rem !important;
  }
  .gap-md-140 {
    gap: 8.75rem !important;
  }
  .gap-md-150 {
    gap: 9.375rem !important;
  }
  .gap-md-180 {
    gap: 11.25rem !important;
  }
  .gap-md-200 {
    gap: 12.5rem !important;
  }
  .gap-md-230 {
    gap: 14.375rem !important;
  }
  .gap-md-250 {
    gap: 15.625rem !important;
  }
  .gap-md-300 {
    gap: 18.75rem !important;
  }
  .gap-md-350 {
    gap: 21.87rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-10 {
    margin: 0.625rem !important;
  }
  .m-lg-20 {
    margin: 1.25rem !important;
  }
  .m-lg-30 {
    margin: 1.875rem !important;
  }
  .m-lg-40 {
    margin: 2.5rem !important;
  }
  .m-lg-60 {
    margin: 3.75rem !important;
  }
  .m-lg-70 {
    margin: 4.375rem !important;
  }
  .m-lg-80 {
    margin: 5rem !important;
  }
  .m-lg-90 {
    margin: 5.625rem !important;
  }
  .m-lg-100 {
    margin: 6.25rem !important;
  }
  .m-lg-120 {
    margin: 7.5rem !important;
  }
  .m-lg-140 {
    margin: 8.75rem !important;
  }
  .m-lg-150 {
    margin: 9.375rem !important;
  }
  .m-lg-180 {
    margin: 11.25rem !important;
  }
  .m-lg-200 {
    margin: 12.5rem !important;
  }
  .m-lg-230 {
    margin: 14.375rem !important;
  }
  .m-lg-250 {
    margin: 15.625rem !important;
  }
  .m-lg-300 {
    margin: 18.75rem !important;
  }
  .m-lg-350 {
    margin: 21.87rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-lg-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-lg-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-lg-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-lg-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-lg-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-lg-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-lg-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-lg-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-lg-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-lg-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-lg-350 {
    margin-right: 21.87rem !important;
    margin-left: 21.87rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-lg-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-lg-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-lg-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-lg-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-lg-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-lg-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-lg-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-lg-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-lg-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-lg-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-lg-350 {
    margin-top: 21.87rem !important;
    margin-bottom: 21.87rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-10 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-20 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-30 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-40 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-60 {
    margin-top: 3.75rem !important;
  }
  .mt-lg-70 {
    margin-top: 4.375rem !important;
  }
  .mt-lg-80 {
    margin-top: 5rem !important;
  }
  .mt-lg-90 {
    margin-top: 5.625rem !important;
  }
  .mt-lg-100 {
    margin-top: 6.25rem !important;
  }
  .mt-lg-120 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-140 {
    margin-top: 8.75rem !important;
  }
  .mt-lg-150 {
    margin-top: 9.375rem !important;
  }
  .mt-lg-180 {
    margin-top: 11.25rem !important;
  }
  .mt-lg-200 {
    margin-top: 12.5rem !important;
  }
  .mt-lg-230 {
    margin-top: 14.375rem !important;
  }
  .mt-lg-250 {
    margin-top: 15.625rem !important;
  }
  .mt-lg-300 {
    margin-top: 18.75rem !important;
  }
  .mt-lg-350 {
    margin-top: 21.87rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-10 {
    margin-right: 0.625rem !important;
  }
  .me-lg-20 {
    margin-right: 1.25rem !important;
  }
  .me-lg-30 {
    margin-right: 1.875rem !important;
  }
  .me-lg-40 {
    margin-right: 2.5rem !important;
  }
  .me-lg-60 {
    margin-right: 3.75rem !important;
  }
  .me-lg-70 {
    margin-right: 4.375rem !important;
  }
  .me-lg-80 {
    margin-right: 5rem !important;
  }
  .me-lg-90 {
    margin-right: 5.625rem !important;
  }
  .me-lg-100 {
    margin-right: 6.25rem !important;
  }
  .me-lg-120 {
    margin-right: 7.5rem !important;
  }
  .me-lg-140 {
    margin-right: 8.75rem !important;
  }
  .me-lg-150 {
    margin-right: 9.375rem !important;
  }
  .me-lg-180 {
    margin-right: 11.25rem !important;
  }
  .me-lg-200 {
    margin-right: 12.5rem !important;
  }
  .me-lg-230 {
    margin-right: 14.375rem !important;
  }
  .me-lg-250 {
    margin-right: 15.625rem !important;
  }
  .me-lg-300 {
    margin-right: 18.75rem !important;
  }
  .me-lg-350 {
    margin-right: 21.87rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-lg-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-lg-80 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-lg-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-lg-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-lg-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-lg-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-lg-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-lg-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-lg-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-lg-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-lg-350 {
    margin-bottom: 21.87rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-10 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-20 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-30 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-40 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-60 {
    margin-left: 3.75rem !important;
  }
  .ms-lg-70 {
    margin-left: 4.375rem !important;
  }
  .ms-lg-80 {
    margin-left: 5rem !important;
  }
  .ms-lg-90 {
    margin-left: 5.625rem !important;
  }
  .ms-lg-100 {
    margin-left: 6.25rem !important;
  }
  .ms-lg-120 {
    margin-left: 7.5rem !important;
  }
  .ms-lg-140 {
    margin-left: 8.75rem !important;
  }
  .ms-lg-150 {
    margin-left: 9.375rem !important;
  }
  .ms-lg-180 {
    margin-left: 11.25rem !important;
  }
  .ms-lg-200 {
    margin-left: 12.5rem !important;
  }
  .ms-lg-230 {
    margin-left: 14.375rem !important;
  }
  .ms-lg-250 {
    margin-left: 15.625rem !important;
  }
  .ms-lg-300 {
    margin-left: 18.75rem !important;
  }
  .ms-lg-350 {
    margin-left: 21.87rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n10 {
    margin: -0.625rem !important;
  }
  .m-lg-n20 {
    margin: -1.25rem !important;
  }
  .m-lg-n30 {
    margin: -1.875rem !important;
  }
  .m-lg-n40 {
    margin: -2.5rem !important;
  }
  .m-lg-n60 {
    margin: -3.75rem !important;
  }
  .m-lg-n70 {
    margin: -4.375rem !important;
  }
  .m-lg-n80 {
    margin: -5rem !important;
  }
  .m-lg-n90 {
    margin: -5.625rem !important;
  }
  .m-lg-n100 {
    margin: -6.25rem !important;
  }
  .m-lg-n120 {
    margin: -7.5rem !important;
  }
  .m-lg-n140 {
    margin: -8.75rem !important;
  }
  .m-lg-n150 {
    margin: -9.375rem !important;
  }
  .m-lg-n180 {
    margin: -11.25rem !important;
  }
  .m-lg-n200 {
    margin: -12.5rem !important;
  }
  .m-lg-n230 {
    margin: -14.375rem !important;
  }
  .m-lg-n250 {
    margin: -15.625rem !important;
  }
  .m-lg-n300 {
    margin: -18.75rem !important;
  }
  .m-lg-n350 {
    margin: -21.87rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-lg-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-lg-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-lg-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-lg-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-lg-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-lg-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-lg-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-lg-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-lg-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-lg-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-lg-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .mx-lg-n350 {
    margin-right: -21.87rem !important;
    margin-left: -21.87rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-lg-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-lg-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-lg-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-lg-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-lg-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-lg-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-lg-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-lg-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-lg-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-lg-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-lg-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .my-lg-n350 {
    margin-top: -21.87rem !important;
    margin-bottom: -21.87rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-lg-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-lg-n80 {
    margin-top: -5rem !important;
  }
  .mt-lg-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-lg-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-lg-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-lg-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-lg-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-lg-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-lg-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-lg-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-lg-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-lg-n300 {
    margin-top: -18.75rem !important;
  }
  .mt-lg-n350 {
    margin-top: -21.87rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n10 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n20 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n30 {
    margin-right: -1.875rem !important;
  }
  .me-lg-n40 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n60 {
    margin-right: -3.75rem !important;
  }
  .me-lg-n70 {
    margin-right: -4.375rem !important;
  }
  .me-lg-n80 {
    margin-right: -5rem !important;
  }
  .me-lg-n90 {
    margin-right: -5.625rem !important;
  }
  .me-lg-n100 {
    margin-right: -6.25rem !important;
  }
  .me-lg-n120 {
    margin-right: -7.5rem !important;
  }
  .me-lg-n140 {
    margin-right: -8.75rem !important;
  }
  .me-lg-n150 {
    margin-right: -9.375rem !important;
  }
  .me-lg-n180 {
    margin-right: -11.25rem !important;
  }
  .me-lg-n200 {
    margin-right: -12.5rem !important;
  }
  .me-lg-n230 {
    margin-right: -14.375rem !important;
  }
  .me-lg-n250 {
    margin-right: -15.625rem !important;
  }
  .me-lg-n300 {
    margin-right: -18.75rem !important;
  }
  .me-lg-n350 {
    margin-right: -21.87rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-lg-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-lg-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-lg-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-lg-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-lg-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-lg-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-lg-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-lg-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-lg-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-lg-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-lg-n300 {
    margin-bottom: -18.75rem !important;
  }
  .mb-lg-n350 {
    margin-bottom: -21.87rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-lg-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-lg-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-lg-n80 {
    margin-left: -5rem !important;
  }
  .ms-lg-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-lg-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-lg-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-lg-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-lg-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-lg-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-lg-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-lg-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-lg-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-lg-n300 {
    margin-left: -18.75rem !important;
  }
  .ms-lg-n350 {
    margin-left: -21.87rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-10 {
    padding: 0.625rem !important;
  }
  .p-lg-20 {
    padding: 1.25rem !important;
  }
  .p-lg-30 {
    padding: 1.875rem !important;
  }
  .p-lg-40 {
    padding: 2.5rem !important;
  }
  .p-lg-60 {
    padding: 3.75rem !important;
  }
  .p-lg-70 {
    padding: 4.375rem !important;
  }
  .p-lg-80 {
    padding: 5rem !important;
  }
  .p-lg-90 {
    padding: 5.625rem !important;
  }
  .p-lg-100 {
    padding: 6.25rem !important;
  }
  .p-lg-120 {
    padding: 7.5rem !important;
  }
  .p-lg-140 {
    padding: 8.75rem !important;
  }
  .p-lg-150 {
    padding: 9.375rem !important;
  }
  .p-lg-180 {
    padding: 11.25rem !important;
  }
  .p-lg-200 {
    padding: 12.5rem !important;
  }
  .p-lg-230 {
    padding: 14.375rem !important;
  }
  .p-lg-250 {
    padding: 15.625rem !important;
  }
  .p-lg-300 {
    padding: 18.75rem !important;
  }
  .p-lg-350 {
    padding: 21.87rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-lg-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-lg-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-lg-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-lg-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-lg-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-lg-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-lg-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-lg-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-lg-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-lg-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-lg-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .px-lg-350 {
    padding-right: 21.87rem !important;
    padding-left: 21.87rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-lg-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-lg-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-lg-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-lg-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-lg-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-lg-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-lg-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-lg-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-lg-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-lg-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-lg-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .py-lg-350 {
    padding-top: 21.87rem !important;
    padding-bottom: 21.87rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-10 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-20 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-30 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-40 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-60 {
    padding-top: 3.75rem !important;
  }
  .pt-lg-70 {
    padding-top: 4.375rem !important;
  }
  .pt-lg-80 {
    padding-top: 5rem !important;
  }
  .pt-lg-90 {
    padding-top: 5.625rem !important;
  }
  .pt-lg-100 {
    padding-top: 6.25rem !important;
  }
  .pt-lg-120 {
    padding-top: 7.5rem !important;
  }
  .pt-lg-140 {
    padding-top: 8.75rem !important;
  }
  .pt-lg-150 {
    padding-top: 9.375rem !important;
  }
  .pt-lg-180 {
    padding-top: 11.25rem !important;
  }
  .pt-lg-200 {
    padding-top: 12.5rem !important;
  }
  .pt-lg-230 {
    padding-top: 14.375rem !important;
  }
  .pt-lg-250 {
    padding-top: 15.625rem !important;
  }
  .pt-lg-300 {
    padding-top: 18.75rem !important;
  }
  .pt-lg-350 {
    padding-top: 21.87rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-10 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-20 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-30 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-40 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-60 {
    padding-right: 3.75rem !important;
  }
  .pe-lg-70 {
    padding-right: 4.375rem !important;
  }
  .pe-lg-80 {
    padding-right: 5rem !important;
  }
  .pe-lg-90 {
    padding-right: 5.625rem !important;
  }
  .pe-lg-100 {
    padding-right: 6.25rem !important;
  }
  .pe-lg-120 {
    padding-right: 7.5rem !important;
  }
  .pe-lg-140 {
    padding-right: 8.75rem !important;
  }
  .pe-lg-150 {
    padding-right: 9.375rem !important;
  }
  .pe-lg-180 {
    padding-right: 11.25rem !important;
  }
  .pe-lg-200 {
    padding-right: 12.5rem !important;
  }
  .pe-lg-230 {
    padding-right: 14.375rem !important;
  }
  .pe-lg-250 {
    padding-right: 15.625rem !important;
  }
  .pe-lg-300 {
    padding-right: 18.75rem !important;
  }
  .pe-lg-350 {
    padding-right: 21.87rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-lg-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-lg-80 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-lg-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-lg-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-lg-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-lg-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-lg-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-lg-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-lg-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-lg-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-lg-300 {
    padding-bottom: 18.75rem !important;
  }
  .pb-lg-350 {
    padding-bottom: 21.87rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-10 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-20 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-30 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-40 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-60 {
    padding-left: 3.75rem !important;
  }
  .ps-lg-70 {
    padding-left: 4.375rem !important;
  }
  .ps-lg-80 {
    padding-left: 5rem !important;
  }
  .ps-lg-90 {
    padding-left: 5.625rem !important;
  }
  .ps-lg-100 {
    padding-left: 6.25rem !important;
  }
  .ps-lg-120 {
    padding-left: 7.5rem !important;
  }
  .ps-lg-140 {
    padding-left: 8.75rem !important;
  }
  .ps-lg-150 {
    padding-left: 9.375rem !important;
  }
  .ps-lg-180 {
    padding-left: 11.25rem !important;
  }
  .ps-lg-200 {
    padding-left: 12.5rem !important;
  }
  .ps-lg-230 {
    padding-left: 14.375rem !important;
  }
  .ps-lg-250 {
    padding-left: 15.625rem !important;
  }
  .ps-lg-300 {
    padding-left: 18.75rem !important;
  }
  .ps-lg-350 {
    padding-left: 21.87rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-10 {
    gap: 0.625rem !important;
  }
  .gap-lg-20 {
    gap: 1.25rem !important;
  }
  .gap-lg-30 {
    gap: 1.875rem !important;
  }
  .gap-lg-40 {
    gap: 2.5rem !important;
  }
  .gap-lg-60 {
    gap: 3.75rem !important;
  }
  .gap-lg-70 {
    gap: 4.375rem !important;
  }
  .gap-lg-80 {
    gap: 5rem !important;
  }
  .gap-lg-90 {
    gap: 5.625rem !important;
  }
  .gap-lg-100 {
    gap: 6.25rem !important;
  }
  .gap-lg-120 {
    gap: 7.5rem !important;
  }
  .gap-lg-140 {
    gap: 8.75rem !important;
  }
  .gap-lg-150 {
    gap: 9.375rem !important;
  }
  .gap-lg-180 {
    gap: 11.25rem !important;
  }
  .gap-lg-200 {
    gap: 12.5rem !important;
  }
  .gap-lg-230 {
    gap: 14.375rem !important;
  }
  .gap-lg-250 {
    gap: 15.625rem !important;
  }
  .gap-lg-300 {
    gap: 18.75rem !important;
  }
  .gap-lg-350 {
    gap: 21.87rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-10 {
    margin: 0.625rem !important;
  }
  .m-xl-20 {
    margin: 1.25rem !important;
  }
  .m-xl-30 {
    margin: 1.875rem !important;
  }
  .m-xl-40 {
    margin: 2.5rem !important;
  }
  .m-xl-60 {
    margin: 3.75rem !important;
  }
  .m-xl-70 {
    margin: 4.375rem !important;
  }
  .m-xl-80 {
    margin: 5rem !important;
  }
  .m-xl-90 {
    margin: 5.625rem !important;
  }
  .m-xl-100 {
    margin: 6.25rem !important;
  }
  .m-xl-120 {
    margin: 7.5rem !important;
  }
  .m-xl-140 {
    margin: 8.75rem !important;
  }
  .m-xl-150 {
    margin: 9.375rem !important;
  }
  .m-xl-180 {
    margin: 11.25rem !important;
  }
  .m-xl-200 {
    margin: 12.5rem !important;
  }
  .m-xl-230 {
    margin: 14.375rem !important;
  }
  .m-xl-250 {
    margin: 15.625rem !important;
  }
  .m-xl-300 {
    margin: 18.75rem !important;
  }
  .m-xl-350 {
    margin: 21.87rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xl-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-xl-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-xl-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xl-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xl-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-xl-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-xl-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-xl-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-xl-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-xl-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-xl-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-xl-350 {
    margin-right: 21.87rem !important;
    margin-left: 21.87rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xl-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-xl-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-xl-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xl-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xl-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-xl-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-xl-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-xl-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-xl-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-xl-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-xl-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-xl-350 {
    margin-top: 21.87rem !important;
    margin-bottom: 21.87rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-10 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-30 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-40 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-60 {
    margin-top: 3.75rem !important;
  }
  .mt-xl-70 {
    margin-top: 4.375rem !important;
  }
  .mt-xl-80 {
    margin-top: 5rem !important;
  }
  .mt-xl-90 {
    margin-top: 5.625rem !important;
  }
  .mt-xl-100 {
    margin-top: 6.25rem !important;
  }
  .mt-xl-120 {
    margin-top: 7.5rem !important;
  }
  .mt-xl-140 {
    margin-top: 8.75rem !important;
  }
  .mt-xl-150 {
    margin-top: 9.375rem !important;
  }
  .mt-xl-180 {
    margin-top: 11.25rem !important;
  }
  .mt-xl-200 {
    margin-top: 12.5rem !important;
  }
  .mt-xl-230 {
    margin-top: 14.375rem !important;
  }
  .mt-xl-250 {
    margin-top: 15.625rem !important;
  }
  .mt-xl-300 {
    margin-top: 18.75rem !important;
  }
  .mt-xl-350 {
    margin-top: 21.87rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-10 {
    margin-right: 0.625rem !important;
  }
  .me-xl-20 {
    margin-right: 1.25rem !important;
  }
  .me-xl-30 {
    margin-right: 1.875rem !important;
  }
  .me-xl-40 {
    margin-right: 2.5rem !important;
  }
  .me-xl-60 {
    margin-right: 3.75rem !important;
  }
  .me-xl-70 {
    margin-right: 4.375rem !important;
  }
  .me-xl-80 {
    margin-right: 5rem !important;
  }
  .me-xl-90 {
    margin-right: 5.625rem !important;
  }
  .me-xl-100 {
    margin-right: 6.25rem !important;
  }
  .me-xl-120 {
    margin-right: 7.5rem !important;
  }
  .me-xl-140 {
    margin-right: 8.75rem !important;
  }
  .me-xl-150 {
    margin-right: 9.375rem !important;
  }
  .me-xl-180 {
    margin-right: 11.25rem !important;
  }
  .me-xl-200 {
    margin-right: 12.5rem !important;
  }
  .me-xl-230 {
    margin-right: 14.375rem !important;
  }
  .me-xl-250 {
    margin-right: 15.625rem !important;
  }
  .me-xl-300 {
    margin-right: 18.75rem !important;
  }
  .me-xl-350 {
    margin-right: 21.87rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xl-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-xl-80 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-xl-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xl-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xl-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-xl-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-xl-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-xl-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-xl-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-xl-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-xl-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-xl-350 {
    margin-bottom: 21.87rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-10 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-30 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-40 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-60 {
    margin-left: 3.75rem !important;
  }
  .ms-xl-70 {
    margin-left: 4.375rem !important;
  }
  .ms-xl-80 {
    margin-left: 5rem !important;
  }
  .ms-xl-90 {
    margin-left: 5.625rem !important;
  }
  .ms-xl-100 {
    margin-left: 6.25rem !important;
  }
  .ms-xl-120 {
    margin-left: 7.5rem !important;
  }
  .ms-xl-140 {
    margin-left: 8.75rem !important;
  }
  .ms-xl-150 {
    margin-left: 9.375rem !important;
  }
  .ms-xl-180 {
    margin-left: 11.25rem !important;
  }
  .ms-xl-200 {
    margin-left: 12.5rem !important;
  }
  .ms-xl-230 {
    margin-left: 14.375rem !important;
  }
  .ms-xl-250 {
    margin-left: 15.625rem !important;
  }
  .ms-xl-300 {
    margin-left: 18.75rem !important;
  }
  .ms-xl-350 {
    margin-left: 21.87rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n10 {
    margin: -0.625rem !important;
  }
  .m-xl-n20 {
    margin: -1.25rem !important;
  }
  .m-xl-n30 {
    margin: -1.875rem !important;
  }
  .m-xl-n40 {
    margin: -2.5rem !important;
  }
  .m-xl-n60 {
    margin: -3.75rem !important;
  }
  .m-xl-n70 {
    margin: -4.375rem !important;
  }
  .m-xl-n80 {
    margin: -5rem !important;
  }
  .m-xl-n90 {
    margin: -5.625rem !important;
  }
  .m-xl-n100 {
    margin: -6.25rem !important;
  }
  .m-xl-n120 {
    margin: -7.5rem !important;
  }
  .m-xl-n140 {
    margin: -8.75rem !important;
  }
  .m-xl-n150 {
    margin: -9.375rem !important;
  }
  .m-xl-n180 {
    margin: -11.25rem !important;
  }
  .m-xl-n200 {
    margin: -12.5rem !important;
  }
  .m-xl-n230 {
    margin: -14.375rem !important;
  }
  .m-xl-n250 {
    margin: -15.625rem !important;
  }
  .m-xl-n300 {
    margin: -18.75rem !important;
  }
  .m-xl-n350 {
    margin: -21.87rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xl-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xl-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-xl-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-xl-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-xl-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xl-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-xl-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-xl-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-xl-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-xl-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-xl-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-xl-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .mx-xl-n350 {
    margin-right: -21.87rem !important;
    margin-left: -21.87rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xl-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xl-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-xl-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-xl-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-xl-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xl-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-xl-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-xl-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-xl-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-xl-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-xl-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-xl-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .my-xl-n350 {
    margin-top: -21.87rem !important;
    margin-bottom: -21.87rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-xl-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-xl-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-xl-n80 {
    margin-top: -5rem !important;
  }
  .mt-xl-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-xl-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-xl-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-xl-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-xl-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-xl-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-xl-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-xl-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-xl-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-xl-n300 {
    margin-top: -18.75rem !important;
  }
  .mt-xl-n350 {
    margin-top: -21.87rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n10 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n20 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n30 {
    margin-right: -1.875rem !important;
  }
  .me-xl-n40 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n60 {
    margin-right: -3.75rem !important;
  }
  .me-xl-n70 {
    margin-right: -4.375rem !important;
  }
  .me-xl-n80 {
    margin-right: -5rem !important;
  }
  .me-xl-n90 {
    margin-right: -5.625rem !important;
  }
  .me-xl-n100 {
    margin-right: -6.25rem !important;
  }
  .me-xl-n120 {
    margin-right: -7.5rem !important;
  }
  .me-xl-n140 {
    margin-right: -8.75rem !important;
  }
  .me-xl-n150 {
    margin-right: -9.375rem !important;
  }
  .me-xl-n180 {
    margin-right: -11.25rem !important;
  }
  .me-xl-n200 {
    margin-right: -12.5rem !important;
  }
  .me-xl-n230 {
    margin-right: -14.375rem !important;
  }
  .me-xl-n250 {
    margin-right: -15.625rem !important;
  }
  .me-xl-n300 {
    margin-right: -18.75rem !important;
  }
  .me-xl-n350 {
    margin-right: -21.87rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xl-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xl-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-xl-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-xl-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-xl-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xl-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-xl-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-xl-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-xl-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-xl-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-xl-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-xl-n300 {
    margin-bottom: -18.75rem !important;
  }
  .mb-xl-n350 {
    margin-bottom: -21.87rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-xl-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-xl-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-xl-n80 {
    margin-left: -5rem !important;
  }
  .ms-xl-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-xl-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-xl-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-xl-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-xl-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-xl-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-xl-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-xl-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-xl-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-xl-n300 {
    margin-left: -18.75rem !important;
  }
  .ms-xl-n350 {
    margin-left: -21.87rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-10 {
    padding: 0.625rem !important;
  }
  .p-xl-20 {
    padding: 1.25rem !important;
  }
  .p-xl-30 {
    padding: 1.875rem !important;
  }
  .p-xl-40 {
    padding: 2.5rem !important;
  }
  .p-xl-60 {
    padding: 3.75rem !important;
  }
  .p-xl-70 {
    padding: 4.375rem !important;
  }
  .p-xl-80 {
    padding: 5rem !important;
  }
  .p-xl-90 {
    padding: 5.625rem !important;
  }
  .p-xl-100 {
    padding: 6.25rem !important;
  }
  .p-xl-120 {
    padding: 7.5rem !important;
  }
  .p-xl-140 {
    padding: 8.75rem !important;
  }
  .p-xl-150 {
    padding: 9.375rem !important;
  }
  .p-xl-180 {
    padding: 11.25rem !important;
  }
  .p-xl-200 {
    padding: 12.5rem !important;
  }
  .p-xl-230 {
    padding: 14.375rem !important;
  }
  .p-xl-250 {
    padding: 15.625rem !important;
  }
  .p-xl-300 {
    padding: 18.75rem !important;
  }
  .p-xl-350 {
    padding: 21.87rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xl-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-xl-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-xl-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-xl-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xl-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-xl-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-xl-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-xl-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-xl-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-xl-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-xl-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .px-xl-350 {
    padding-right: 21.87rem !important;
    padding-left: 21.87rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xl-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-xl-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-xl-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-xl-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xl-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-xl-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-xl-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-xl-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-xl-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-xl-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-xl-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .py-xl-350 {
    padding-top: 21.87rem !important;
    padding-bottom: 21.87rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-10 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-20 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-30 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-40 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-60 {
    padding-top: 3.75rem !important;
  }
  .pt-xl-70 {
    padding-top: 4.375rem !important;
  }
  .pt-xl-80 {
    padding-top: 5rem !important;
  }
  .pt-xl-90 {
    padding-top: 5.625rem !important;
  }
  .pt-xl-100 {
    padding-top: 6.25rem !important;
  }
  .pt-xl-120 {
    padding-top: 7.5rem !important;
  }
  .pt-xl-140 {
    padding-top: 8.75rem !important;
  }
  .pt-xl-150 {
    padding-top: 9.375rem !important;
  }
  .pt-xl-180 {
    padding-top: 11.25rem !important;
  }
  .pt-xl-200 {
    padding-top: 12.5rem !important;
  }
  .pt-xl-230 {
    padding-top: 14.375rem !important;
  }
  .pt-xl-250 {
    padding-top: 15.625rem !important;
  }
  .pt-xl-300 {
    padding-top: 18.75rem !important;
  }
  .pt-xl-350 {
    padding-top: 21.87rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-10 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-20 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-30 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-40 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-60 {
    padding-right: 3.75rem !important;
  }
  .pe-xl-70 {
    padding-right: 4.375rem !important;
  }
  .pe-xl-80 {
    padding-right: 5rem !important;
  }
  .pe-xl-90 {
    padding-right: 5.625rem !important;
  }
  .pe-xl-100 {
    padding-right: 6.25rem !important;
  }
  .pe-xl-120 {
    padding-right: 7.5rem !important;
  }
  .pe-xl-140 {
    padding-right: 8.75rem !important;
  }
  .pe-xl-150 {
    padding-right: 9.375rem !important;
  }
  .pe-xl-180 {
    padding-right: 11.25rem !important;
  }
  .pe-xl-200 {
    padding-right: 12.5rem !important;
  }
  .pe-xl-230 {
    padding-right: 14.375rem !important;
  }
  .pe-xl-250 {
    padding-right: 15.625rem !important;
  }
  .pe-xl-300 {
    padding-right: 18.75rem !important;
  }
  .pe-xl-350 {
    padding-right: 21.87rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xl-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-xl-80 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-xl-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-xl-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xl-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-xl-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-xl-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-xl-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-xl-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-xl-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-xl-300 {
    padding-bottom: 18.75rem !important;
  }
  .pb-xl-350 {
    padding-bottom: 21.87rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-10 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-20 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-30 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-40 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-60 {
    padding-left: 3.75rem !important;
  }
  .ps-xl-70 {
    padding-left: 4.375rem !important;
  }
  .ps-xl-80 {
    padding-left: 5rem !important;
  }
  .ps-xl-90 {
    padding-left: 5.625rem !important;
  }
  .ps-xl-100 {
    padding-left: 6.25rem !important;
  }
  .ps-xl-120 {
    padding-left: 7.5rem !important;
  }
  .ps-xl-140 {
    padding-left: 8.75rem !important;
  }
  .ps-xl-150 {
    padding-left: 9.375rem !important;
  }
  .ps-xl-180 {
    padding-left: 11.25rem !important;
  }
  .ps-xl-200 {
    padding-left: 12.5rem !important;
  }
  .ps-xl-230 {
    padding-left: 14.375rem !important;
  }
  .ps-xl-250 {
    padding-left: 15.625rem !important;
  }
  .ps-xl-300 {
    padding-left: 18.75rem !important;
  }
  .ps-xl-350 {
    padding-left: 21.87rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-10 {
    gap: 0.625rem !important;
  }
  .gap-xl-20 {
    gap: 1.25rem !important;
  }
  .gap-xl-30 {
    gap: 1.875rem !important;
  }
  .gap-xl-40 {
    gap: 2.5rem !important;
  }
  .gap-xl-60 {
    gap: 3.75rem !important;
  }
  .gap-xl-70 {
    gap: 4.375rem !important;
  }
  .gap-xl-80 {
    gap: 5rem !important;
  }
  .gap-xl-90 {
    gap: 5.625rem !important;
  }
  .gap-xl-100 {
    gap: 6.25rem !important;
  }
  .gap-xl-120 {
    gap: 7.5rem !important;
  }
  .gap-xl-140 {
    gap: 8.75rem !important;
  }
  .gap-xl-150 {
    gap: 9.375rem !important;
  }
  .gap-xl-180 {
    gap: 11.25rem !important;
  }
  .gap-xl-200 {
    gap: 12.5rem !important;
  }
  .gap-xl-230 {
    gap: 14.375rem !important;
  }
  .gap-xl-250 {
    gap: 15.625rem !important;
  }
  .gap-xl-300 {
    gap: 18.75rem !important;
  }
  .gap-xl-350 {
    gap: 21.87rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-10 {
    margin: 0.625rem !important;
  }
  .m-xxl-20 {
    margin: 1.25rem !important;
  }
  .m-xxl-30 {
    margin: 1.875rem !important;
  }
  .m-xxl-40 {
    margin: 2.5rem !important;
  }
  .m-xxl-60 {
    margin: 3.75rem !important;
  }
  .m-xxl-70 {
    margin: 4.375rem !important;
  }
  .m-xxl-80 {
    margin: 5rem !important;
  }
  .m-xxl-90 {
    margin: 5.625rem !important;
  }
  .m-xxl-100 {
    margin: 6.25rem !important;
  }
  .m-xxl-120 {
    margin: 7.5rem !important;
  }
  .m-xxl-140 {
    margin: 8.75rem !important;
  }
  .m-xxl-150 {
    margin: 9.375rem !important;
  }
  .m-xxl-180 {
    margin: 11.25rem !important;
  }
  .m-xxl-200 {
    margin: 12.5rem !important;
  }
  .m-xxl-230 {
    margin: 14.375rem !important;
  }
  .m-xxl-250 {
    margin: 15.625rem !important;
  }
  .m-xxl-300 {
    margin: 18.75rem !important;
  }
  .m-xxl-350 {
    margin: 21.87rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xxl-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-xxl-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-xxl-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xxl-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xxl-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-xxl-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-xxl-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-xxl-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-xxl-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-xxl-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-xxl-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-xxl-350 {
    margin-right: 21.87rem !important;
    margin-left: 21.87rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xxl-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-xxl-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-xxl-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xxl-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xxl-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-xxl-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-xxl-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-xxl-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-xxl-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-xxl-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-xxl-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-xxl-350 {
    margin-top: 21.87rem !important;
    margin-bottom: 21.87rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-10 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-30 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-40 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-60 {
    margin-top: 3.75rem !important;
  }
  .mt-xxl-70 {
    margin-top: 4.375rem !important;
  }
  .mt-xxl-80 {
    margin-top: 5rem !important;
  }
  .mt-xxl-90 {
    margin-top: 5.625rem !important;
  }
  .mt-xxl-100 {
    margin-top: 6.25rem !important;
  }
  .mt-xxl-120 {
    margin-top: 7.5rem !important;
  }
  .mt-xxl-140 {
    margin-top: 8.75rem !important;
  }
  .mt-xxl-150 {
    margin-top: 9.375rem !important;
  }
  .mt-xxl-180 {
    margin-top: 11.25rem !important;
  }
  .mt-xxl-200 {
    margin-top: 12.5rem !important;
  }
  .mt-xxl-230 {
    margin-top: 14.375rem !important;
  }
  .mt-xxl-250 {
    margin-top: 15.625rem !important;
  }
  .mt-xxl-300 {
    margin-top: 18.75rem !important;
  }
  .mt-xxl-350 {
    margin-top: 21.87rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-10 {
    margin-right: 0.625rem !important;
  }
  .me-xxl-20 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-30 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-40 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-60 {
    margin-right: 3.75rem !important;
  }
  .me-xxl-70 {
    margin-right: 4.375rem !important;
  }
  .me-xxl-80 {
    margin-right: 5rem !important;
  }
  .me-xxl-90 {
    margin-right: 5.625rem !important;
  }
  .me-xxl-100 {
    margin-right: 6.25rem !important;
  }
  .me-xxl-120 {
    margin-right: 7.5rem !important;
  }
  .me-xxl-140 {
    margin-right: 8.75rem !important;
  }
  .me-xxl-150 {
    margin-right: 9.375rem !important;
  }
  .me-xxl-180 {
    margin-right: 11.25rem !important;
  }
  .me-xxl-200 {
    margin-right: 12.5rem !important;
  }
  .me-xxl-230 {
    margin-right: 14.375rem !important;
  }
  .me-xxl-250 {
    margin-right: 15.625rem !important;
  }
  .me-xxl-300 {
    margin-right: 18.75rem !important;
  }
  .me-xxl-350 {
    margin-right: 21.87rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xxl-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-xxl-80 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-xxl-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xxl-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xxl-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-xxl-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-xxl-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-xxl-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-xxl-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-xxl-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-xxl-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-xxl-350 {
    margin-bottom: 21.87rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-10 {
    margin-left: 0.625rem !important;
  }
  .ms-xxl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-30 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-40 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-60 {
    margin-left: 3.75rem !important;
  }
  .ms-xxl-70 {
    margin-left: 4.375rem !important;
  }
  .ms-xxl-80 {
    margin-left: 5rem !important;
  }
  .ms-xxl-90 {
    margin-left: 5.625rem !important;
  }
  .ms-xxl-100 {
    margin-left: 6.25rem !important;
  }
  .ms-xxl-120 {
    margin-left: 7.5rem !important;
  }
  .ms-xxl-140 {
    margin-left: 8.75rem !important;
  }
  .ms-xxl-150 {
    margin-left: 9.375rem !important;
  }
  .ms-xxl-180 {
    margin-left: 11.25rem !important;
  }
  .ms-xxl-200 {
    margin-left: 12.5rem !important;
  }
  .ms-xxl-230 {
    margin-left: 14.375rem !important;
  }
  .ms-xxl-250 {
    margin-left: 15.625rem !important;
  }
  .ms-xxl-300 {
    margin-left: 18.75rem !important;
  }
  .ms-xxl-350 {
    margin-left: 21.87rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n10 {
    margin: -0.625rem !important;
  }
  .m-xxl-n20 {
    margin: -1.25rem !important;
  }
  .m-xxl-n30 {
    margin: -1.875rem !important;
  }
  .m-xxl-n40 {
    margin: -2.5rem !important;
  }
  .m-xxl-n60 {
    margin: -3.75rem !important;
  }
  .m-xxl-n70 {
    margin: -4.375rem !important;
  }
  .m-xxl-n80 {
    margin: -5rem !important;
  }
  .m-xxl-n90 {
    margin: -5.625rem !important;
  }
  .m-xxl-n100 {
    margin: -6.25rem !important;
  }
  .m-xxl-n120 {
    margin: -7.5rem !important;
  }
  .m-xxl-n140 {
    margin: -8.75rem !important;
  }
  .m-xxl-n150 {
    margin: -9.375rem !important;
  }
  .m-xxl-n180 {
    margin: -11.25rem !important;
  }
  .m-xxl-n200 {
    margin: -12.5rem !important;
  }
  .m-xxl-n230 {
    margin: -14.375rem !important;
  }
  .m-xxl-n250 {
    margin: -15.625rem !important;
  }
  .m-xxl-n300 {
    margin: -18.75rem !important;
  }
  .m-xxl-n350 {
    margin: -21.87rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xxl-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xxl-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xxl-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xxl-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-xxl-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-xxl-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-xxl-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xxl-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-xxl-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-xxl-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-xxl-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-xxl-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-xxl-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-xxl-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .mx-xxl-n350 {
    margin-right: -21.87rem !important;
    margin-left: -21.87rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xxl-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xxl-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xxl-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xxl-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-xxl-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-xxl-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-xxl-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xxl-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-xxl-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-xxl-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-xxl-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-xxl-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-xxl-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-xxl-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .my-xxl-n350 {
    margin-top: -21.87rem !important;
    margin-bottom: -21.87rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-xxl-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-xxl-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-xxl-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-xxl-n80 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-xxl-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-xxl-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-xxl-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-xxl-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-xxl-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-xxl-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-xxl-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-xxl-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-xxl-n300 {
    margin-top: -18.75rem !important;
  }
  .mt-xxl-n350 {
    margin-top: -21.87rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n10 {
    margin-right: -0.625rem !important;
  }
  .me-xxl-n20 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n30 {
    margin-right: -1.875rem !important;
  }
  .me-xxl-n40 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n60 {
    margin-right: -3.75rem !important;
  }
  .me-xxl-n70 {
    margin-right: -4.375rem !important;
  }
  .me-xxl-n80 {
    margin-right: -5rem !important;
  }
  .me-xxl-n90 {
    margin-right: -5.625rem !important;
  }
  .me-xxl-n100 {
    margin-right: -6.25rem !important;
  }
  .me-xxl-n120 {
    margin-right: -7.5rem !important;
  }
  .me-xxl-n140 {
    margin-right: -8.75rem !important;
  }
  .me-xxl-n150 {
    margin-right: -9.375rem !important;
  }
  .me-xxl-n180 {
    margin-right: -11.25rem !important;
  }
  .me-xxl-n200 {
    margin-right: -12.5rem !important;
  }
  .me-xxl-n230 {
    margin-right: -14.375rem !important;
  }
  .me-xxl-n250 {
    margin-right: -15.625rem !important;
  }
  .me-xxl-n300 {
    margin-right: -18.75rem !important;
  }
  .me-xxl-n350 {
    margin-right: -21.87rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xxl-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xxl-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xxl-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-xxl-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-xxl-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-xxl-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xxl-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-xxl-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-xxl-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-xxl-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-xxl-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-xxl-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-xxl-n300 {
    margin-bottom: -18.75rem !important;
  }
  .mb-xxl-n350 {
    margin-bottom: -21.87rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-xxl-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-xxl-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-xxl-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-xxl-n80 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-xxl-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-xxl-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-xxl-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-xxl-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-xxl-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-xxl-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-xxl-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-xxl-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-xxl-n300 {
    margin-left: -18.75rem !important;
  }
  .ms-xxl-n350 {
    margin-left: -21.87rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-10 {
    padding: 0.625rem !important;
  }
  .p-xxl-20 {
    padding: 1.25rem !important;
  }
  .p-xxl-30 {
    padding: 1.875rem !important;
  }
  .p-xxl-40 {
    padding: 2.5rem !important;
  }
  .p-xxl-60 {
    padding: 3.75rem !important;
  }
  .p-xxl-70 {
    padding: 4.375rem !important;
  }
  .p-xxl-80 {
    padding: 5rem !important;
  }
  .p-xxl-90 {
    padding: 5.625rem !important;
  }
  .p-xxl-100 {
    padding: 6.25rem !important;
  }
  .p-xxl-120 {
    padding: 7.5rem !important;
  }
  .p-xxl-140 {
    padding: 8.75rem !important;
  }
  .p-xxl-150 {
    padding: 9.375rem !important;
  }
  .p-xxl-180 {
    padding: 11.25rem !important;
  }
  .p-xxl-200 {
    padding: 12.5rem !important;
  }
  .p-xxl-230 {
    padding: 14.375rem !important;
  }
  .p-xxl-250 {
    padding: 15.625rem !important;
  }
  .p-xxl-300 {
    padding: 18.75rem !important;
  }
  .p-xxl-350 {
    padding: 21.87rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xxl-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-xxl-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-xxl-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-xxl-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xxl-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-xxl-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-xxl-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-xxl-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-xxl-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-xxl-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-xxl-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .px-xxl-350 {
    padding-right: 21.87rem !important;
    padding-left: 21.87rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xxl-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-xxl-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-xxl-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-xxl-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xxl-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-xxl-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-xxl-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-xxl-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-xxl-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-xxl-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-xxl-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .py-xxl-350 {
    padding-top: 21.87rem !important;
    padding-bottom: 21.87rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-10 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-20 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-30 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-40 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-60 {
    padding-top: 3.75rem !important;
  }
  .pt-xxl-70 {
    padding-top: 4.375rem !important;
  }
  .pt-xxl-80 {
    padding-top: 5rem !important;
  }
  .pt-xxl-90 {
    padding-top: 5.625rem !important;
  }
  .pt-xxl-100 {
    padding-top: 6.25rem !important;
  }
  .pt-xxl-120 {
    padding-top: 7.5rem !important;
  }
  .pt-xxl-140 {
    padding-top: 8.75rem !important;
  }
  .pt-xxl-150 {
    padding-top: 9.375rem !important;
  }
  .pt-xxl-180 {
    padding-top: 11.25rem !important;
  }
  .pt-xxl-200 {
    padding-top: 12.5rem !important;
  }
  .pt-xxl-230 {
    padding-top: 14.375rem !important;
  }
  .pt-xxl-250 {
    padding-top: 15.625rem !important;
  }
  .pt-xxl-300 {
    padding-top: 18.75rem !important;
  }
  .pt-xxl-350 {
    padding-top: 21.87rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-10 {
    padding-right: 0.625rem !important;
  }
  .pe-xxl-20 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-30 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-40 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-60 {
    padding-right: 3.75rem !important;
  }
  .pe-xxl-70 {
    padding-right: 4.375rem !important;
  }
  .pe-xxl-80 {
    padding-right: 5rem !important;
  }
  .pe-xxl-90 {
    padding-right: 5.625rem !important;
  }
  .pe-xxl-100 {
    padding-right: 6.25rem !important;
  }
  .pe-xxl-120 {
    padding-right: 7.5rem !important;
  }
  .pe-xxl-140 {
    padding-right: 8.75rem !important;
  }
  .pe-xxl-150 {
    padding-right: 9.375rem !important;
  }
  .pe-xxl-180 {
    padding-right: 11.25rem !important;
  }
  .pe-xxl-200 {
    padding-right: 12.5rem !important;
  }
  .pe-xxl-230 {
    padding-right: 14.375rem !important;
  }
  .pe-xxl-250 {
    padding-right: 15.625rem !important;
  }
  .pe-xxl-300 {
    padding-right: 18.75rem !important;
  }
  .pe-xxl-350 {
    padding-right: 21.87rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xxl-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-xxl-80 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-xxl-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-xxl-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xxl-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-xxl-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-xxl-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-xxl-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-xxl-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-xxl-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-xxl-300 {
    padding-bottom: 18.75rem !important;
  }
  .pb-xxl-350 {
    padding-bottom: 21.87rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-10 {
    padding-left: 0.625rem !important;
  }
  .ps-xxl-20 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-30 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-40 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-60 {
    padding-left: 3.75rem !important;
  }
  .ps-xxl-70 {
    padding-left: 4.375rem !important;
  }
  .ps-xxl-80 {
    padding-left: 5rem !important;
  }
  .ps-xxl-90 {
    padding-left: 5.625rem !important;
  }
  .ps-xxl-100 {
    padding-left: 6.25rem !important;
  }
  .ps-xxl-120 {
    padding-left: 7.5rem !important;
  }
  .ps-xxl-140 {
    padding-left: 8.75rem !important;
  }
  .ps-xxl-150 {
    padding-left: 9.375rem !important;
  }
  .ps-xxl-180 {
    padding-left: 11.25rem !important;
  }
  .ps-xxl-200 {
    padding-left: 12.5rem !important;
  }
  .ps-xxl-230 {
    padding-left: 14.375rem !important;
  }
  .ps-xxl-250 {
    padding-left: 15.625rem !important;
  }
  .ps-xxl-300 {
    padding-left: 18.75rem !important;
  }
  .ps-xxl-350 {
    padding-left: 21.87rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-10 {
    gap: 0.625rem !important;
  }
  .gap-xxl-20 {
    gap: 1.25rem !important;
  }
  .gap-xxl-30 {
    gap: 1.875rem !important;
  }
  .gap-xxl-40 {
    gap: 2.5rem !important;
  }
  .gap-xxl-60 {
    gap: 3.75rem !important;
  }
  .gap-xxl-70 {
    gap: 4.375rem !important;
  }
  .gap-xxl-80 {
    gap: 5rem !important;
  }
  .gap-xxl-90 {
    gap: 5.625rem !important;
  }
  .gap-xxl-100 {
    gap: 6.25rem !important;
  }
  .gap-xxl-120 {
    gap: 7.5rem !important;
  }
  .gap-xxl-140 {
    gap: 8.75rem !important;
  }
  .gap-xxl-150 {
    gap: 9.375rem !important;
  }
  .gap-xxl-180 {
    gap: 11.25rem !important;
  }
  .gap-xxl-200 {
    gap: 12.5rem !important;
  }
  .gap-xxl-230 {
    gap: 14.375rem !important;
  }
  .gap-xxl-250 {
    gap: 15.625rem !important;
  }
  .gap-xxl-300 {
    gap: 18.75rem !important;
  }
  .gap-xxl-350 {
    gap: 21.87rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/* 1.5 */
/* Buttons
/* ========================================================================== */
.btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.btn-theme {
  color: #ffffff;
  border-width: 1px;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  padding: 13px 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn-theme:hover {
  background-color: #14181c;
  border-color: #14181c;
  color: #ffffff;
}

.btn-theme .icon-left {
  margin-right: 7px;
}

.btn-theme .icon-right {
  margin-left: 7px;
}

.btn-theme.btn-block {
  max-width: 450px;
}

.btn-theme-transparent {
  background-color: transparent;
  border-width: 1px;
  border-color: #e9e9e9;
  color: #14181c;
}

.btn-theme-transparent:focus {
  background-color: transparent;
  border-width: 1px;
  border-color: #e9e9e9;
  color: #14181c;
}

.btn-theme-transparent:active {
  background-color: transparent;
  border-width: 1px;
  border-color: #e9e9e9;
  color: #14181c;
}

.btn-theme-transparent:hover {
  background-color: #14181c;
  border-color: #14181c;
  color: #ffffff;
}

.dark .btn-theme-transparent {
  color: #ffffff;
}

.dark .btn-theme-transparent:focus {
  color: #ffffff;
}

.dark .btn-theme-transparent:active {
  color: #ffffff;
}

.dark .btn-theme-transparent:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #14181c;
}

.btn-theme-dark {
  background-color: #14181c;
  border-width: 1px;
  border-color: #14181c;
  color: #ffffff;
}

.btn-theme-dark:focus {
  background-color: #14181c;
  border-width: 1px;
  border-color: #14181c;
  color: #ffffff;
}

.btn-theme-dark:active {
  background-color: #14181c;
  border-width: 1px;
  border-color: #14181c;
  color: #ffffff;
}

.btn-theme-dark:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: #ffffff;
}

.btn-theme-light {
  background-color: #ffffff;
  border-width: 1px;
  border-color: #e6e9eb;
  color: #6d7480;
}

.btn-theme-light:focus {
  background-color: #ffffff;
  border-width: 1px;
  border-color: #e6e9eb;
  color: #6d7480;
}

.btn-theme-light:active {
  background-color: #ffffff;
  border-width: 1px;
  border-color: #e6e9eb;
  color: #6d7480;
}

.btn-theme-light:hover {
  background-color: #14181c;
  border-color: #14181c;
  color: #ffffff;
}

.btn-theme-xs {
  font-size: 11px;
  padding: 7px 12px;
}

.btn-theme-sm {
  font-size: 14px;
  padding: 7px 20px;
}

.btn-theme-md {
  padding: 17px 25px;
}

.btn-theme-lg {
  font-size: 18px;
  padding: 20px 35px;
}

.btn-theme-xl {
  font-size: 24px;
  padding: 25px 35px;
}

.btn-icon-left .fa {
  margin-right: 7px;
}

.btn-icon-left .glyphicon {
  margin-right: 7px;
}

.btn-icon-right .fa {
  margin-left: 7px;
}

.btn-icon-right .glyphicon {
  margin-left: 7px;
}

.btn-more-posts {
  font-size: 12px;
  font-weight: 900;
  padding: 20px 60px;
}

.btn-play {
  position: relative;
  display: inline-block !important;
  padding: 0 !important;
  width: 150px !important;
  height: 150px !important;
  border-radius: 50% !important;
  text-align: center;
  background-color: rgba(230, 0, 0, 0.85);
  color: #ffffff;
}

.btn-play .fa {
  font-size: 60px;
  line-height: 150px;
  margin-right: -9px;
}

.btn-play:before {
  content: '';
  display: block;
  position: absolute;
  width: 180px !important;
  height: 180px !important;
  top: -16px;
  left: -16px;
  border-radius: 50% !important;
  border: solid 10px rgba(230, 0, 0, 0.35);
}

.btn-play:hover {
  background-color: #ffffff;
  color: var(--bs-primary);
}

a:hover .btn-play {
  background-color: #ffffff;
  color: var(--bs-primary);
}

.btn-title-more {
  font-size: 11px;
  padding: 13px;
  margin-top: -10px;
  margin-left: 20px;
  margin-bottom: -30px;
  background-color: transparent;
  border-width: 1px;
  border-color: #e9e9e9;
  color: #14181c;
}

.btn-title-more .fa {
  font-size: 14px;
  margin-top: -1px;
}

.btn-title-more .glyphicon {
  font-size: 14px;
  margin-top: -1px;
}

.btn-title-more:hover {
  background-color: #14181c;
  border-color: #14181c;
  color: #ffffff;
}

p.btn-row {
  margin-top: -10px;
}

p.btn-row .btn {
  margin-top: 10px;
  margin-right: 10px;
}

p.btn-row .text {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.btn-view-more-block {
  width: 100%;
  max-width: 100%;
  display: block;
  font-size: 18px;
  padding: 13px 35px;
  border-color: #a5abb7;
  background-color: #a5abb7;
  color: #ffffff;
}

.btn-view-more-block:hover {
  border-color: #14181c;
  background-color: #14181c;
}

a.btn.twitter {
  background-color: #2daae1;
  border-color: #2daae1;
}

a.btn.facebook {
  background-color: #3c5b9b;
  border-color: #3c5b9b;
}

a.btn.google {
  background-color: #f63f29;
  border-color: #f63f29;
}

a.btn[title*='Google+'] {
  background-color: #f63f29;
  border-color: #f63f29;
}

a.btn.flickr {
  background-color: #216BD4;
  border-color: #216BD4;
}

a.btn.dribbble {
  background-color: #F46899;
  border-color: #F46899;
}

a.btn.linkedin {
  background-color: #0085AE;
  border-color: #0085AE;
}

a.btn.tumblr {
  background-color: #2C4762;
  border-color: #2C4762;
}

a.btn.instagram {
  background-color: #517fa4;
  border-color: #517fa4;
}

a.btn.pinterest {
  background-color: #cb2027;
  border-color: #cb2027;
}

a.btn.skype {
  background-color: #00aaf1;
  border-color: #00aaf1;
}

a.btn.vimeo {
  background-color: #5BC8FF;
  border-color: #5BC8FF;
}

/* -- Ripple effect ======================================================================== */
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}

@-moz-keyframes ripple {
  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
    transform: scale(2.5);
  }
}

@-ms-keyframes ripple {
  100% {
    opacity: 0;
    -ms-transform: scale(2.5);
    transform: scale(2.5);
  }
}

@-o-keyframes ripple {
  100% {
    opacity: 0;
    -o-transform: scale(2.5);
    transform: scale(2.5);
  }
}

ripple-effect {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.ink {
  display: block;
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  background: #fff;
  opacity: 1;
}

.ink.animate {
  -webkit-animation: ripple .5s linear;
  -moz-animation: ripple .5s linear;
  -ms-animation: ripple .5s linear;
  -o-animation: ripple .5s linear;
  animation: ripple .5s linear;
}

/* 1.6 */
/* Form / Input / Textarea / Select
/* ========================================================================== */
@media (min-width: 992px) {
  .slide1 .row-inputs .container-fluid, .slide1 .row-inputs .container-sm, .slide1 .row-inputs .container-md, .slide1 .row-inputs .container-lg, .slide1 .row-inputs .container-xl, .slide1 .row-inputs .container-xxl {
    display: flex;
    align-items: end;
  }
}

.slide1 .form-control-icon, .slide2 .form-control-icon, .slide3 .form-control-icon {
  top: 38%;
  right: 2%;
}

.form-control {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 50px;
  border-radius: 0;
  border: 1px solid #e9e9e9;
  color: #14181c;
}

.form-control:focus {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #14181c;
}

.has-icon .form-control {
  padding-right: 50px;
}

.has-icon .bootstrap-select.btn-group .btn .caret {
  display: none;
}

.dark .form-control {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.6);
}

.dark .form-control:focus {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.dark .bootstrap-select .dropdown-menu .form-control {
  background: rgba(20, 24, 28, 0.2);
  border: 1px solid rgba(20, 24, 28, 0);
  color: rgba(20, 24, 28, 0.6);
}

.dark .bootstrap-select .dropdown-menu .form-control:focus {
  background: rgba(20, 24, 28, 0.1);
  border: 1px solid rgba(20, 24, 28, 0.2);
}

.dark .form-control-icon {
  color: rgba(255, 255, 255, 0.6);
}

.dark .alt .form-control-icon {
  color: #ffffff;
}

.dark label {
  color: #ffffff;
}

.light .form-control {
  background: #ffffff;
  border: 1px solid #d3d3d3;
  color: #a5abb7;
}

.light .form-control:focus {
  border: 1px solid #a5abb7;
}

.light .form-control-icon {
  color: rgba(20, 24, 28, 0.6);
}

.light label {
  color: #14181c;
  font-weight: 700;
}

.form-control.alt {
  border-color: #f5f6f8;
  background-color: #f5f6f8;
  color: #6d7582;
}

.form-control.alt::-webkit-input-placeholder {
  color: #6d7582 !important;
  font-weight: 400 !important;
  font-size: 14px;
  text-transform: none;
  line-height: 22px;
}

.form-control.alt:-moz-placeholder {
  color: #6d7582 !important;
  font-weight: 400 !important;
  font-size: 14px;
  text-transform: none;
  line-height: 22px;
}

.form-control.alt::-moz-placeholder {
  color: #6d7582 !important;
  font-weight: 400 !important;
  font-size: 14px;
  text-transform: none;
  line-height: 22px;
}

.form-control.alt:-ms-input-placeholder {
  color: #6d7582 !important;
  font-weight: 400 !important;
  font-size: 14px;
  text-transform: none;
  line-height: 22px;
}

.form-control.alt:focus {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #14181c;
}

.form-control-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.form-control-icon.clock {
  top: 38%;
}

.has-label .form-control-icon {
  top: 28px;
}

.form-group.has-icon {
  position: relative;
}

.form-group.has-icon .sr-only {
  display: none;
}

textarea {
  resize: none;
}

.bootstrap-select .dropdown-menu {
  border-radius: 0;
}

.bootstrap-select .dropdown-menu a {
  padding-top: 5px;
  padding-bottom: 5px;
}

.bootstrap-select > .selectpicker {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none !important;
  height: 40px;
  border-radius: 0;
  background-color: transparent !important;
  color: #5c5c5c !important;
}

.bootstrap-select > .selectpicker:focus {
  border-color: #14181c;
}

.bootstrap-select-searchbox .form-control {
  border-radius: 0;
}

.alert {
  border-radius: 0;
}

label {
  font-size: 14px;
  color: #14181c;
}

input[type=checkbox] {
  margin-top: 2px;
  margin-right: 5px;
}

.form-login .row {
  margin-top: -20px;
}

.form-login .row + .row {
  margin-top: 0;
}

.form-login .row div[class*="col-"] {
  margin-top: 20px;
}

.form-login .row aside[class*="col-"] {
  margin-top: 20px;
}

.form-login .hello-text-wrap {
  line-height: 12px;
}

.form-login .btn-block {
  margin-left: auto;
  margin-right: auto;
}

.form-login .btn.facebook {
  background-color: #3e5c98;
  border-color: #3e5c98;
  color: #ffffff;
}

.form-login .btn.facebook:hover {
  background-color: #14181c;
  border-color: #14181c;
}

.form-login .btn.twitter {
  background-color: #22a9e0;
  border-color: #22a9e0;
  color: #ffffff;
}

.form-login .btn.twitter:hover {
  background-color: #14181c;
  border-color: #14181c;
}

.form-login .btn-theme {
  padding-top: 14px;
  padding-bottom: 14px;
}

.form-login .form-group {
  margin-bottom: 0;
}

.form-login .form-control {
  height: 50px;
}

.form-login .checkbox {
  font-size: 11px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #14181c;
}

.form-login .checkbox label {
  font-weight: 700 !important;
}

.form-login .checkbox input[type=checkbox] {
  margin-top: 2px;
}

.form-login .col-md-12.col-lg-6.text-right-lg {
  line-height: 0;
}

.form-login .forgot-password {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 18px;
}

.form-login .forgot-password:hover {
  border-bottom: solid 1px #14181c;
}

.form-login::-webkit-input-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-login:-moz-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-login::-moz-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-login:-ms-input-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.create-account {
  color: #14181c;
}

.create-account .list-check {
  font-weight: 700;
}

.create-account .row {
  margin-top: -20px;
}

.create-account .row + .row {
  margin-top: 0;
}

.create-account .row div[class*="col-"] {
  margin-top: 20px;
}

.create-account .row aside[class*="col-"] {
  margin-top: 20px;
}

.create-account .hello-text-wrap {
  line-height: 12px;
}

.create-account .btn-theme {
  padding-top: 14px;
  padding-bottom: 14px;
}

.create-account .btn-create {
  margin-top: 15px;
}

.page-section.subscribe {
  background-attachment: scroll !important;
}

.form-subscribe {
  position: relative;
}

.form-subscribe .form-control {
  border-color: #14181c;
}

.form-subscribe .btn-submit {
  position: absolute;
  top: 24px;
  right: 0;
  font-size: 12px;
  line-height: 12px;
  padding: 18px 50px;
}

.page-section.find-car {
  overflow: visible;
  padding-top: 24px;
  padding-bottom: 24px;
}

.form-find-car .section-title {
  margin-top: 4px;
}

.form-find-car .form-group {
  margin: 0;
}

.form-find-car .form-control {
  height: 40px;
}

.form-find-car .has-icon .form-control {
  padding-right: 40px;
}

.form-find-car .form-control-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.form-find-car .btn-submit {
  margin-top: 24px;
  font-size: 12px;
  line-height: 12px;
  padding: 13px 20px;
}

@media (min-width: 992px) {
  .form-login .btn-block {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .form-login .col-md-12.col-lg-6.text-right-lg {
    margin-bottom: 2px;
  }
}

@media (max-width: 1199px) {
  .form-login .col-md-12.col-lg-6.text-right-lg {
    margin-top: 0;
  }
}

.form-select {
  border-radius: 0;
}

.form-select:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}

/* 1.2 */
/* Global properties (body, common classes, structure etc)
/* ========================================================================== */
body {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  line-height: 1.6;
  background: #fbfbfb;
  color: #5c5c5c;
  -webkit-font-kerning: auto;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: visible !important;
  position: relative;
  overflow-x: hidden;
}

body.boxed {
  background: #fbfbfb;
}

/* 1.3 */
/* Layout
/* ========================================================================== */
html {
  height: 100%;
  min-height: 100%;
}

body {
  min-height: 100%;
}

.content-area {
  position: relative;
  z-index: 0;
}

.widget *:last-child {
  margin-bottom: 0;
}

.content .widget + .widget {
  margin-top: 30px;
}

.sidebar .widget + .widget {
  margin-top: 30px;
}

.footer .widget + .widget {
  margin-top: 30px;
}

.container.full-width {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.row {
  margin-top: -30px;
}

.row + .row {
  margin-top: 0;
}

.row div[class*="col-"] {
  margin-top: 30px;
}

.row div[class*="col-"]:after {
  content: '';
  display: block;
  clear: both;
}

.row aside[class*="col-"] {
  margin-top: 30px;
}

.row aside[class*="col-"]:after {
  content: '';
  display: block;
  clear: both;
}

.boxed .wrapper {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

/* Page section block
  /* ========================================================================== */
.page-section {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .page-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.page-section.md-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.page-section.sm-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.page-section.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.page-section.no-padding-top {
  padding-top: 0;
}

.page-section.no-padding-bottom {
  padding-bottom: 0;
}

.page-section.no-bottom-space {
  margin-bottom: -70px;
}

.page-section.light {
  background-color: #f4f6f7;
}

.page-section.dark {
  background-color: #14181c;
  color: #8e9091;
}

.page-section.image {
  background: url(../img/page-section-image.png) center top no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.page-section.of-visible {
  overflow: visible;
}

.page-section.sub-page {
  padding-top: 30px;
}

html {
  position: relative;
  height: 100%;
}

body {
  position: relative;
  height: 100%;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: auto;
}

/* -------------------------------------------------------------------------- */
/* 1.1 */
/* Preloader
/* ========================================================================== */
@-webkit-keyframes stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

#preloader {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
}

#preloader-status {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin: -100px 0 0 -100px;
}

#preloader-title {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
}

.spinner {
  margin: 0 auto;
  width: 120px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: var(--bs-primary);
  height: 100%;
  width: 20px;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

/* 1.4 */
/* Typography (section title, links, page dividers)
/* ========================================================================== */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'Poppins', sans-serif;
  color: var(--bs-primary);
}

.dark .h1,
.dark .h2,
.dark .h3,
.dark .h4,
.dark .h5,
.dark .h6,
.dark h1,
.dark .h1,
.dark h2,
.dark .h2,
.dark h3,
.dark .h3,
.dark h4,
.dark .h4,
.dark h5,
.dark .h5,
.dark h6,
.dark .h6,
.color .h1 small,
.color .h1 .small,
.color .h2 small,
.color .h2 .small,
.color .h3 small,
.color .h3 .small,
.color .h4 small,
.color .h4 .small,
.color .h5 small,
.color .h5 .small,
.color .h6 small,
.color .h6 .small,
.color h1 small,
.color .h1 small,
.color h1 .small,
.color .h1 .small,
.color h2 small,
.color .h2 small,
.color h2 .small,
.color .h2 .small,
.color h3 small,
.color .h3 small,
.color h3 .small,
.color .h3 .small,
.color h4 small,
.color .h4 small,
.color h4 .small,
.color .h4 .small,
.color h5 small,
.color .h5 small,
.color h5 .small,
.color .h5 .small,
.color h6 small,
.color .h6 small,
.color h6 .small,
.color .h6 .small {
  color: #ffffff;
}

h1 .fa, .h1 .fa,
h2 .fa,
.h2 .fa,
h3 .fa,
.h3 .fa,
h4 .fa,
.h4 .fa,
h5 .fa,
.h5 .fa,
h6 .fa,
.h6 .fa,
h1 .glyphicon,
.h1 .glyphicon,
h2 .glyphicon,
.h2 .glyphicon,
h3 .glyphicon,
.h3 .glyphicon,
h4 .glyphicon,
.h4 .glyphicon,
h5 .glyphicon,
.h5 .glyphicon,
h6 .glyphicon,
.h6 .glyphicon {
  color: #b30000;
}

.image h1 .fa, .image .h1 .fa,
.image h2 .fa,
.image .h2 .fa,
.image h3 .fa,
.image .h3 .fa,
.image h4 .fa,
.image .h4 .fa,
.image h5 .fa,
.image .h5 .fa,
.image h6 .fa,
.image .h6 .fa,
.image h1 .glyphicon,
.image .h1 .glyphicon,
.image h2 .glyphicon,
.image .h2 .glyphicon,
.image h3 .glyphicon,
.image .h3 .glyphicon,
.image h4 .glyphicon,
.image .h4 .glyphicon,
.image h5 .glyphicon,
.image .h5 .glyphicon,
.image h6 .glyphicon,
.image .h6 .glyphicon,
.color h1 .fa,
.color .h1 .fa,
.color h2 .fa,
.color .h2 .fa,
.color h3 .fa,
.color .h3 .fa,
.color h4 .fa,
.color .h4 .fa,
.color h5 .fa,
.color .h5 .fa,
.color h6 .fa,
.color .h6 .fa,
.color h1 .glyphicon,
.color .h1 .glyphicon,
.color h2 .glyphicon,
.color .h2 .glyphicon,
.color h3 .glyphicon,
.color .h3 .glyphicon,
.color h4 .glyphicon,
.color .h4 .glyphicon,
.color h5 .glyphicon,
.color .h5 .glyphicon,
.color h6 .glyphicon,
.color .h6 .glyphicon {
  color: #ffffff;
}

a,
a .fa,
a .glyphicon,
a:hover,
a:hover .fa,
a:hover .glyphicon,
a:active,
a:focus {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

a:not(.btn-theme) {
  color: var(--bs-primary);
}

a:not(.btn-theme):hover,
a:not(.btn-theme):active,
a:not(.btn-theme):focus {
  color: #000000;
}

.footer a:not(.btn-theme) {
  color: #14181c;
}

.footer a:not(.btn-theme):hover,
.footer a:not(.btn-theme):active,
.footer a:not(.btn-theme):focus {
  color: var(--bs-primary);
}

p {
  margin-bottom: 20px;
}

ul,
ol {
  padding-left: 0;
  list-style: none;
  margin-bottom: 20px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  padding-left: 20px;
}

ul.list-check li,
ol.list-check li {
  padding-left: 20px;
  position: relative;
}

ul.list-check li:before,
ol.list-check li:before {
  content: '\f046';
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  left: 0;
}

ul.list-check li + li,
ol.list-check li + li {
  margin-top: 7px;
}

ul.list-icons li {
  padding-left: 30px;
  position: relative;
}

ul.list-icons li + li {
  margin-top: 10px;
}

ul.list-icons li > .fa {
  position: absolute;
  left: 0;
  top: 3px;
}

.section-title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  color: #14181c;
  overflow: hidden;
}

.section-title small, .section-title .small {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: none;
}

.section-title small + span, .section-title .small + span {
  margin-top: 10px;
}

.section-title span {
  display: block;
}

.section-title span + small, .section-title span + .small {
  margin-top: 10px;
}

.section-title .thin {
  font-weight: 300 !important;
}

.section-title.section-title-lg {
  font-size: 24px;
}

.section-title.text-left {
  text-align: left;
}

.section-title.text-right {
  text-align: right;
}

.section-title.no-margin {
  margin: 0;
}

.dark .section-title {
  color: #ffffff;
}

.dark .section-title small, .dark .section-title .small {
  color: #ffffff;
}

.block-title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 900;
  line-height: 20px;
  text-transform: uppercase;
  color: #14181c;
  overflow: hidden;
}

.block-title span {
  display: inline-block;
  position: relative;
}

.block-title span:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 2000px;
  background-color: #a5abb7;
  top: 10px;
  left: 100%;
  margin-left: 15px;
}

.block-title .thin {
  font-weight: 300;
  margin-left: 5px;
}

.block-title.alt {
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 10px 70px 10px 20px;
  border: solid 1px #a5abb7;
  overflow: visible;
  position: relative;
  color: #7f7f7f;
}

.block-title.alt .fa {
  position: absolute;
  top: -1px;
  right: -1px;
  height: 46px;
  width: 46px;
  line-height: 45px;
  vertical-align: middle;
  text-align: center !important;
  background-color: #f5f6f8;
  border: solid 1px #a5abb7;
  color: #a5abb7;
  z-index: 20;
}

.block-title.alt .fa.color {
  background-color: var(--bs-primary);
}

.block-title.alt2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0 50px 20px 0;
  border-bottom: solid 1px #e9e9e9;
  overflow: visible;
  position: relative;
  color: #7f7f7f;
}

.block-title.alt2 .fa {
  position: absolute;
  top: -1px;
  right: -1px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center !important;
  background-color: #e9e9e9;
  color: #a5abb7;
  z-index: 20;
}

.block-title.alt2 .fa.color {
  background-color: var(--bs-primary);
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
}

.text-lg {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1.2;
  color: #9a0000;
}

.text-xl {
  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
}

.text-thin {
  font-size: 11px;
  text-transform: uppercase;
  color: #14181c;
}

.text-dark {
  color: #14181c;
}

.text-color {
  color: var(--bs-primary);
}

.drop-cap {
  display: block;
  font-size: 44px;
  line-height: 37px;
  margin: 0 10px 0 0;
  color: var(--bs-primary);
}

/* Blockquote
/* ======================================================================================== */
blockquote {
  background-color: var(--bs-primary);
  border: none;
  color: #ffffff;
}

blockquote p {
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  color: #ffffff;
}

blockquote footer {
  margin-top: 40px;
}

blockquote footer,
blockquote cite {
  font-size: 18px;
  font-style: italic;
  line-height: 20px;
  color: #ffffff;
}

/* Page dividers
  /* ======================================================================================== */
hr.page-divider {
  margin-top: 30px;
  margin-bottom: 30px;
  clear: both;
  border-color: #e9e9e9;
}

hr.page-divider.half {
  border-color: transparent;
  margin-top: 0;
}

hr.page-divider.small {
  border-color: transparent;
  margin-top: 0;
  margin-bottom: 20px;
}

hr.page-divider.single {
  border-color: #d3d3d3;
}

hr.transparent,
hr.page-divider.transparent {
  border-color: transparent;
}

::-webkit-input-placeholder {
  color: #8f8f8f !important;
}

:-moz-placeholder {
  color: #8f8f8f !important;
}

::-moz-placeholder {
  color: #8f8f8f !important;
}

:-ms-input-placeholder {
  color: #8f8f8f !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

input:focus:-moz-placeholder {
  color: transparent !important;
}

input:focus::-moz-placeholder {
  color: transparent !important;
}

input:focus:-ms-input-placeholder {
  color: transparent !important;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent !important;
}

textarea:focus:-moz-placeholder {
  color: transparent !important;
}

textarea:focus::-moz-placeholder {
  color: transparent !important;
}

textarea:focus:-ms-input-placeholder {
  color: transparent !important;
}

/* 2.1 */
/* Logo
/* ========================================================================== */
.logo {
  width: 220px;
  height: 100px;
  line-height: 100px;
  background-color: var(--bs-primary);
  position: absolute;
  z-index: 999;
  top: 0;
  left: 15px;
  -webkit-transition: none;
  transition: none;
}

.logo a {
  margin: auto auto;
  display: block;
  text-align: center;
  -webkit-transition: none;
  transition: none;
}

.logo img {
  max-width: 100%;
  height: auto;
  -webkit-transition: none;
  transition: none;
}

.is-sticky .logo {
  width: 140px;
  height: 50px;
  line-height: 50px;
  -webkit-transition: none;
  transition: none;
}

.is-sticky .logo img {
  max-width: none;
  height: 40px;
  -webkit-transition: none;
  transition: none;
}

.is-sticky .logo a {
  -webkit-transition: none;
  transition: none;
}

.full-screen-map .logo {
  width: 140px;
  height: 50px;
  line-height: 50px;
}

.full-screen-map .logo img {
  max-width: none;
  height: 40px;
}

@media (max-width: 991px) {
  .logo {
    width: 140px;
    height: 50px;
    line-height: 50px;
  }
  .logo img {
    max-width: none;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .logo {
    padding-top: 0;
    margin-bottom: 30px;
  }
}

/* SUPERFISH ESSENTIAL STYLES */
/* SUPERFISH THEME SKIN */
/* ARROW DOWN */
/* ARROW RIGHT */
.navigation {
  position: relative;
  text-align: right;
  line-height: 0;
}

.navigation .nav.sf-menu {
  font-family: 'Poppins', sans-serif;
  line-height: 1.72;
  text-align: left;
  margin: 0 auto !important;
}

.navigation ul {
  list-style: none;
}

.navigation ul.social-icons {
  min-width: 0;
  padding-top: 28px;
  padding-bottom: 28px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: block !important;
  position: relative !important;
  opacity: 1 !important;
  top: auto !important;
  left: auto !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.navigation ul.social-icons li {
  width: auto !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  background-color: transparent !important;
}

.navigation ul.social-icons li a {
  padding: 0;
  border: none;
  font-size: 16px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: #a5abb7;
}

.navigation ul.social-icons li a:hover {
  background-color: transparent;
  color: var(--bs-primary);
}

.is-sticky .navigation ul.social-icons {
  padding-top: 8px;
  padding-bottom: 4px;
}

.is-sticky .sf-menu.nav > li > a {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.is-sticky .sf-menu li.megamenu > ul li.row {
  padding-top: 15px !important;
}

.full-screen-map .navigation {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  z-index: 11000;
  border-left: solid 1px #14181c;
  background-color: #14181c;
}

.full-screen-map .navigation .nav.sf-menu {
  display: block;
}

.full-screen-map .menu-toggle {
  display: inline-block;
  line-height: 27px;
  border-color: #14181c;
  background-color: #14181c;
  color: #ffffff;
}

.full-screen-map .menu-toggle:focus {
  display: inline-block;
  line-height: 27px;
  border-color: #14181c;
  background-color: #14181c;
  color: #ffffff;
}

.full-screen-map .menu-toggle:active {
  display: inline-block;
  line-height: 27px;
  border-color: #14181c;
  background-color: #14181c;
  color: #ffffff;
}

.full-screen-map .menu-toggle:hover {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  color: #ffffff;
}

.full-screen-map .menu-toggle-close {
  display: block;
  width: 100%;
  text-align: right;
}

.full-screen-map .menu-toggle-close .fa {
  display: inline-block;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 28px;
  color: #ffffff !important;
  background-color: #14181c;
  border: solid 1px #ffffff;
  border-radius: 15px;
}

.full-screen-map .navigation.closed {
  right: -250px;
}

.full-screen-map .sf-menu {
  margin-right: 0;
  padding: 15px 0 15px 0;
}

.full-screen-map .sf-menu a {
  color: #ffffff;
}

.full-screen-map .sf-menu a:hover {
  color: #000000;
}

.full-screen-map .sf-menu > li > a {
  background-color: #14181c;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.full-screen-map .sf-menu > li > a:hover {
  background-color: #ffffff !important;
  color: var(--bs-primary);
}

.full-screen-map .sf-menu li.megamenu {
  color: #ffffff;
}

.full-screen-map .sf-menu li.megamenu ul {
  margin-left: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.full-screen-map .sf-menu li.megamenu ul a {
  color: #ffffff !important;
}

.full-screen-map .sf-menu li.megamenu .row {
  margin: 0 !important;
}

.full-screen-map .sf-menu li.megamenu > ul li.row {
  color: #d3d3d3 !important;
}

.full-screen-map .sf-menu li.megamenu .block-title {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.full-screen-map .sf-menu li.megamenu div[class*="col-"] {
  width: 100%;
}

.full-screen-map .sf-menu li.megamenu div[class*="col-"] + div[class*="col-"] {
  margin-top: 10px !important;
}

.full-screen-map .sf-menu ul {
  display: block !important;
  opacity: 1 !important;
  position: relative;
  position: static !important;
  margin-left: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.full-screen-map .sf-menu ul a {
  color: #ffffff;
}

.full-screen-map .sf-menu ul li {
  background-color: transparent;
}

.full-screen-map .sf-menu li {
  display: block !important;
  width: 100% !important;
  margin-bottom: 10px !important;
}

.full-screen-map .sf-menu li li:hover {
  background-color: transparent !important;
}

.full-screen-map .sf-menu li li.sfHover {
  background-color: transparent !important;
}

.full-screen-map .sf-menu li li.sfHover > a {
  background-color: transparent !important;
}

.full-screen-map .sf-menu li li.active {
  background-color: transparent !important;
}

.full-screen-map .sf-menu li.active > a {
  color: #ffffff !important;
}

.full-screen-map .sf-menu li.active > a:hover {
  color: var(--bs-primary) !important;
}

.full-screen-map .sf-menu li.sale > a {
  background-color: var(--bs-primary) !important;
  columns: #ffffff !important;
}

.full-screen-map .sf-menu li.sale > a:hover {
  background-color: var(--bs-primary) !important;
  columns: #ffffff !important;
}

.full-screen-map .sf-arrows .sf-with-ul:after {
  border-color: #ffffff transparent transparent !important;
}

.full-screen-map .product-list .media-link {
  text-align: left !important;
  margin-bottom: 10px;
}

.full-screen-map .product-list .price del {
  color: #d3d3d3 !important;
}

.full-screen-map .rating span.star.active:before {
  color: #d3d3d3 !important;
}

.full-screen-map .rating span.star:hover:before {
  color: #d3d3d3 !important;
}

.full-screen-map .rating span.star:hover ~ span.star:before {
  color: #d3d3d3 !important;
}

.sf-menu {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: end;
}

.sf-menu li {
  position: relative;
}

.sf-menu li:hover {
  color: #14181c;
}

.sf-menu li:hover > ul {
  display: block;
}

.sf-menu li ul ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sf-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
  min-width: 12em;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  margin-left: 0;
  padding-left: 0;
}

.sf-menu ul ul {
  top: 0;
  left: 100%;
  margin-left: 0;
}

.sf-menu ul li {
  background: #ffffff;
}

.sf-menu ul li > a {
  padding: 28px 15px 28px 15px;
  padding: 8px 15px 8px 15px;
}

.sf-menu ul a {
  text-transform: none;
  font-weight: 400;
  text-decoration: none;
  color: #14181c;
}

.sf-menu ul a:hover {
  color: #14181c;
  background-color: #e9e9e9;
}

.sf-menu li.sfHover {
  color: #14181c;
}

.sf-menu li.sfHover > ul {
  display: block;
}

.sf-menu li.sfHover > a {
  color: #14181c;
}

.sf-menu a {
  display: block;
  position: relative;
  color: #a5abb7;
}

.sf-menu a:hover {
  color: #14181c;
}

.sf-menu .megamenu ul li > a {
  padding: 3px 15px 3px 15px;
}

.sf-menu li.active {
  color: #14181c;
}

.sf-menu li.active > a {
  color: #14181c;
}

.sf-menu li.megamenu {
  position: inherit !important;
}

.sf-menu li.megamenu > ul {
  font-weight: normal;
  font-size: 13px;
  text-transform: none;
  width: 100%;
  margin: 0;
  padding: 0 15px;
}

.sf-menu li.megamenu > ul > li.row {
  padding-top: 50px !important;
}

.sf-menu li.megamenu > ul li.row {
  color: #7f7f7f !important;
  padding: 15px 0;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 0;
}

.sf-menu li.megamenu > ul li.row div[class*="col-"] {
  margin-top: 0;
}

.sf-menu li.megamenu > ul li ul {
  display: block;
  position: relative;
  left: 0;
  margin-left: 0;
}

.sf-menu li.megamenu > ul li a {
  background-color: transparent;
}

.sf-menu li.megamenu ul {
  margin-left: 0;
}

.sf-menu li.megamenu ul a {
  padding-left: 0;
}

.sf-menu li.megamenu ul a:hover {
  color: var(--bs-primary);
}

.sf-menu li.megamenu p {
  margin-bottom: 10px;
}

.sf-menu li.megamenu .block-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 8px;
  color: #14181c;
}

.sf-menu li.sale > a {
  background-color: var(--bs-primary);
  color: #ffffff;
}

.sf-menu li.sale > a:hover {
  background-color: var(--bs-primary);
  color: #ffffff;
}

.sf-menu.nav > li > a {
  padding: 32px 15px 28px 15px;
  text-transform: uppercase;
}

.sf-menu.nav > li > a:hover {
  background-color: transparent;
}

.sf-menu.nav > li > a:hover:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  background-color: var(--bs-primary);
}

.sf-menu.nav > li > a:focus {
  background-color: transparent;
}

.sf-menu.nav > li > a:focus:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  background-color: var(--bs-primary);
}

.sf-menu.nav > li > a.sf-with-ul {
  padding-right: 1.8em;
}

.sf-arrows .sf-with-ul {
  padding-right: 1.8em;
}

.sf-arrows .sf-with-ul:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -1px;
  height: 0;
  width: 0;
  border: 3px solid transparent;
  border-top-color: #a5abb7;
}

.sf-arrows > li > .sf-with-ul:focus:after {
  border-top-color: var(--bs-primary);
}

.sf-arrows > li:hover > .sf-with-ul:after {
  border-top-color: var(--bs-primary);
}

.sf-arrows > .sfHover > .sf-with-ul:after {
  border-top-color: var(--bs-primary);
}

.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #a5abb7;
}

.sf-arrows ul li > .sf-with-ul:focus:after {
  border-left-color: var(--bs-primary);
}

.sf-arrows ul li:hover > .sf-with-ul:after {
  border-left-color: var(--bs-primary);
}

.sf-arrows ul .sfHover > .sf-with-ul:after {
  border-left-color: var(--bs-primary);
}

.menu-toggle {
  display: none;
  height: 40px;
  margin: 5px 0;
}

.menu-toggle:hover .fa {
  color: #ffffff;
}

.menu-toggle-close {
  display: none;
}

.sign-in-menu {
  text-align: end;
  margin: 0;
  position: relative;
  top: -20px;
}

.sign-in-menu li {
  display: inline-block;
  font-size: 14px;
  font-weight: 900;
  margin-right: 15px;
  text-transform: uppercase;
  color: #6d737f;
}

.sign-in-menu li a {
  color: #6d737f;
  display: inline-block;
  line-height: 40px;
}

.sign-in-menu li a:hover {
  color: #13181c;
  border-top: solid 5px var(--bs-primary);
}

.sign-in-menu li.active a {
  color: #13181c;
  border-top: solid 5px var(--bs-primary);
}

@media (max-width: 991px) {
  .navigation {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    z-index: 11000;
    border-left: solid 1px #14181c;
    background-color: #14181c;
  }
  .navigation .nav.sf-menu {
    display: block;
  }
  .menu-toggle {
    display: inline-block;
  }
  .menu-toggle-close {
    display: block;
    width: 100%;
    text-align: right;
  }
  .menu-toggle-close .fa {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 28px;
    color: #ffffff !important;
    background-color: #14181c;
    border: solid 1px #ffffff;
    border-radius: 15px;
  }
  .navigation.closed {
    right: -250px;
  }
  .navigation.opened {
    right: 0;
  }
  .sf-menu {
    margin-right: 0;
    padding: 15px 0 15px 0;
  }
  .sf-menu a {
    color: #ffffff;
  }
  .sf-menu a:hover {
    color: #000000;
  }
  .sf-menu > li > a {
    background-color: #14181c;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sf-menu > li > a:hover {
    background-color: #ffffff !important;
    color: var(--bs-primary);
  }
  .sf-menu li.megamenu {
    color: #ffffff;
  }
  .sf-menu li.megamenu ul {
    margin-left: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .sf-menu li.megamenu ul a {
    color: #ffffff !important;
  }
  .sf-menu li.megamenu .row {
    margin: 0 !important;
  }
  .sf-menu li.megamenu > ul li.row {
    color: #d3d3d3 !important;
  }
  .sf-menu li.megamenu .block-title {
    color: #ffffff !important;
    border-color: #ffffff !important;
  }
  .sf-menu li.megamenu div[class*="col-"] + div[class*="col-"] {
    margin-top: 10px !important;
  }
  .sf-menu ul {
    display: block !important;
    opacity: 1 !important;
    position: relative;
    position: static !important;
    margin-left: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sf-menu ul a {
    color: #ffffff;
  }
  .sf-menu ul li {
    background-color: transparent;
  }
  .sf-menu li {
    display: block !important;
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .sf-menu li li:hover {
    background-color: transparent !important;
  }
  .sf-menu li li.sfHover {
    background-color: transparent !important;
  }
  .sf-menu li li.sfHover > a {
    background-color: transparent !important;
  }
  .sf-menu li li.active {
    background-color: transparent !important;
  }
  .sf-menu li.active > a {
    color: #ffffff !important;
  }
  .sf-menu li.active > a:hover {
    color: var(--bs-primary) !important;
  }
  .sf-menu li.sale > a {
    background-color: var(--bs-primary) !important;
    columns: #ffffff !important;
  }
  .sf-menu li.sale > a:hover {
    background-color: var(--bs-primary) !important;
    columns: #ffffff !important;
  }
  .sf-arrows .sf-with-ul:after {
    border-color: #ffffff transparent transparent !important;
  }
  .product-list .media-link {
    text-align: left !important;
    margin-bottom: 10px;
  }
  .product-list .price del {
    color: #d3d3d3 !important;
  }
  .rating span.star.active:before {
    color: #d3d3d3 !important;
  }
  .rating span.star:hover:before {
    color: #d3d3d3 !important;
  }
  .rating span.star:hover ~ span.star:before {
    color: #d3d3d3 !important;
  }
  .sign-in-menu li a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sign-in-menu li a .text {
    display: none;
  }
}

@media (min-width: 992px) {
  .full-screen-map .navigation.opened {
    right: auto;
    left: 630px;
  }
  .sign-in-menu li a .fa {
    display: none;
  }
}

/* 3.0 */
/* Footer
/* ========================================================================== */
.footer {
  color: #14181c;
  background-color: #f9fafb;
}

/* 3.1 */
/* Footer widgets
  /* ========================================================================== */
.footer-widgets {
  padding: 0 0 50px 0;
  margin-top: 0;
  background-color: transparent;
}

.footer-widgets .widget {
  margin-top: 50px;
}

.footer-widgets .widget-title {
  margin-top: 0;
  font-weight: 900;
  text-transform: uppercase;
  color: #14181c;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}

.footer-widgets .widget-title:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #a3a4a5;
}

/* 3.2 */
/* Footer meta
  /* ========================================================================== */
.footer-meta {
  padding: 30px 0;
  background-color: #ffffff;
}

.footer-meta .copyright {
  text-align: center;
  color: #a1b1bc;
}

/* 4.1 */
/* Main Slider / OwlCarousel
/* ========================================================================== */
.main-slider .owl-theme .owl-controls {
  margin: 0 !important;
}

@media (max-width: 640px) {
  .main-slider .owl-theme .owl-controls {
    display: none;
  }
}

.main-slider {
  position: relative;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  color: #fff;
}

.main-slider .owl-nav button.owl-next, .main-slider .owl-nav button.owl-prev {
  position: absolute;
  top: 50%;
  margin: -20px 0 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  font-size: 30px;
  line-height: 30px;
  border: solid 3px #ffffff;
  background: transparent;
  color: #ffffff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.main-slider .owl-nav button .fa {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (min-width: 992px) and (max-width: 1100px) {
  .main-slider .owl-nav button.owl-next, .main-slider .owl-nav button.owl-prev {
    opacity: .3;
  }
}

@media (min-width: 1200px) and (max-width: 1290px) {
  .main-slider .owl-nav button.owl-next, .main-slider .owl-nav button.owl-prev {
    opacity: .3;
  }
}

.main-slider .owl-nav button:hover {
  background: var(--bs-primary);
  border-color: var(--bs-primary);
  color: #ffffff;
  opacity: 1;
}

.owl-carousel .owl-nav button.owl-prev {
  left: 30px;
}

.main-slider .owl-nav button.owl-prev .fa {
  margin-top: 3px;
  margin-right: 3px;
}

.owl-carousel .owl-nav button.owl-next {
  right: 30px;
}

.main-slider .owl-nav button.owl-next .fa {
  margin-bottom: 3px;
  margin-left: 3px;
}

@media (max-width: 991px) {
  .main-slider .owl-nav {
    display: none;
  }
}

.owl-carousel .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.owl-carousel .owl-dots .owl-dot span {
  background-color: #a5abb7;
}

.owl-carousel .owl-dots .owl-dot:hover span,
.owl-carousel .owl-dots .owl-dot.active span {
  background-color: #14181c;
}

.main-slider .div-table {
  width: 100%;
}

.main-slider .caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-slider .caption > .container {
  position: relative;
  min-height: 100%;
  height: 100%;
}

.main-slider .caption-content {
  text-align: center;
}

.main-slider .captiofont-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 100;
  line-height: 1;
  color: var(--bs-primary);
  clear: both;
  display: inline-block;
  text-transform: uppercase;
  margin: 0 0 15px 0;
}

.main-slider .caption-subtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 72px;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 40px 0;
}

@media (max-width: 1280px) {
  .main-slider .caption-subtitle {
    font-size: 60px;
  }
}

@media (max-width: 1199px) {
  .main-slider .caption-subtitle {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .main-slider .caption-subtitle {
    font-size: 35px;
  }
}

@media (max-width: 767px) {
  .main-slider .caption-subtitle {
    font-size: 20px;
  }
}

@media (max-width: 639px) {
  .main-slider .caption-subtitle {
    font-size: 18px;
  }
}

.main-slider .caption-text {
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 0;
}

.main-slider .caption-text + .caption-text {
  margin-top: 20px;
}

.main-slider .btn-theme {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff;
}

.main-slider .btn-theme:hover {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  color: #ffffff;
}

.main-slider .ver1:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.main-slider .ver1 > .caption {
  z-index: 2;
}

.main-slider .ver1 .div-cell {
  vertical-align: top !important;
}

.main-slider .ver1 .caption-content {
  margin-top: 70px;
}

@media (max-width: 480px) {
  .main-slider .ver1 .caption-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .main-slider .ver1 .caption-subtitle {
    display: none;
  }
}

@media (max-width: 480px) {
  .main-slider .ver1 .caption-text {
    display: none;
  }
}

@media (max-width: 640px) {
  .main-slider .ver1 .form-search .btn-submit {
    text-indent: -9999px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-slider .ver1 .form-search .btn-submit:after {
    font-family: 'FontAwesome';
    content: "\f002";
    display: block;
    text-indent: 0;
    margin-top: -13px;
    font-size: 26px;
  }
}

.main-slider .ver2:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.main-slider .ver2 > .caption {
  z-index: 2;
}

.main-slider .ver2 .caption-content {
  text-align: left;
}

@media (max-width: 480px) {
  .main-slider .ver2 .caption-title {
    display: none;
  }
}

.main-slider .ver2 .caption-subtitle {
  padding-top: 60px;
  margin-bottom: 30px;
  text-transform: none;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.35);
}

@media (max-width: 480px) {
  .main-slider .ver2 .caption-subtitle {
    display: none;
  }
}

@media (max-width: 480px) {
  .main-slider .ver2 .caption-text {
    display: none;
  }
}

.main-slider .ver2 .caption-text + .caption-text {
  margin-top: 40px;
}

.main-slider .ver2 .form-search {
  margin-left: 30px;
  max-width: 510px;
}

@media (max-width: 480px) {
  .main-slider .ver2 .form-search {
    margin-left: 0;
    max-width: 100%;
  }
}

@media (max-width: 640px) {
  .main-slider .ver2 .form-search .btn-submit {
    text-indent: -9999px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-slider .ver2 .form-search .btn-submit:after {
    font-family: 'FontAwesome';
    content: "\f002";
    display: block;
    text-indent: 0;
    margin-top: -13px;
    font-size: 26px;
  }
}

.main-slider .ver3 .caption-content {
  text-align: right;
}

.main-slider .ver3 .caption-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  color: #14181c;
}

@media (max-width: 480px) {
  .main-slider .ver3 .caption-title {
    display: none;
  }
}

.main-slider .ver3 .caption-subtitle {
  color: var(--bs-primary);
}

@media (max-width: 480px) {
  .main-slider .ver3 .caption-subtitle {
    display: none;
  }
}

.main-slider .ver3 .caption-text {
  color: #14181c;
}

@media (max-width: 480px) {
  .main-slider .ver3 .caption-text {
    display: none;
  }
}

.main-slider .ver3 .btn-theme {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
}

.main-slider .ver3 .btn-theme:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: #ffffff;
}

.main-slider .ver3 .form-search {
  margin-left: 50px;
  max-width: 510px;
}

@media (max-width: 991px) {
  .main-slider .ver3 .form-search {
    margin-left: 30px;
  }
}

@media (max-width: 480px) {
  .main-slider .ver3 .form-search {
    margin-left: 0;
    max-width: 100%;
  }
}

@media (max-width: 640px) {
  .main-slider .ver3 .form-search .btn-submit {
    text-indent: -9999px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-slider .ver3 .form-search .btn-submit:after {
    font-family: 'FontAwesome';
    content: "\f002";
    display: block;
    text-indent: 0;
    margin-top: -13px;
    font-size: 26px;
  }
}

.main-slider .ver4 .caption:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 0%, #d3ecef 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, #d3ecef));
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 0%, #d3ecef 100%);
  background: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 0%, #d3ecef 100%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 0%, #d3ecef 100%);
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, #d3ecef 100%);
}

.main-slider .ver4 .caption-content {
  text-align: center;
  min-height: 500px;
  margin-left: -400px;
}

@media (max-width: 767px) {
  .main-slider .ver4 .caption-content {
    margin-left: auto;
  }
}

.main-slider .ver4 .caption-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  color: #14181c;
}

.main-slider .ver4 .caption-subtitle {
  color: var(--bs-primary);
}

.main-slider .ver4 .caption-text {
  color: #14181c;
}

.main-slider .ver4 .btn-theme {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
}

.main-slider .ver4 .btn-theme:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: #ffffff;
}

@media (max-width: 640px) {
  .main-slider .ver4 .form-search .btn-submit {
    text-indent: -9999px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-slider .ver4 .form-search .btn-submit:after {
    font-family: 'FontAwesome';
    content: "\f002";
    display: block;
    text-indent: 0;
    margin-top: -13px;
    font-size: 26px;
  }
}

.main-slider .form-search {
  text-align: left;
  background: rgba(20, 24, 28, 0.6);
}

.main-slider .form-search .form-group {
  margin-bottom: 10px;
}

.main-slider .form-search .form-group label {
  margin-bottom: 3px;
  font-weight: 700;
}

.main-slider .form-search .form-control {
  height: 40px;
}

.main-slider .form-search .has-icon .form-control {
  padding-right: 40px;
}

.main-slider .form-search .form-control-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
}

.main-slider .form-search .btn-submit {
  padding: 15px 50px;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: #ffffff;
}

.main-slider .form-search .btn-submit:hover {
  background-color: #14181c;
  border-color: #14181c;
}

.main-slider .form-search .form-title {
  background: #14181c;
  color: #ffffff;
  padding: 16px 20px;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.main-slider .form-search .form-title h2, .main-slider .form-search .form-title .h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  color: #ffffff;
}

.main-slider .form-search .form-title .fa {
  line-height: 18px;
  font-size: 24px;
}

.main-slider .form-search .form-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 50px;
  bottom: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #14181c;
}

.main-slider .form-search .row,
.main-slider .form-search .row div[class*="col-"] {
  margin-top: 0;
}

.main-slider .form-search .row-inputs .container-fluid, .main-slider .form-search .row-inputs .container-sm, .main-slider .form-search .row-inputs .container-md, .main-slider .form-search .row-inputs .container-lg, .main-slider .form-search .row-inputs .container-xl, .main-slider .form-search .row-inputs .container-xxl {
  padding-left: 25px;
  padding-right: 25px;
}

.main-slider .form-search .row-inputs .container-fluid div[class*="col-"], .main-slider .form-search .row-inputs .container-sm div[class*="col-"], .main-slider .form-search .row-inputs .container-md div[class*="col-"], .main-slider .form-search .row-inputs .container-lg div[class*="col-"], .main-slider .form-search .row-inputs .container-xl div[class*="col-"], .main-slider .form-search .row-inputs .container-xxl div[class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.main-slider .form-search .row-inputs + .row-inputs {
  padding-bottom: 10px;
}

.main-slider .form-search .row-submit .container-fluid > .inner, .main-slider .form-search .row-submit .container-sm > .inner, .main-slider .form-search .row-submit .container-md > .inner, .main-slider .form-search .row-submit .container-lg > .inner, .main-slider .form-search .row-submit .container-xl > .inner, .main-slider .form-search .row-submit .container-xxl > .inner {
  padding: 20px;
  border-top: solid 1px rgba(255, 255, 255, 0.35);
  line-height: 50px;
}

.main-slider .form-search .row-submit a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: underline;
}

.main-slider .form-search .row-submit a:hover {
  color: #ffffff;
}

.main-slider .form-search.light {
  background: #ffffff;
}

.main-slider .form-search.light .form-title h2, .main-slider .form-search.light .form-title .h2 {
  max-width: 260px;
}

.main-slider .form-search.light .form-title .fa {
  font-size: 36px;
  line-height: 36px;
}

.main-slider .form-search.light .row-submit .container-fluid > .inner, .main-slider .form-search.light .row-submit .container-sm > .inner, .main-slider .form-search.light .row-submit .container-md > .inner, .main-slider .form-search.light .row-submit .container-lg > .inner, .main-slider .form-search.light .row-submit .container-xl > .inner, .main-slider .form-search.light .row-submit .container-xxl > .inner {
  border-top: solid 1px #d3d3d3;
  background-color: #e9e9e9;
}

.main-slider .form-search.light .row-submit a {
  color: #7f7f7f;
}

.main-slider .form-search.light .row-submit a:hover {
  color: var(--bs-primary);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider .sub .caption > .container {
    width: 698px;
  }
}

@media (min-width: 1200px) {
  .main-slider .sub .caption > .container {
    width: 848px;
  }
}

.main-slider .sub .caption-content {
  max-width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  text-align: right;
}

@media (max-width: 639px) {
  .main-slider .sub .caption-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 479px) {
  .main-slider .sub .caption-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-slider .sub .caption-title {
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-size: 44px;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 10px;
  color: #14181c;
}

@media (max-width: 639px) {
  .main-slider .sub .caption-title {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 479px) {
  .main-slider .sub .caption-title {
    font-size: 14px;
    line-height: 18px;
  }
}

.main-slider .sub .caption-subtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 44px;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 10px;
  overflow: hidden;
  color: #14181c;
}

@media (max-width: 639px) {
  .main-slider .sub .caption-subtitle {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 479px) {
  .main-slider .sub .caption-subtitle {
    font-size: 14px;
    line-height: 18px;
  }
}

.main-slider .sub .caption-title span,
.main-slider .sub .caption-subtitle span {
  padding: 0 8px;
  background-color: rgba(255, 255, 255, 0.3);
}

.main-slider .sub .caption-text .btn-theme {
  padding: 9px 32px;
}

@media (max-width: 639px) {
  .main-slider .sub .caption-text .btn-theme {
    padding: 7px 25px;
    font-size: 12px;
  }
}

@media (max-width: 479px) {
  .main-slider .sub .caption-text .btn-theme {
    padding: 7px 20px;
    font-size: 11px;
  }
}

.main-slider.sub .owl-theme .owl-controls .owl-nav .owl-prev {
  left: 10px;
}

.main-slider.sub .owl-theme .owl-controls .owl-nav .owl-next {
  right: 10px;
}

.main-slider .item {
  background-position: center center;
  background-size: cover;
  height: 700px;
}

@media (max-width: 767px) {
  .main-slider .item {
    min-height: 850px;
  }
}

.main-slider .slide1 {
  background-image: url("../img/preview/slider/slide-1.jpg");
}

.main-slider .slide2 {
  background-image: url("../img/preview/slider/slide-1.jpg");
}

.main-slider .slide3 {
  background-image: url("../img/preview/slider/slide-2.jpg");
}

.main-slider .slide4 {
  background-image: url("../img/preview/slider/slide-3.jpg");
}

.main-slider-row .slider .main-slider .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin: -18px 0 0 0;
  padding: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 30px;
  line-height: 28px;
  border: solid 1px #d3d3d3;
  background: transparent;
  color: #d3d3d3;
}

.main-slider-row .slider .main-slider .owl-nav [class*=owl-]:hover {
  border-color: #a5abb7;
  background: rgba(211, 211, 211, 0.5);
  color: #7f7f7f;
  opacity: 1;
}

.main-slider-row .slider .main-slider .owl-nav .owl-prev {
  left: 10px;
}

.main-slider-row .slider .main-slider .owl-nav .owl-prev .fa {
  margin-left: -3px;
}

.main-slider-row .slider .main-slider .owl-nav .owl-next {
  right: 10px;
}

.main-slider-row .slider .main-slider .owl-nav .owl-next .fa {
  margin-right: -3px;
}

@media (max-width: 991px) {
  .main-slider-row .slider .main-slider .owl-nav {
    display: none;
  }
}

@media (min-width: 1200px) {
  .main-slider-row .slider .caption > .container {
    width: 848px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider-row .slider .caption > .container {
    width: 698px;
  }
}

@media (min-width: 1200px) {
  .main-slider-row .slider .caption-content {
    padding: 0 40px;
    max-width: 585px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider-row .slider .caption-content {
    padding: 0 40px;
    max-width: 585px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-slider-row .slider .caption-content {
    padding: 0 30px;
  }
}

.main-slider-row .slider .slide1 .caption-content {
  text-align: left;
}

@media (min-width: 992px) {
  .main-slider-row .slider .slide1 .caption-subtitle {
    font-size: 140px;
  }
}

@media (min-width: 992px) {
  .main-slider-row .slider .slide2 .caption-subtitle {
    font-size: 44px;
    line-height: 56px;
  }
}

@media (min-width: 992px) {
  .main-slider-row .slider .slide3 .caption-subtitle {
    font-size: 44px;
    line-height: 56px;
  }
}

@media (min-width: 992px) {
  .main-slider-row .owl-stage-outer {
    max-height: 414px;
  }
  .main-slider-row .owl-stage {
    max-height: 414px;
  }
  .main-slider-row .owl-item {
    max-height: 414px;
  }
  .main-slider-row .item {
    max-height: 414px;
  }
  .main-slider-row .slide-img {
    max-height: 414px;
  }
}

.owl-carousel .owl-item {
  -webkit-transform: translateZ(0) scale(1, 1);
}

.coming-soon .header-wrapper > .container {
  min-height: 0 !important;
}

.coming-soon .main-slider .page {
  background: transparent url("../img/preview/slider/slide-3.jpg") no-repeat center center;
  background-size: cover;
}

.coming-soon .main-slider .caption-content {
  display: block !important;
  text-align: center !important;
}

.coming-soon .main-slider .page .countdown-wrapper {
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.coming-soon .main-slider .page .defaultCountdown {
  background-color: transparent;
  border-color: transparent;
  border: none;
  width: 100%;
}

.coming-soon .main-slider .page .countdown-row {
  overflow: hidden;
  display: table;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.coming-soon .main-slider .page .countdown-section {
  background-color: rgba(0, 0, 0, 0.5);
  display: table-cell;
}

.coming-soon .main-slider .page .countdown-show4 .countdown-section {
  padding-top: 8px;
  padding-bottom: 9px;
  width: 25% !important;
}

.coming-soon .main-slider .page .countdown-amount {
  margin: 0 0 0 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: var(--bs-primary);
}

.coming-soon .main-slider .page .countdown-period {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #d3d3d3;
  margin: 0 0 0 0;
  display: block;
  position: relative;
  overflow: hidden;
}

::after, ::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fa-angle-left::before {
  content: "\f104";
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 14px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.swiper .swiper-button-prev {
  left: -80px;
}

.swiper .swiper-button-next {
  right: -80px;
}

.swiper.swiper-offer {
  padding: 0 68px;
}

@media (max-width: 991.98px) {
  .swiper.swiper-offer {
    padding: 0;
  }
}

.swiper.swiper-offer .swiper-button-prev {
  left: .7% !important;
}

@media (max-width: 991.98px) {
  .swiper.swiper-offer .swiper-button-prev {
    left: 2.7% !important;
  }
}

.swiper.swiper-offer .swiper-button-prev::after {
  content: "";
}

.swiper.swiper-offer .swiper-button-next {
  right: .7% !important;
}

@media (max-width: 991.98px) {
  .swiper.swiper-offer .swiper-button-next {
    right: 2.7% !important;
  }
}

.swiper.swiper-offer .swiper-button-next::after {
  content: "";
}

.swiper.swiperthumbs2 {
  margin-bottom: -80px;
}

.swiper.swiperthumbs {
  left: 10px;
  top: -20px;
}

.swiper.swiperthumbs .swiper-slide {
  width: 100%;
  max-width: 80px !important;
}

.swiper.swiperthumbs .swiper-slide img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.swiper.swiperthumbs .swiper-slide.swiper-slide-visible {
  border: 4px solid white;
  transition: .3s ease-in-out;
}

.swiper.swiperthumbs .swiper-slide.swiper-slide-visible:hover {
  border: 4px solid red;
}

.swiper.swiperthumbs .swiper-slide.swiper-slide-active {
  border: 4px solid red;
}

.swiper.swiperthumbs .swiper-slide::after {
  content: '\f002';
  font-family: 'FontAwesome';
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: -20px 0 0 -20px;
  text-align: center;
  left: 50%;
  top: 50%;
  color: #ffffff;
  opacity: 0;
  transition: .3s ease-in-out;
}

.swiper.swiperthumbs .swiper-slide:hover .swiper-slide-visible {
  border: 4px solid red;
}

.swiper.swiperthumbs .swiper-slide:hover::after {
  opacity: 1;
}

.swiper-slide img.img-responsive {
  width: 100%;
}

.swiper-pagination {
  position: relative;
  margin-top: 10px;
  bottom: auto;
  left: auto;
}

.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  border: solid 4px var(--bs-primary);
  background-color: transparent;
  opacity: 1;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--bs-primary);
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-top: -20px;
  text-align: center;
  background: none;
  border: solid 4px #14181c;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.swiper-button-prev .fa,
.swiper-button-next .fa {
  font-size: 25px;
  font-weight: 900;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 991px) {
  .swiper--offers-best .swiper-button-prev,
  .swiper--offers-popular .swiper-button-prev,
  .swiper--offers-economic .swiper-button-prev,
  .swiper--offers-best .swiper-button-next,
  .swiper--offers-popular .swiper-button-next,
  .swiper--offers-economic .swiper-button-next {
    display: none;
  }
}

/* 4.2 */
/* Partners carousel / OwlCarousel
/* ========================================================================== */
.partners-carousel .owl-carousel div a {
  display: block;
  text-align: center;
  border: solid 3px #f3f4f6;
  background-color: #f3f4f6;
  padding: 20px;
  margin: 1px;
  -webkit-transition: all 0.65s ease-in-out;
  transition: all 0.65s ease-in-out;
}

.partners-carousel .owl-carousel div a:hover {
  border: solid 3px #14181c;
}

.partners-carousel .owl-carousel div a img {
  display: inline-block;
  max-width: 100%;
}

.partners-carousel .owl-carousel .owl-item img {
  width: auto;
}

.partners-carousel .owl-nav [class*="owl-"] {
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.partners-carousel .owl-nav .owl-prev {
  left: -50px !important;
  margin-top: -16px !important;
}

.partners-carousel .owl-nav .owl-next {
  right: -50px !important;
  margin-top: -16px !important;
}

.partners-carousel .owl-dots {
  line-height: 1;
}

.partners-carousel .owl-prev,
.partners-carousel .owl-next {
  position: absolute;
  top: 50%;
  border: 3px solid #e9e9e9 !important;
  color: #e9e9e9;
  height: 37px;
  width: 37px;
  line-height: 37px;
  text-align: center;
}

.partners-carousel .owl-prev .fa,
.partners-carousel .owl-next .fa {
  color: #e9e9e9;
  font-size: 24px !important;
  line-height: 30px;
}

.partners-carousel .owl-prev:hover,
.partners-carousel .owl-next:hover {
  border-color: #000000 !important;
  color: #000000;
}

.partners-carousel .owl-prev:hover .fa,
.partners-carousel .owl-next:hover .fa {
  color: #000000 !important;
}

/* 4.4 */
/* Products Carousel / OwlCarousel
/* ========================================================================== */
.featured-products-carousel .owl-theme .owl-controls {
  margin: 0 !important;
}

.featured-products-carousel .owl-theme .owl-controls .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin: -20px 0 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  font-size: 30px;
  line-height: 30px;
  border: solid 3px #a5abb7;
  background: transparent;
  color: #a5abb7;
}

.featured-products-carousel .owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
  background: #14181c;
  border-color: #14181c;
  color: #ffffff;
}

.featured-products-carousel .owl-theme .owl-controls .owl-nav .owl-prev {
  left: -70px;
}

.featured-products-carousel .owl-theme .owl-controls .owl-nav .owl-next {
  right: -70px;
}

.featured-products-carousel .owl-theme .owl-controls .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.featured-products-carousel .owl-theme .owl-controls .owl-dots .owl-dot span {
  background-color: #a5abb7;
}

.featured-products-carousel .owl-theme .owl-controls .owl-dots .owl-dot:hover span,
.featured-products-carousel .owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background-color: #14181c;
}

.sidebar-products-carousel .owl-theme .owl-controls .owl-dots .owl-dot span {
  background-color: #a5abb7;
  border-radius: 0;
  margin-left: 1px;
  margin-right: 2px;
}

.sidebar-products-carousel .owl-theme .owl-controls .owl-dots .owl-dot:hover span,
.sidebar-products-carousel .owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background-color: #14181c;
}

@media (max-width: 639px) {
  .featured-products-carousel .owl-theme .owl-controls {
    display: none;
  }
}

@media (max-width: 1300px) {
  .featured-products-carousel .owl-theme .owl-controls .owl-nav .owl-prev {
    left: 15px;
  }
  .featured-products-carousel .owl-theme .owl-controls .owl-nav .owl-next {
    right: 15px;
  }
}

/* 4.3 */
/* Testimonials carousel / OwlCarousel
/* ========================================================================== */
.page-section.testimonials {
  background: #e9e9e9 url(../img/transparent-bg.png) center top no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-bottom: 20px;
}

.page-section.testimonials.alt {
  background-image: none;
  background-color: #e9e9e9;
}

.testimonials-carousel .owl-stage {
  margin-bottom: 86px;
}

.testimonials-carousel .testimonial .media {
  max-width: 970px;
  margin: 0 auto;
}

.testimonials-carousel .owl-dots .owl-dot:hover span,
.testimonials-carousel .owl-dots .owl-dot.active span {
  background-color: transparent;
}

.testimonials-carousel .testimonial {
  margin-top: 10px;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
}

.testimonials-carousel .testimonial .testimonial-text {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  max-width: 900px;
  margin: 0 auto 0 auto;
  padding: 60px 0 30px 0;
  position: relative;
}

.testimonials-carousel .testimonial .testimonial-text:before {
  content: '\f10d';
  left: 0;
}

.testimonials-carousel .testimonial .testimonial-text:after {
  content: '\f10e';
  right: 0;
  top: auto;
  bottom: 0;
}

.testimonials-carousel .testimonial .testimonial-name {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 2px;
}

.testimonials-carousel .testimonial .testimonial-position {
  font-size: 14px;
  font-weight: 300;
}

.testimonials-carousel .testimonial .testimonial-avatar {
  margin-top: 70px;
  border-radius: 50%;
  width: auto;
}

.testimonials-carousel .testimonial .media-left {
  padding-right: 30px;
}

.testimonials-carousel .testimonial .media-right {
  padding-left: 30px;
}

.testimonials-carousel .owl-dots .owl-dot span {
  width: 14px;
  height: 14px;
  margin: 5px;
  background-color: transparent;
  border: solid 3px #a5abb7;
}

.testimonials-carousel .owl-dots .owl-dot.active span {
  background-color: #a5abb7;
}

.testimonials-carousel .testimonial .testimonial-text:before,
.testimonials-carousel .testimonial .testimonial-text:after {
  content: '';
  position: absolute;
  top: 0;
  margin-top: 0;
  font-family: 'FontAwesome';
  font-size: 24px;
  line-height: 30px;
  color: #7f7f7f;
}

.testimonials-carousel .testimonial .testimonial-text::before {
  content: '\f10d';
  left: 0;
}

.testimonials-carousel .testimonial .testimonial-text::after {
  content: '\f10e';
  right: 0;
  top: auto;
  bottom: 0;
}

.image .testimonials-carousel .testimonial .testimonial-text {
  color: #ffffff;
}

.image .testimonials-carousel .testimonial .testimonial-name {
  color: #ffffff;
}

.sidebar .testimonials-carousel .testimonial {
  padding-left: 20px;
  padding-right: 20px;
}

.sidebar .testimonials-carousel .testimonial .testimonial-text {
  font-size: 14px;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
}

.sidebar .testimonials-carousel .testimonial .testimonial-text:before {
  content: '';
}

.sidebar .testimonials-carousel .testimonial .testimonial-text:after {
  content: '';
}

.sidebar .testimonials-carousel .testimonial .testimonial-name {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.sidebar .testimonials-carousel .testimonial .testimonial-name .testimonial-position {
  display: block;
}

.sidebar .testimonials-carousel .owl-stage {
  margin-bottom: 70px;
}

.sidebar .testimonials-carousel .owl-dots .owl-dot span {
  border-color: #3c3c3c;
}

.sidebar .testimonials-carousel .owl-dots .owl-dot.active span {
  background-color: #3c3c3c;
}

.alt .testimonials-carousel {
  text-align: center;
}

.alt .testimonials-carousel .testimonial {
  padding-left: 15px;
  padding-right: 15px;
}

.alt .testimonials-carousel .testimonial-text {
  font-size: 16px;
  line-height: 24px;
  padding: 25px 40px 50px 40px;
  background-color: #ffffff;
  border-radius: 8px;
}

.alt .testimonials-carousel .testimonial-text:before {
  top: 20px;
  left: 20px;
}

.alt .testimonials-carousel .testimonial-text:after {
  bottom: 20px;
  right: 20px;
}

.alt .testimonials-carousel .testimonial-name span {
  display: block;
}

.alt .testimonials-carousel .media-right,
.alt .testimonials-carousel .media-left {
  display: inline-block;
  margin-top: -40px;
  padding: 0;
}

.alt .testimonials-carousel .media-right img,
.alt .testimonials-carousel .media-left img {
  margin: 0;
  max-width: 80px;
  max-height: 80px;
  border: solid 5px #ffffff;
}

.alt .testimonials-carousel .testimonial-text:before,
.alt .testimonials-carousel .testimonial-text:after {
  color: #a5abb7;
  font-size: 18px;
}

@media (max-width: 767px) {
  .testimonials-carousel .testimonial {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .testimonials-carousel .testimonial:before,
  .testimonials-carousel .testimonial:after {
    display: none;
  }
}

/* Car carousel / OwlCarousel
  /* ========================================================================== */
.parking-carousel.owl-theme .owl-controls {
  margin: 0 !important;
}

.parking-carousel.owl-theme .owl-controls .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin: -20px 0 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  font-size: 30px;
  line-height: 30px;
  border: solid 3px #14181c;
  background: transparent;
  color: #14181c;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.parking-carousel.owl-theme .owl-controls .owl-nav [class*=owl-] .fa {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.parking-carousel.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
  background: #14181c;
  color: #ffffff;
}

.parking-carousel.owl-theme .owl-controls .owl-nav .owl-prev {
  left: -60px;
}

.parking-carousel.owl-theme .owl-controls .owl-nav .owl-next {
  right: -60px;
}

.parking-carousel.owl-theme .owl-controls .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.parking-carousel.owl-theme .owl-controls .owl-dots .owl-dot span {
  background-color: #a5abb7;
}

.parking-carousel.owl-theme .owl-controls .owl-dots .owl-dot:hover span,
.parking-carousel.owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background-color: #14181c;
}

@media (max-width: 639px) {
  .parking-carousel.owl-theme .owl-controls {
    display: none;
  }
}

@media (max-width: 767px) {
  .parking-carousel.owl-theme .owl-controls .owl-nav .owl-prev {
    left: 0;
  }
  .parking-carousel.owl-theme .owl-controls .owl-nav .owl-next {
    right: 0;
  }
}

/* Image carousel / OwlCarousel
  /* ========================================================================== */
.img-carousel .owl-nav button.owl-next, .img-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 50%;
  margin: -20px 0 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  font-size: 30px;
  line-height: 30px;
  border: solid 3px #14181c;
  background: transparent;
  color: #14181c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-carousel .owl-nav button.owl-next:hover, .img-carousel .owl-nav button.owl-prev:hover {
  background: #14181c;
  color: #ffffff;
}

.img-carousel .owl-nav button.owl-next .owl-prev, .img-carousel .owl-nav button.owl-prev .owl-prev {
  left: 30px;
}

.img-carousel .owl-nav button.owl-next .owl-next, .img-carousel .owl-nav button.owl-prev .owl-next {
  right: 30px;
}

.img-carousel .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.img-carousel .owl-dots .owl-dot span {
  background-color: #a5abb7;
}

.parking-big-card .img-carousel .owl-nav .owl-prev, .parking-big-card .img-carousel .owl-nav .owl-next {
  border: none;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.parking-big-card .img-carousel .owl-nav .owl-prev:hover, .parking-big-card .img-carousel .owl-nav .owl-next:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
}

.parking-big-card .img-carousel .owl-nav .owl-prev {
  left: 0;
}

.parking-big-card .img-carousel .owl-nav .owl-next {
  right: 0;
}

.parking-big-card .img-carousel .btn-zoom {
  position: absolute;
  padding: 0;
  line-height: 30px;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 10px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.parking-big-card .img-carousel .btn-zoom:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.parking-big-card .img-carousel .btn-zoom .fa {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 639px) {
  .img-carousel.owl-theme .owl-controls {
    display: none;
  }
}

/* 5.7 */
/* Accordions / FAQ
/* ========================================================================== */
.accordion {
  --bs-accordion-btn-padding-x: 0rem;
  --bs-accordion-btn-padding-y: .6rem;
  margin-bottom: 30px;
}

.accordion.payments-options .dot {
  margin-top: 0;
}

.accordion.payments-options .accordion-item:not(:first-of-type) {
  border-top: none;
}

.accordion.payments-options .panel-heading {
  border-bottom: none !important;
}

.accordion .panel {
  border-radius: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: solid 1px #e9e9e9;
}

.accordion .panel-heading {
  background: none;
  padding-left: 20px;
  padding-right: 0;
  border-bottom: solid 1px #e9e9e9;
  margin-bottom: -1px;
  position: relative;
}

.accordion .panel-title {
  font-size: 16px;
  line-height: 22px;
  color: #14181c;
}

.accordion .panel-title .collapsed {
  font-weight: 400;
}

.accordion .panel-title a {
  display: block;
  color: #14181c;
}

.accordion .panel-title a:hover {
  color: var(--bs-primary);
}

.accordion .panel-title .overflowed {
  margin-top: -2px;
}

.accordion .panel-title .overflowed img {
  margin-left: 5px;
}

.accordion .panel-body {
  padding-top: 20px;
  padding-right: 40px;
  padding-left: 20px;
  font-size: 15px;
}

.accordion .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: none;
}

.accordion .dot {
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  margin-top: -10px;
  margin-bottom: -10px;
  text-align: center;
  position: absolute;
  right: 0;
  background: #e9e9e9;
  color: #7f7f7f;
  border-left: solid 1px #d3d3d3;
}

.accordion .dot:before {
  content: '\f068';
  font-family: 'FontAwesome';
}

.accordion .collapsed .dot:before {
  content: '\f067';
}

.accordion .alert {
  margin-bottom: 0;
  color: #14181c;
}

.accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.accordion .accordion-button:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion .accordion-item:not(:first-of-type) {
  border-top: 1px solid #e9e9e9;
}

/* 5.16 */
/* Back to top button
/* ========================================================================== */
.to-top {
  z-index: 9999;
  width: 40px;
  height: 40px;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  position: fixed;
  bottom: -100px;
  right: 20px;
  margin-left: -20px;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  background-color: rgba(var(--bs-primary-rgb), 0.5);
  border: solid 2px var(--bs-primary);
  color: #ffffff;
}

.to-top:hover {
  background-color: #ffffff;
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}

/* 5.8 */
/* Blog / Post
/* ========================================================================== */
.content .post-wrap {
  position: relative;
}

.content .post-wrap + .post-wrap {
  margin-top: 50px;
  position: relative;
}

.post-media {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.post-media img {
  max-width: 100%;
  width: 100%;
}

.post-media .btn-play {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -90px 0 0 -90px;
}

.post-header {
  position: relative;
  margin-bottom: 25px;
}

.post-title {
  color: #14181c;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: 300;
}

.post-title a {
  color: #14181c;
  line-height: 1;
}

.post-title a:hover {
  color: var(--bs-primary);
}

.post-meta {
  color: #14181c;
  line-height: 1;
  font-size: 14px;
}

.post-meta a:hover {
  color: var(--bs-primary);
}

.post-meta a,
.post-meta .fa {
  color: #14181c;
}

.post-read-more {
  display: block;
}

.post-excerpt {
  font-size: 15px;
  line-height: 24px;
}

.about-the-author {
  margin-top: 30px;
  padding: 25px;
  border: solid 1px #e9e9e9;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.about-the-author .media-heading {
  font-size: 15px;
  font-weight: 700;
  color: #14181c;
}

.about-the-author .media-heading a {
  color: #14181c;
}

.about-the-author .media-heading a:hover {
  color: var(--bs-primary);
}

.about-the-author .media-object {
  margin-right: 15px;
  border-radius: 100%;
}

.about-the-author .media-body {
  font-size: 15px;
  line-height: 24px;
}

.about-the-author .media-body p {
  margin: 20px 0 0 0;
}

.about-the-author .media-body .media-category {
  color: #14181c;
  margin: 0 0 10px 0;
}

.post-wrap .owl-theme .owl-controls {
  margin: 0 !important;
}

.post-wrap .owl-theme .owl-controls .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin: -20px 0 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  font-size: 30px;
  line-height: 30px;
  border: solid 3px #14181c;
  background: transparent;
  color: #14181c;
}

.post-wrap .owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
  background: #14181c;
  color: #ffffff;
}

.post-wrap .owl-theme .owl-controls .owl-nav .owl-prev {
  left: 30px;
}

.post-wrap .owl-theme .owl-controls .owl-nav .owl-next {
  right: 30px;
}

.post-wrap .owl-theme .owl-controls .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.post-wrap .owl-theme .owl-controls .owl-dots .owl-dot span {
  background-color: #a5abb7;
}

.post-wrap blockquote {
  padding: 20px 20px 50px 20px;
  border-top: solid 6px var(--bs-primary);
  background-color: #14181c;
  position: relative;
}

.post-wrap blockquote:after {
  content: '\f10e';
  font-family: 'FontAwesome';
  font-size: 60px;
  line-height: 60px;
  display: block;
  position: absolute;
  right: 20px;
  bottom: 7px;
  opacity: .1;
}

.post-wrap blockquote h4, .post-wrap blockquote .h4 {
  margin-top: 0;
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  font-style: italic;
  color: #ffffff;
}

.post-wrap blockquote p {
  font-style: italic;
}

.post-wrap .owl-theme .owl-controls .owl-dots .owl-dot:hover span,
.post-wrap .owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background-color: #14181c;
}

@media (max-width: 639px) {
  .post-wrap .owl-theme .owl-controls {
    display: none;
  }
}

/* 5.1 */
/* Breadcrumbs
/* ========================================================================== */
.page-section.breadcrumbs {
  background-color: #f5f6f8;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1;
}

.page-section.breadcrumbs .breadcrumb {
  position: relative;
  padding: 5px 0 0 0;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  background-color: transparent;
}

.page-section.breadcrumbs .breadcrumb a {
  color: #9ea6b1;
}

.page-section.breadcrumbs .breadcrumb a:hover {
  border-bottom: solid 1px #14181c;
}

.page-section.breadcrumbs .breadcrumb li {
  margin-bottom: 10px;
}

.page-section.breadcrumbs .breadcrumb > li + li:before {
  content: '\f105 ';
  font-family: 'FontAwesome';
}

.page-section.breadcrumbs .breadcrumb > .active {
  color: #a5abb7;
}

.page-section.breadcrumbs .page-header {
  padding: 0;
  margin: 25px 0 0 0;
  border: none;
}

.page-section.breadcrumbs .page-header h1, .page-section.breadcrumbs .page-header .h1 {
  margin: 0;
  padding: 0;
  font-size: 70px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .page-section.breadcrumbs .page-header h1, .page-section.breadcrumbs .page-header .h1 {
    font-size: 35px;
  }
}

.page-section.breadcrumbs .page-header h1, .page-section.breadcrumbs .page-header .h1,
.page-section.breadcrumbs .page-header h1 small,
.page-section.breadcrumbs .page-header .h1 small,
.page-section.breadcrumbs .page-header h1 .small,
.page-section.breadcrumbs .page-header .h1 .small {
  color: #9ea6b1;
  text-transform: uppercase;
}

/* Car Card
/* ========================================================================== */
.thumbnail-parking-card {
  background-color: #ffffff;
  border-color: #e9e9e9;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.thumbnail-parking-card.no-border {
  border: 1px solid #e9e9e9 !important;
}

.thumbnail-parking-card .caption-title {
  font-size: 15px;
  font-weight: 700;
}

.thumbnail-parking-card .caption-text {
  margin-bottom: 10px;
  color: #7f7f7f;
}

.thumbnail-parking-card .buttons {
  margin-bottom: 15px;
}

.thumbnail-parking-card .buttons .btn-theme {
  padding-left: 40px;
  padding-right: 40px;
  line-height: 1;
  font-weight: 900;
}

.thumbnail-parking-card .table {
  margin-bottom: 0;
}

.thumbnail-parking-card .table td {
  font-size: 13px;
  color: #7f7f7f;
}

.thumbnail-parking-card .table td + td {
  border-left: solid 1px #e9e9e9;
}

.thumbnail-parking-card .table tbody {
  border-top: 1px solid #ddd;
}

.thumbnail-parking-card.hover,
.thumbnail-parking-card:hover {
  border-color: #e9e9e9;
}

.thumbnail-parking-card.no-border.hover,
.thumbnail-parking-card.no-border:hover {
  border: solid 1px #e9e9e9;
}

.parking-listing .thumbnail-parking-card .rating {
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 30px;
}

@media (max-width: 991.98px) {
  .parking-listing .thumbnail-parking-card .rating {
    margin-right: 0;
  }
}

.parking-listing .thumbnail-parking-card .caption-title {
  padding-left: 30px;
  padding-right: 30px;
}

.parking-listing .thumbnail-parking-card .caption-title-sub {
  font-size: 14px;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  color: var(--bs-primary);
}

.parking-listing .thumbnail-parking-card .caption-text {
  font-size: 13px;
  border-top: solid 1px #e9e9e9;
  padding-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 34px;
}

.parking-listing .thumbnail-parking-card .table td {
  text-align: center;
}

.parking-listing .thumbnail-parking-card .table td.buttons {
  padding: 0;
}

.parking-listing .thumbnail-parking-card .table td.buttons .btn-theme {
  display: block;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 13px;
  padding-bottom: 12px;
  line-height: 1;
  font-weight: 900;
  border-color: #14181c;
  background-color: #14181c;
}

@media (max-width: 991.98px) {
  .parking-listing .thumbnail-parking-card .table td.buttons .btn-theme {
    padding: 20px 16px;
  }
}

.parking-listing .thumbnail-parking-card .table td.buttons .btn-theme:hover {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
}

.parking-listing .thumbnail-parking-card + .thumbnail-parking-card {
  margin-top: 30px;
}

.parking-listing .thumbnail-parking-card .rating span.star.active:before,
.parking-listing .thumbnail-parking-card .rating span.star:hover:before,
.parking-listing .thumbnail-parking-card .rating span.star:hover ~ span.star:before {
  color: #ffd200;
}

.parking-big-card {
  border: solid 1px #e9e9e9;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.parking-big-card .row {
  margin-left: 0;
  margin-right: 0;
}

.parking-big-card .row [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.parking-big-card .sp-details .title {
  padding-left: 0;
}

.parking-big-card .sp-details .title h2, .parking-big-card .sp-details .title .h2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 1px;
  margin-bottom: 7px;
  color: #14181c;
}

.parking-big-card .sp-details .title h2 span, .parking-big-card .sp-details .title .h2 span {
  color: var(--bs-primary);
}

.parking-big-card .sp-details .title:before {
  display: none;
}

.parking-big-card .sp-details .price {
  padding: 15px 30px;
  background-color: #e9e9e9;
  color: #14181c;
}

.parking-big-card .sp-details .price strong {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
}

.parking-big-card .sp-details .price i {
  color: var(--bs-primary);
}

.parking-big-card .sp-details .list {
  padding: 10px 5px 0 30px;
}

.parking-big-card .sp-details .button {
  padding: 0 30px;
  margin-bottom: 15px;
}

.parking-big-card .sp-details ul {
  margin-bottom: 15px;
}

.parking-big-card .sp-details ul li {
  font-size: 14px;
  border-bottom: dashed 1px #d3d3d3;
  padding: 8px 0 8px 20px;
  position: relative;
}

.parking-big-card .sp-details ul li:before {
  content: '\f058';
  font-family: 'FontAwesome';
  position: absolute;
  top: 7px;
  left: 0;
  color: var(--bs-primary);
}

.parking-big-card .btn-zoom {
  position: absolute;
  padding: 0;
  line-height: 30px;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 10px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.parking-big-card .btn-zoom:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.parking-big-card .btn-zoom .fa {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.parking-big-card .parking-thumbnails {
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: 1;
}

.parking-big-card .parking-thumbnails a {
  display: block;
  position: relative;
  border: solid 4px rgba(255, 255, 255, 0.6);
  margin: 0 0 10px 10px;
}

.parking-big-card .parking-thumbnails a:hover {
  border-color: rgba(var(--bs-primary-rgb), 0.6);
  background-color: rgba(var(--bs-primary-rgb), 0.6);
}

.parking-big-card .parking-thumbnails a:hover:after {
  content: '\f002';
  font-family: 'FontAwesome';
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: -20px 0 0 -20px;
  text-align: center;
  left: 50%;
  top: 50%;
  color: #ffffff;
}

.parking-big-card .parking-thumbnails a:hover img {
  opacity: 0.8;
}

.parking-big-card .parking-thumbnails .swiper-pagination-bullet {
  width: inherit;
  height: inherit;
  border: none !important;
  background-color: transparent !important;
}

.parking-big-card .parking-thumbnails .swiper-pagination-bullet.swiper-pagination-bullet-active a {
  border-color: var(--bs-primary) !important;
}

.parking-big-card .owl-dots {
  display: none !important;
}

.parking-big-card.alt .sp-details .price {
  padding-top: 5px;
  background-color: #f5f6f8;
}

.parking-big-card.alt .sp-details ul li:last-child {
  border: none;
}

@media (max-width: 480px) {
  .parking-big-card .parking-thumbnails .swiper-pagination-bullet {
    width: 30px;
  }
  .parking-big-card .parking-thumbnails .swiper-pagination-bullet a {
    border-width: 1px;
  }
  .parking-big-card .parking-thumbnails .swiper-pagination-bullet img {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .parking-big-card .row > .col-md-4 {
    margin-top: 0;
  }
}

/* 5.10 */
/* Comments
/* ========================================================================== */
.comments {
  margin-top: 30px;
  margin-bottom: 30px;
}

.comments .media + .media {
  margin-top: 20px;
}

.comment {
  border: solid 1px #e9e9e9;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.comment .comment {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.comment-avatar img {
  width: 70px;
}

.pull-left.comment-avatar {
  margin-right: 10px;
}

.pull-right.comment-avatar {
  margin-left: 10px;
}

.comment-meta {
  padding-bottom: 20px;
  margin-bottom: 30px;
  position: relative;
}

.comment-meta:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 70px;
  height: 1px;
  background-color: #7f7f7f;
}

.comment-author {
  font-size: 15px;
  font-weight: 700;
  color: #14181c;
  display: flex;
  justify-content: space-between;
}

.comment-author a {
  color: #14181c;
}

.comment-author a:hover {
  color: var(--bs-primary);
}

.comment-date {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #a5abb7;
}

.comment-date .fa {
  color: var(--bs-primary);
  margin-left: 10px;
}

.comment-text {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 20px;
}

.comment-reply {
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 10px;
  margin-bottom: 0;
  background-color: #f9fafb;
  color: #a5abb7;
  display: flex;
  justify-content: space-between;
}

.comment-reply a {
  color: #a5abb7;
}

.comment-reply a:hover {
  color: #14181c;
}

.comment-reply .fa {
  color: #14181c;
}

.comments-form {
  padding: 20px 20px 0 20px;
  border: solid 1px #e9e9e9;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.comments-form > .block-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 1;
}

.comments-form .form-group {
  margin-bottom: 20px;
}

.comments-form .form-control {
  height: 50px;
  color: #14181c;
  border-color: #f9fafb;
  background-color: #f9fafb;
}

.comments-form .form-control:focus {
  border-color: #14181c;
}

.comments-form textarea.form-control {
  height: 200px;
}

.comments-form ::-webkit-input-placeholder {
  color: #14181c !important;
}

.comments-form :-moz-placeholder {
  color: #14181c !important;
}

.comments-form ::-moz-placeholder {
  color: #14181c !important;
}

.comments-form :-ms-input-placeholder {
  color: #14181c !important;
}

@media (max-width: 479px) {
  .comment-avatar img {
    width: 24px;
    height: auto;
  }
}

/* 5.14 */
/* Contact form
/* ========================================================================== */
.page-section.contact {
  background: url(../img/preview/page-section-contact-2.jpg) center top no-repeat;
  background-size: cover;
}

.page-section.contact:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.page-section.contact > .container {
  position: relative;
  z-index: 1;
}

.dark #contact-form .form-control {
  background: rgba(20, 24, 28, 0.8);
  border: 1px solid rgba(20, 24, 28, 0);
  color: #ffffff;
}

.dark #contact-form .form-control:focus {
  background: rgba(20, 24, 28, 0.5);
  border: 1px solid rgba(20, 24, 28, 0.5);
}

#contact-form textarea.form-control {
  height: 180px;
}

#contact-form .alert {
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px 15px;
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  color: #ffffff;
}

#contact-form .tooltip {
  left: 15px !important;
}

#contact-form .tooltip-inner {
  border-radius: 0;
  padding: 10px 20px;
  background-color: #000000;
}

#contact-form .tooltip-arrow {
  border-top-color: #000000;
}

#contact-form .form-control {
  height: 40px;
}

#contact-form .has-icon .form-control {
  padding-right: 40px;
}

#contact-form .form-control-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

#contact-form .form-button-submit {
  max-width: none;
}

/* Contact info
  /* ========================================================================== */
.contact-info .media .fa {
  padding: 0;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  border-radius: 20px;
  margin-right: 15px;
  text-align: center;
  background-color: #d3d3d3;
  color: #ffffff;
}

.contact-info .media-list {
  margin-bottom: 30px;
}

.contact-info .media-body {
  line-height: 22px;
}

.contact-list .media .fa {
  min-width: 18px;
  text-align: center;
}

.contact-list .media-left {
  margin-right: 15px;
}

.contact-list .media-right {
  margin-left: 15px;
}

/* 5.6 */
/* Tabs
/* ========================================================================== */
.content-tabs .nav-tabs {
  border-bottom: none;
}

.content-tabs .nav-tabs > li a {
  margin-top: 5px;
  padding: 10px 35px;
  background-color: #f5f6f7;
  color: #14181c;
}

.content-tabs .nav-tabs > li a.active {
  position: relative;
  margin-top: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #fff !important;
  border-right: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
  border-bottom: none;
  border-top: solid 1px #14181c;
  color: var(--bs-primary);
}

.content-tabs .nav-tabs > li a:before {
  content: '';
  display: block;
  height: 4px;
  width: 100%;
  position: absolute;
  top: -4px;
  left: -1px;
  background-color: #14181c;
}

.content-tabs .nav-tabs > li a:after {
  content: '';
  display: block;
  height: 4px;
  width: 100%;
  position: absolute;
  top: -4px;
  right: -1px;
  background-color: #14181c;
}

.content-tabs .tab-content {
  padding: 15px;
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  margin-top: 6px;
}

.content-tabs .media > .pull-left {
  margin-right: 15px;
}

.content-tabs .media > .pull-right {
  margin-left: 15px;
}

.content-tabs .media + .media {
  border-top: solid 1px #e9e9e9;
  padding-top: 15px;
}

.content-tabs .media p {
  margin-bottom: 0;
  font-size: 13px;
}

.content-tabs .media p + p {
  margin-top: 10px;
}

.content-tabs .media .media-heading {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.content-tabs .media .post-date {
  margin-bottom: 0;
  font-size: 12px;
  color: #d3d3d3;
  line-height: 20px;
}

.content-tabs #item-description p {
  margin-bottom: 0;
}

.content-tabs #item-description p + p {
  margin-top: 20px;
}

.content-tabs .rating {
  display: block;
  margin-bottom: 30px;
}

.content-tabs .nav-tabs > li > a,
.content-tabs .nav-tabs > li > a:focus {
  border-radius: 0;
  border-color: #e9e9e9 #e9e9e9 #e9e9e9;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #f5f6f7;
}

@media (min-width: 1200px) {
  .content-tabs .nav-tabs > li > a {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 1200px) {
  .content-tabs .tab-content {
    padding: 35px;
  }
}

/* 5.15 */
/* Google map
/* ========================================================================== */
.google-map,
.map-canvas,
#map-canvas {
  min-height: 700px;
  max-height: 700px;
}

.gm-style-iw {
  width: 280px !important;
  height: 380px !important;
  min-height: 380px !important;
  width: 350px !important;
  top: 20px !important;
  left: 0 !important;
}

.gmap-background .google-map,
.gmap-background #map-canvas {
  max-height: 100%;
  min-height: 100%;
}

.gmap-background {
  margin-top: 0;
  margin-bottom: 0;
}

.gmap-background .google-map {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.gmap-background #map-canvas {
  width: 100%;
  height: 100%;
}

.gmap-background .on-gmap {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
  min-height: 200px;
  width: 350px;
}

.form-search {
  text-align: left;
  background: rgba(20, 24, 28, 0.6);
}

.form-search .form-group {
  margin-bottom: 10px;
}

.form-search .form-control {
  height: 40px;
}

.form-search .has-icon .form-control {
  padding-right: 40px;
}

.form-search .form-control-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.form-search .btn-submit {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: #ffffff;
}

.form-search .btn-submit:hover {
  background-color: #14181c;
  border-color: #14181c;
}

.form-search .form-title {
  background: #14181c;
  color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}

.form-search .form-title h2, .form-search .form-title .h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  color: #ffffff;
}

.form-search .form-title .fa {
  line-height: 18px;
}

.form-search .form-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 40px;
  bottom: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #14181c;
}

.form-search .row-inputs .container-fluid, .form-search .row-inputs .container-sm, .form-search .row-inputs .container-md, .form-search .row-inputs .container-lg, .form-search .row-inputs .container-xl, .form-search .row-inputs .container-xxl {
  padding-left: 25px;
  padding-right: 25px;
}

.form-search .row-inputs .container-fluid div[class*="col-"], .form-search .row-inputs .container-sm div[class*="col-"], .form-search .row-inputs .container-md div[class*="col-"], .form-search .row-inputs .container-lg div[class*="col-"], .form-search .row-inputs .container-xl div[class*="col-"], .form-search .row-inputs .container-xxl div[class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.form-search .row-inputs + .row-inputs {
  padding-bottom: 10px;
}

.form-search .row-submit .container-fluid > .inner, .form-search .row-submit .container-sm > .inner, .form-search .row-submit .container-md > .inner, .form-search .row-submit .container-lg > .inner, .form-search .row-submit .container-xl > .inner, .form-search .row-submit .container-xxl > .inner {
  padding: 20px;
  border-top: solid 1px rgba(255, 255, 255, 0.35);
  line-height: 50px;
}

.form-search .row-submit a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: underline;
}

.form-search .row-submit a:hover {
  color: #ffffff;
}

.form-search.dark .bootstrap-select > .selectpicker {
  border: solid 1px transparent;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-search.light {
  background: #ffffff;
}

.form-search.light .form-title h2, .form-search.light .form-title .h2 {
  max-width: 260px;
}

.form-search.light .form-title .fa {
  font-size: 36px;
  line-height: 36px;
}

.form-search.light .row-submit .container-fluid > .inner, .form-search.light .row-submit .container-sm > .inner, .form-search.light .row-submit .container-md > .inner, .form-search.light .row-submit .container-lg > .inner, .form-search.light .row-submit .container-xl > .inner, .form-search.light .row-submit .container-xxl > .inner {
  border-top: solid 1px #d3d3d3;
  background-color: #e9e9e9;
}

.form-search.light .row-submit a {
  color: #7f7f7f;
}

.form-search.light .row-submit a:hover {
  color: var(--bs-primary);
}

.form-search .row,
.form-search .row div[class*="col-"] {
  margin-top: 0;
}

body.full-screen-map .wrapper {
  width: 630px;
}

body.full-screen-map .wrapper.closed {
  margin-left: -630px;
}

body.full-screen-map .google-map {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  max-height: 100%;
}

body.full-screen-map .form-search {
  background-color: transparent;
}

body.full-screen-map .form-search .row-submit .inner {
  padding: 0;
  border-top: none !important;
  background-color: transparent !important;
}

body.full-screen-map .map-canvas,
body.full-screen-map #map-canvas {
  height: 100%;
  max-height: 100%;
}

@media (max-width: 767px) {
  .gmap-background .on-gmap {
    width: 290px;
  }
}

@media (max-width: 991px) {
  body.full-screen-map .wrapper.closed {
    margin-left: -320px;
  }
}

@media (max-width: 991px) {
  body.full-screen-map .wrapper {
    width: 320px;
  }
}

@media (max-height: 600px) {
  .google-map,
  .map-canvas,
  #map-canvas {
    min-height: 390px;
    max-height: 390px;
  }
}

@media (max-height: 400px) {
  .google-map,
  .map-canvas,
  #map-canvas {
    min-height: 200px;
    max-height: 200px;
  }
}

/* 2.0 */
/* Header
/* ========================================================================== */
.sticky-wrapper {
  position: relative;
  z-index: 1;
}

.sticky-wrapper:not(.is-sticky) {
  height: auto !important;
}

.sticky-wrapper .header {
  z-index: 20;
}

.sticky-wrapper.is-sticky .header .header-wrapper .container {
  min-height: 40px;
}

.header {
  -webkit-box-shadow: 0 4px 14px 0 rgba(50, 50, 50, 0.3);
  box-shadow: 0 4px 14px 0 rgba(50, 50, 50, 0.3);
}

.header .header-wrapper {
  padding: 0;
  background-color: #fbfbfb;
  position: relative;
}

.header .header-wrapper > .container {
  position: relative;
  min-height: 50px;
}

.header .navigation-wrapper.navigation-sticky {
  width: 100%;
}

.wide .sticky-wrapper .header {
  width: 100% !important;
}

.full-screen-map .header-wrapper > .container {
  width: auto;
}

.full-screen-map .sticky-wrapper.is-sticky .header {
  width: 630px !important;
}

.content-area.scroll {
  background-color: #fbfbfb;
  width: 630px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.content-area.scroll .swiper-slide {
  height: auto;
}

.content-area.scroll .open-close-area {
  position: absolute;
  right: -40px;
  top: 50%;
  width: 40px;
  height: 40px;
}

.content-area.scroll .open-close-area a {
  display: block;
  background-color: #14181c;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
}

.content-area.scroll .open-close-area a:hover {
  background-color: var(--bs-primary);
}

.content-area.scroll .container {
  width: auto;
}

.closed .content-area.scroll {
  left: -630px;
}

@media (max-width: 991px) {
  .full-screen-map .sticky-wrapper.is-sticky .header {
    width: 320px !important;
  }
}

@media (max-width: 991px) {
  .closed .content-area.scroll {
    left: -320px;
  }
}

@media (max-width: 991px) {
  .content-area.scroll {
    width: 320px;
  }
}

/* 5.17 */
/* 6.0 */
/* Helper classes
/* ========================================================================== */
.text-uppercase {
  text-transform: uppercase;
}

.margin-top {
  margin-top: 50px;
}

.margin-bottom {
  margin-bottom: 50px;
}

.clear {
  clear: both;
}

.inline-block {
  display: inline-block !important;
}

.overflowed {
  overflow: hidden;
  position: relative;
}

.relative {
  position: relative;
}

.vhidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.div-table,
.div-cell {
  height: 100% !important;
  display: table !important;
}

.div-cell {
  display: table-cell !important;
  vertical-align: middle !important;
  float: none !important;
}

.div-cell.va-top {
  vertical-align: top !important;
}

.div-cell.padding-top {
  padding-top: 15px;
}

.div-cell.padding-bottom {
  padding-top: 15px;
}

.div-table.full-width {
  width: 100%;
}

.row.div-table {
  margin: 0;
}

@media (min-width: 480px) and (max-width: 767px) {
  .col-xsp-4,
  .col-xsp-6,
  .col-xsp-8 {
    float: left;
  }
  .col-xsp-4 {
    width: 33.33333333%;
  }
  .col-xsp-6 {
    width: 50%;
  }
  .col-xsp-8 {
    width: 66.66666667%;
  }
  .col-xsp-offset-3 {
    margin-left: 25%;
  }
}

@media (min-width: 1200px) {
  .text-left-lg {
    text-align: left;
  }
  .text-right-lg {
    text-align: right;
  }
  .text-center-lg {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .text-left-md {
    text-align: left;
  }
  .text-right-md {
    text-align: right;
  }
  .text-center-md {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .text-left-sm {
    text-align: left;
  }
  .text-right-sm {
    text-align: right;
  }
  .text-center-sm {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .row.div-table .div-cell {
    display: block !important;
  }
}

/* Remove firefox dotted line
/* -------------------------------------------------------------------------- */
a,
a:active,
a:focus,
input,
input:active,
input:focus,
button,
button:active,
button:focus,
select,
select:active,
select:focus,
.bootstrap-select .btn,
.bootstrap-select .btn:active,
.bootstrap-select .btn:focus {
  outline: 0 !important;
}

/* Remove webkit outline glow
/* -------------------------------------------------------------------------- */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 5.3 */
/* Message box
/* ========================================================================== */
.message-box {
  padding: 15px;
  position: relative;
  text-align: center;
  background-color: var(--bs-primary);
  color: #ffffff;
}

.message-box h2, .message-box .h2 {
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

.message-box:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.message-box .message-box-inner {
  border: solid 1px #ffffff;
  padding: 20px 15px;
  position: relative;
  z-index: 1;
}

.message-box.alt {
  padding: 12px;
  text-align: left;
  background-color: #a5abb7;
}

.message-box.alt .message-box-inner {
  padding: 10px 15px;
}

.message-box.alt h2, .message-box.alt .h2 {
  font-size: 18px;
  font-weight: bold;
}

.message-box.alt .btn {
  font-size: 11px;
  border-color: #ffffff;
  background-color: #ffffff;
  color: #14181c;
  margin-top: -6px;
  margin-left: 30px;
}

.message-box.alt .btn:hover {
  border-color: #14181c;
  background-color: #14181c;
  color: #ffffff;
}

.message-box.clear {
  padding: 0;
  background: none;
  color: #14181c;
}

.message-box.clear h2, .message-box.clear .h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  text-transform: none;
  color: #14181c;
}

.message-box.clear .message-box-inner {
  padding: 30px 0;
  border: solid 1px #d3d3d3;
  border-left: none;
  border-right: none;
}

/* 5.2 */
/* Pagination
/* ========================================================================== */
.pagination-wrapper {
  border-top: solid 1px #e9e9e9;
  margin-top: 30px;
  padding-top: 50px;
  line-height: 0;
}

.pagination {
  margin: -10px 0 0 0;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
}

.pagination > li > a {
  font-weight: 700;
  background-color: transparent;
  color: #14181c;
  margin: 10px 10px 0 0;
  padding: 7px 15px 7px 15px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-radius: 0;
  background-color: transparent;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0;
  background-color: transparent;
}

.pagination > li > a,
.pagination > li > span {
  border: solid 3px rgba(20, 24, 28, 0.1);
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  color: #ffffff;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: rgba(20, 24, 28, 0.1);
  border-color: transparent;
  color: #14181c;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #14181c;
  background-color: transparent;
}

/* 5.12 */
/* Portfolio
/* ========================================================================== */
.isotope-item {
  z-index: 2;
  margin-left: -1px;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -ms-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}

.isotope.infinite-scrolling {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

.filtrable {
  display: inline-block;
  margin-bottom: 20px;
}

.filtrable li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  line-height: 1;
}

.filtrable a {
  display: block;
  border: solid 3px rgba(20, 24, 28, 0.1);
  background-color: transparent;
  color: #a5abb7;
  padding: 10px 20px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.filtrable a:hover {
  border: solid 3px #14181c;
  background-color: #14181c;
  color: #ffffff;
}

.filtrable .current a,
.filtrable .active a,
.filtrable .current a:hover,
.filtrable .active a:hover {
  border: solid 3px #14181c;
  background-color: #14181c;
  color: #ffffff;
}

.thumbnails.portfolio {
  border: solid 1px transparent;
}

.thumbnails.portfolio .thumbnail .caption.hovered {
  text-align: center;
  padding: 0;
  background-color: rgba(var(--bs-primary-rgb), 0.5);
}

.thumbnails.portfolio .thumbnail .caption-title {
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.thumbnails.portfolio .thumbnail .caption-category {
  margin-bottom: 0;
}

.thumbnails.portfolio .thumbnail .media + .caption {
  text-align: center;
  margin-top: 0;
  padding: 5px 5px 10px 5px;
  border-top: solid 3px rgba(20, 24, 28, 0.1);
  background-color: #f9fafb;
}

.thumbnails.portfolio .thumbnail .media + .caption .caption-title {
  margin-bottom: 5px;
  color: #14181c;
}

.thumbnails.portfolio .thumbnail .media + .caption .caption-title a {
  color: #14181c;
}

.thumbnails.portfolio .thumbnail .media + .caption .caption-title a:hover {
  border-bottom: solid 1px #14181c;
}

.thumbnails.portfolio .thumbnail .media + .caption .caption-category a {
  color: #14181c;
}

.thumbnails.portfolio .thumbnail .media + .caption .caption-category a:hover {
  border-bottom: solid 1px #14181c;
}

.thumbnails.portfolio .caption-buttons {
  margin-bottom: 0;
  margin-top: 10px;
}

.thumbnails.portfolio .caption-buttons .btn {
  color: #ffffff;
  border-color: #ffffff;
  margin: 0 5px;
  padding: 8px 12px;
  font-size: 14px;
}

.thumbnails.portfolio.row div[class*="col-"] {
  margin-top: 0;
}

.thumbnails.portfolio .thumbnail .caption-zoom,
.thumbnails.portfolio .thumbnail .caption-link {
  border: solid 3px rgba(20, 24, 28, 0.1);
}

.thumbnails.portfolio .thumbnail .caption-zoom:hover,
.thumbnails.portfolio .thumbnail .caption-link:hover {
  border: solid 3px #14181c;
  background-color: #14181c;
}

.thumbnails.portfolio .caption-title,
.thumbnails.portfolio .caption-title a {
  color: #ffffff;
}

.thumbnails.portfolio .caption-category,
.thumbnails.portfolio .caption-category a {
  font-size: 12px;
  margin-bottom: 0;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 14px;
  color: #ffffff;
}

.project-media .owl-theme .owl-controls {
  margin: 0 !important;
}

.project-media .owl-theme .owl-controls .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin: -20px 0 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  font-size: 30px;
  line-height: 30px;
  border: solid 3px #14181c;
  background: transparent;
  color: #14181c;
}

.project-media .owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
  background: #14181c;
  color: #ffffff;
}

.project-media .owl-theme .owl-controls .owl-nav .owl-prev {
  left: 30px;
}

.project-media .owl-theme .owl-controls .owl-nav .owl-next {
  right: 30px;
}

.project-media .owl-theme .owl-controls .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.project-media .owl-theme .owl-controls .owl-dots .owl-dot span {
  background-color: #a5abb7;
}

.project-media .owl-theme .owl-controls .owl-dots .owl-dot:hover span,
.project-media .owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background-color: #14181c;
}

.feature-overview h3, .feature-overview .h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

.feature-overview p {
  font-size: 15px;
  color: #14181c;
}

.project-details h3, .project-details .h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

.project-details .dl-horizontal dt {
  text-align: left;
  padding-bottom: 5px;
  color: #3c4547;
  width: 90px;
  padding-bottom: 5px;
}

.project-details .dl-horizontal dd {
  position: relative;
  margin-left: 110px;
}

@media (max-width: 639px) {
  .project-media .owl-theme .owl-controls {
    display: none;
  }
}

/* 5.5 */
/* Rating stars
/* ========================================================================== */
.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  font-size: 14px;
  text-align: left;
}

.rating span.star {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
}

.rating span.star:hover {
  cursor: pointer;
}

.rating span.star:before {
  content: "\f006";
  padding-right: 5px;
  color: #d3d3d3;
}

.rating span.star.active:before,
.rating span.star:hover:before,
.rating span.star:hover ~ span.star:before {
  content: "\f005";
  color: #3c3c3c;
}

/* 5.9 */
/* Recent posts
/* ========================================================================== */
.recent-post .media {
  background-color: #ffffff;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.recent-post .media.pull-left {
  padding-right: 15px;
  padding-bottom: 15px;
}

.recent-post .media.pull-right {
  padding-left: 15px;
  padding-bottom: 15px;
}

.recent-post .meta-date {
  padding: 2px 15px 7px 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  background: #14181c;
  color: #ffffff;
}

.recent-post .meta-date .day {
  font-size: 34px;
  line-height: 34px;
}

.recent-post .meta-date .month {
  margin-top: 3px;
  font-size: 18px;
  line-height: 18px;
}

.recent-post .media-body {
  font-size: 16px;
  line-height: 18px;
}

.recent-post .media-category {
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
  margin-bottom: 7px;
  color: var(--bs-primary);
}

.recent-post .media-heading {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 25px;
}

.recent-post .media-heading a {
  color: #14181c;
}

.recent-post .media-heading a:hover {
  color: var(--bs-primary);
}

.recent-post .media-excerpt {
  font-weight: 100;
  line-height: 24px;
}

.recent-post .media-meta {
  display: block;
  margin-top: 10px;
  font-size: 15px;
  line-height: 18px;
  color: #a5abb7;
}

.recent-post .media-meta .divider {
  margin: 0 7px 0 5px;
}

.recent-post .media-meta a {
  color: #a5abb7;
}

.recent-post .media-meta a:hover {
  color: #14181c;
}

.recent-post .media-link {
  padding: 0;
  margin-right: 30px;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.recent-post .media-link .fa {
  position: absolute;
  bottom: 50%;
  left: 50%;
  font-size: 24px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -15px;
  margin-bottom: -15px;
  color: #ffffff;
  z-index: 11;
  opacity: 0;
}

.recent-post .media-link:after {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(230, 0, 0, 0);
  z-index: 10;
}

.recent-post .media-link:hover .fa {
  bottom: 50%;
  opacity: 1;
}

.recent-post .media-link:hover:after {
  background-color: rgba(230, 0, 0, 0.7);
}

.recent-post .media-link .badge {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 11;
  border-radius: 0;
  padding: 5px 10px;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
}

.recent-post .media-link .badge .fa {
  position: relative;
  font-size: 13px;
  line-height: 13px;
  width: 13px;
  height: 13px;
  margin: auto;
  left: auto;
  opacity: 1;
}

.recent-post .media-link .badge.type {
  background-color: var(--bs-primary);
}

.recent-post .media-link .badge.post {
  left: auto;
  right: 0;
  background-color: #7f7f7f;
}

.recent-post.alt {
  margin: 0 auto;
}

.recent-post.alt .media-left {
  padding-right: 30px;
}

.recent-post.alt .media-right {
  padding-left: 30px;
}

.recent-post.alt .media-link {
  margin: 0 0 20px 0;
}

.recent-post.alt .media-link .media-object {
  width: 100%;
  max-width: 100%;
}

.recent-post.alt .media-body {
  padding-right: 20px;
  padding-bottom: 20px;
}

.recent-post.alt .media-meta {
  margin-top: 0;
  margin-bottom: 10px;
}

.recent-post .media-meta .fa,
.recent-post .media-meta .glyphicon {
  font-size: 14px;
  margin-right: 5px;
}

.widget .recent-post .media {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  gap: 10px;
}

.widget .recent-post .media + .media {
  padding-top: 15px;
  border-top: solid 1px #e9e9e9;
}

.widget .recent-post .media-link {
  margin-right: 10px;
}

.widget .recent-post .media-meta {
  margin-top: 0;
  font-size: 14px;
  color: #14181c;
}

.widget .recent-post .media-meta a {
  color: #14181c;
}

.widget .recent-post .media-meta a:hover {
  color: var(--bs-primary);
}

.widget .recent-post .media-heading {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 480px) {
  .recent-post .media-link {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
  .recent-post .media-link img {
    width: 100%;
    max-width: 100%;
  }
}

/* Shopping cart popup
/* ========================================================================== */
.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
}

.cart-wrapper {
  margin-left: 30px;
}

.cart-wrapper .btn {
  height: 40px;
}

.cart-total {
  cursor: pointer;
  font-weight: bold;
  line-height: 33px;
  text-transform: uppercase;
  color: #a5abb7;
}

.cart-total .fa {
  display: block;
  font-size: 24px;
  line-height: 33px;
  margin-left: 2px;
  color: #14181c;
}

.cart-total .items {
  margin-left: 5px;
}

.cart-items {
  position: relative;
  margin: 0 0 0 auto;
  max-width: 450px;
}

.cart-items .cart-items-inner {
  background-color: #ffffff;
  border: solid 3px #e9e9e9;
}

.cart-items .media {
  padding: 15px;
  overflow: hidden;
}

.cart-items .media + .media {
  border-top: solid 1px #e9e9e9;
  margin-top: 0;
}

.cart-items .item-image {
  width: 50px;
}

.cart-items .item-title {
  margin-bottom: 0;
  font-size: 17px;
  text-transform: uppercase;
  color: #14181c;
  font-weight: 300;
}

.cart-items .item-title a {
  color: #14181c;
}

.cart-items .item-desc {
  margin-bottom: 0;
  font-size: 10px;
  text-transform: uppercase;
}

.cart-items .item-price {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: bold;
  line-height: 1;
  color: #14181c;
}

.cart-items .btn-call-checkout {
  margin-left: 15px;
}

.popup-cart .modal-dialog {
  margin-top: 90px;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.15s ease-out;
  -o-transition: -o-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.modal.in .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (max-width: 1199px) {
  .cart-wrapper {
    margin-left: 15px;
  }
}

@media (max-width: 480px) {
  .cart-wrapper {
    margin-left: 0;
  }
}

@media (max-width: 639px) {
  .cart-total {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .cart-items:before {
    content: '';
    position: absolute;
    top: -9px;
    right: 50px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e9e9e9;
  }
  .cart-items:after {
    content: '';
    position: absolute;
    top: -7px;
    right: 50px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
  }
}

@media (min-width: 768px) {
  .popup-cart .modal-dialog {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .popup-cart .modal-dialog > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Shop info banners
  /* ========================================================================== */
.product-list {
  max-width: 450px;
  margin: 0 auto;
}

.product-list .media {
  clear: both;
}

.product-list .media + .media {
  padding-top: 15px;
}

.product-list .media-heading {
  font-size: 18px;
  font-weight: 300;
  color: #14181c;
}

.product-list .media-heading a {
  color: #14181c;
}

.product-list .media-heading small, .product-list .media-heading .small {
  display: block;
  margin-top: 5px;
}

.product-list .media-body {
  min-height: 80px;
}

.product-list .price {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
}

.product-list .price del {
  font-weight: normal;
  font-size: 14px;
  color: #14181c;
}

.product-list .price ins {
  padding-right: 5px;
  text-decoration: none;
  color: var(--bs-primary);
}

.product-list .rating {
  font-size: 14px;
}

.product-list .media-link {
  padding: 0;
  margin-right: 30px;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.product-list .media-link .fa {
  position: absolute;
  bottom: 50%;
  left: 50%;
  font-size: 24px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -15px;
  margin-bottom: -15px;
  color: #ffffff;
  z-index: 11;
  opacity: 0;
}

.product-list .media-link:after {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(230, 0, 0, 0);
  z-index: 10;
}

.product-list .media-link:hover .fa {
  bottom: 50%;
  opacity: 1;
}

.product-list .media-link:hover:after {
  background-color: rgba(230, 0, 0, 0.7);
}

/* Shop info banners
  /* ========================================================================== */
.shop-info-banners .block {
  max-width: 450px;
  margin: 0 auto;
  padding: 15px 17px;
  border: solid 4px #3c3c3c;
  background-color: transparent;
  color: #3c3c3c;
  font-weight: 500;
  line-height: 18px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shop-info-banners .block .fa {
  font-size: 60px;
  line-height: 60px;
  color: #3c3c3c;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shop-info-banners .block .media-heading {
  font-size: 24px;
  line-height: 24px;
  font-weight: 900;
  color: #3c3c3c;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shop-info-banners .block:hover {
  color: #ffffff;
  background-color: #3c3c3c;
}

.shop-info-banners .block:hover .fa {
  color: #ffffff;
}

.shop-info-banners .block:hover .media-heading {
  color: #ffffff;
}

/* Shop sorting
  /* ========================================================================== */
.shop-sorting {
  border-bottom: solid 1px rgba(20, 24, 28, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.shop-sorting .bootstrap-select > .selectpicker {
  height: 35px;
}

.shop-sorting .bootstrap-select .selectpicker > .filter-option {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  text-transform: none;
}

.shop-sorting .col-sm-4 .btn-theme {
  height: 35px;
  width: 35px;
  line-height: 1;
  padding: 6px;
}

.shop-sorting .col-sm-4 .btn-theme:hover {
  background-color: var(--bs-primary);
}

.form-sign-in {
  margin-bottom: 30px;
}

.form-sign-in .row {
  margin-top: -15px;
}

.form-sign-in .row + .row {
  margin-top: 0;
}

.form-sign-in .btn-block {
  margin-left: auto;
  margin-right: auto;
}

.form-sign-in .btn.facebook {
  background-color: #3e5c98;
  border-color: #3e5c98;
}

.form-sign-in .btn.twitter {
  background-color: #22a9e0;
  border-color: #22a9e0;
}

.form-sign-in .form-group {
  margin-bottom: 0;
}

.form-sign-in .checkbox {
  font-size: 11px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #14181c;
}

.form-sign-in .checkbox label {
  font-weight: 700 !important;
}

.form-sign-in .checkbox input[type=checkbox] {
  margin-top: 2px;
}

.form-sign-in .forgot-password:hover {
  border-bottom: solid 1px #14181c;
}

.form-sign-in ::-webkit-input-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-sign-in :-moz-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-sign-in ::-moz-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-sign-in :-ms-input-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-sign-in .row div[class*="col-"],
.form-sign-in .row aside[class*="col-"] {
  margin-top: 15px;
}

.form-sign-in .btn.facebook:hover,
.form-sign-in .btn.twitter:hover {
  background-color: #14181c;
  border-color: #14181c;
}

.form-sign-in .forgot-password,
.form-sign-in .bootstrap-select .selectpicker > .filter-option {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.orders {
  margin-bottom: 30px;
}

.orders thead {
  background-color: #14181c;
  color: #ffffff;
}

.orders thead > tr > th {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 14px;
  font-weight: 300;
  border: none;
}

.orders td.image {
  padding-left: 0;
}

.orders td.quantity {
  font-size: 15px;
  font-weight: 700;
  color: #14181c;
  vertical-align: middle;
}

.orders td.description {
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
  color: #14181c;
  vertical-align: middle;
}

.orders td.description h4, .orders td.description .h4 {
  margin: 0;
  font-weight: 300;
}

.orders td.description h4 a, .orders td.description .h4 a {
  color: #14181c;
}

.orders td.description h4 a:hover, .orders td.description .h4 a:hover {
  color: var(--bs-primary);
}

.orders td.add {
  vertical-align: middle;
}

.orders td.total {
  font-size: 18px;
  font-weight: 900;
  color: #14181c;
  vertical-align: middle;
}

.orders td.total a {
  margin-left: 10px;
  color: #a5abb7;
}

.orders td.total a:hover {
  color: var(--bs-primary);
}

.orders .media-link {
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.orders .media-link .fa {
  position: absolute;
  bottom: 50%;
  left: 50%;
  font-size: 24px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -15px;
  margin-bottom: -15px;
  color: #ffffff;
  z-index: 11;
  opacity: 0;
}

.orders .media-link:after {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(230, 0, 0, 0);
  z-index: 10;
}

.orders .media-link:hover .fa {
  bottom: 50%;
  opacity: 1;
}

.orders .media-link:hover:after {
  background-color: rgba(230, 0, 0, 0.7);
}

.orders .shopping-cart {
  padding: 10px;
  border: solid 3px rgba(20, 24, 28, 0.1);
}

.orders .shopping-cart table {
  width: 100%;
}

.orders .shopping-cart table td {
  padding: 3px;
  width: 50%;
  font-size: 15px;
  text-transform: uppercase;
  text-align: right;
  padding-right: 10px;
  color: #14181c;
}

.orders .shopping-cart table td + td {
  text-align: left;
  padding-right: 3px;
  padding-left: 10px;
}

.orders .shopping-cart table tfoot td {
  border-top: solid 1px #a5abb7;
  font-size: 18px;
  font-weight: 900;
  color: #14181c;
}

.orders .table > thead > tr > th,
.orders .table > tbody > tr > th,
.orders .table > tfoot > tr > th,
.orders .table > thead > tr > td,
.orders .table > tbody > tr > td,
.orders .table > tfoot > tr > td {
  border-top: none;
  border-bottom: solid 1px #e9e9e9;
}

.wishlist thead {
  background-color: #14181c;
  color: #ffffff;
}

.wishlist thead > tr > th {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 14px;
  font-weight: 300;
  border: none;
}

.wishlist .table {
  margin-bottom: 30px;
}

.wishlist td.image {
  padding-left: 0;
}

.wishlist td.price {
  font-size: 18px;
  font-weight: 400;
  color: #14181c;
  vertical-align: middle;
}

.wishlist td.description {
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
  color: #14181c;
  vertical-align: middle;
}

.wishlist td.description h4, .wishlist td.description .h4 {
  margin: 0;
  font-weight: 300;
}

.wishlist td.description h4 a, .wishlist td.description .h4 a {
  color: #14181c;
}

.wishlist td.description h4 a:hover, .wishlist td.description .h4 a:hover {
  color: var(--bs-primary);
}

.wishlist td.add {
  vertical-align: middle;
}

.wishlist td.add .btn-theme {
  font-size: 12px;
  line-height: 16px;
  padding: 9px 30px;
}

.wishlist td.add .btn-theme .fa {
  font-size: 16px;
  line-height: 16px;
}

.wishlist td.total {
  font-size: 18px;
  font-weight: 900;
  color: #14181c;
  vertical-align: middle;
}

.wishlist td.total a {
  margin-left: 10px;
  color: #14181c;
}

.wishlist td.total a:hover {
  color: var(--bs-primary);
}

.wishlist td.total a .fa-close:before {
  font-family: 'Poppins', sans-serif;
  content: 'X';
}

.wishlist .media-link {
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.wishlist .media-link .fa {
  position: absolute;
  bottom: 50%;
  left: 50%;
  font-size: 24px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -15px;
  margin-bottom: -15px;
  color: #ffffff;
  z-index: 11;
  opacity: 0;
}

.wishlist .media-link:after {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(230, 0, 0, 0);
  z-index: 10;
}

.wishlist .media-link:hover .fa {
  bottom: 50%;
  opacity: 1;
}

.wishlist .media-link:hover:after {
  background-color: rgba(230, 0, 0, 0.7);
}

.wishlist .btn-continue-shopping {
  font-size: 12px;
  line-height: 16px;
  padding: 7px 50px;
}

.wishlist .btn-continue-shopping .fa {
  font-size: 16px;
  line-height: 16px;
}

.wishlist .form-sign-in .row {
  margin-top: -20px;
}

.wishlist .form-sign-in .row + .row {
  margin-top: 0;
}

.wishlist .form-sign-in .hello-text-wrap {
  margin-top: 8px !important;
}

.wishlist .form-sign-in .btn-theme {
  padding-top: 14px;
  padding-bottom: 14px;
}

.wishlist .form-sign-in .form-control {
  height: 50px;
}

.wishlist .form-sign-in .col-md-12.col-lg-6.text-right-lg {
  line-height: 0;
}

.wishlist .form-sign-in .forgot-password {
  line-height: 18px;
}

.wishlist .table > thead > tr > th,
.wishlist .table > tbody > tr > th,
.wishlist .table > tfoot > tr > th,
.wishlist .table > thead > tr > td,
.wishlist .table > tbody > tr > td,
.wishlist .table > tfoot > tr > td {
  border-top: none;
  border-bottom: solid 1px #a5abb7;
  padding-top: 20px;
  padding-bottom: 31px;
}

.wishlist .form-sign-in .row div[class*="col-"],
.wishlist .form-sign-in .row aside[class*="col-"] {
  margin-top: 20px;
}

.form-delivery {
  margin-bottom: 30px;
}

.form-delivery .row {
  margin-top: -15px;
}

.form-delivery .row + .row {
  margin-top: 0;
}

.form-delivery .form-group {
  margin-bottom: 0;
}

.form-delivery .checkbox {
  font-size: 11px;
  text-transform: uppercase;
  color: #14181c;
}

.form-delivery .checkbox label {
  font-weight: 700 !important;
}

.form-delivery .checkbox input[type=checkbox] {
  margin-top: 2px;
}

.form-delivery .bootstrap-select .selectpicker > .filter-option {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-delivery ::-webkit-input-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-delivery :-moz-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-delivery ::-moz-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-delivery :-ms-input-placeholder {
  color: #14181c !important;
  font-weight: 700 !important;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
}

.form-delivery .row div[class*="col-"],
.form-delivery .row aside[class*="col-"] {
  margin-top: 15px;
}

.payments-options {
  margin-bottom: 30px;
}

.payments-options .radio {
  padding-left: 0;
}

.payments-options .panel {
  border: none;
  border-radius: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: solid 1px #d3d3d3;
  background-color: transparent;
}

.payments-options .panel-heading {
  background: none;
  padding-left: 0;
  padding-right: 0;
}

.payments-options .panel-title {
  font-size: 14px;
  line-height: 22px;
  color: #14181c;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}

.payments-options .panel-title .collapsed {
  font-weight: 400;
}

.payments-options .panel-title a {
  display: inline-block;
  color: #7f7f7f;
}

.payments-options .panel-title .overflowed {
  margin-top: -2px;
}

.payments-options .panel-title .overflowed img {
  margin-left: 5px;
}

.payments-options .panel-body {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}

.payments-options .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: none;
}

.payments-options .accordion-button {
  position: relative;
  padding-left: 30px;
}

.payments-options .dot {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 27%;
  width: 20px;
  height: 20px;
  border: solid 3px #d3d3d3;
  border-radius: 10px;
  margin-right: 10px;
}

.payments-options .dot:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  margin: 3px 0 0 3px;
  border-radius: 4px;
  background-color: var(--bs-primary);
}

.payments-options .collapsed .dot:before {
  content: none;
}

.payments-options .alert {
  font-size: 13px;
  margin-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
  border-color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: #14181c;
}

.compare-products table {
  width: 100%;
  border: solid 3px #e9e9e9;
  color: #14181c;
}

.compare-products table td {
  position: relative;
  vertical-align: top;
  padding: 15px 20px;
}

.compare-products .product .close {
  position: absolute;
  top: 15px;
  right: 20px;
}

.compare-products .product .media-link {
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.compare-products .product .media-link .fa {
  position: absolute;
  bottom: 50%;
  left: 50%;
  font-size: 24px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -15px;
  margin-bottom: -15px;
  color: #ffffff;
  z-index: 11;
  opacity: 0;
}

.compare-products .product .media-link:after {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(230, 0, 0, 0);
  z-index: 10;
}

.compare-products .product .media-link:hover .fa {
  bottom: 50%;
  opacity: 1;
}

.compare-products .product .media-link:hover:after {
  background-color: rgba(230, 0, 0, 0.7);
}

.compare-products .reviews {
  color: #14181c;
}

.compare-products .reviews:hover {
  border-bottom: solid 1px #14181c;
}

.compare-products .price {
  font-size: 18px;
  font-weight: 900;
}

.compare-products .colors ul {
  overflow: hidden;
  margin-top: -7px;
  margin-bottom: 0;
}

.compare-products .colors ul li {
  margin: 7px 7px 0 0;
}

.compare-products .colors ul li a {
  display: block;
  width: 35px;
  height: 35px;
  border: solid 3px rgba(20, 24, 28, 0.1);
  padding: 3px;
}

.compare-products .colors ul li a span {
  display: block;
  width: 23px;
  height: 23px;
}

.compare-products .colors ul li a:hover {
  border-color: #14181c;
}

.compare-products .buttons {
  margin-top: -10px;
  overflow: hidden;
}

.compare-products .buttons .quantity {
  overflow: hidden;
  margin-right: 5px;
}

.compare-products .buttons .quantity .form-control {
  height: 40px !important;
}

.compare-products .buttons .quantity .btn {
  margin-top: 10px;
  margin-right: 1px;
  background-color: #14181c;
  color: #7f7f7f;
  height: 40px;
}

.compare-products .buttons .quantity .btn:hover {
  color: #ffffff;
}

.compare-products .buttons .quantity .qty {
  margin-top: 10px;
  margin-right: 1px;
  width: 40px;
  height: 60px;
  background-color: #14181c;
  border-color: #14181c;
  color: #ffffff;
}

.compare-products .buttons .btn-cart {
  margin-top: 10px;
  margin-right: 5px;
  max-width: 100%;
  background-color: #14181c;
  border-color: #14181c;
  border-width: 3px;
  color: #ffffff;
  height: 50px;
}

.compare-products .buttons .btn-cart:hover {
  background-color: transparent;
  color: #14181c;
}

.compare-products .table-bordered > thead > tr > th,
.compare-products .table-bordered > tbody > tr > th,
.compare-products .table-bordered > tfoot > tr > th,
.compare-products .table-bordered > thead > tr > td,
.compare-products .table-bordered > tbody > tr > td,
.compare-products .table-bordered > tfoot > tr > td {
  border: solid 1px #e9e9e9;
}

.compare-products .product h4, .compare-products .product .h4,
.compare-products .product h4 a,
.compare-products .product .h4 a {
  font-weight: 300;
  color: #14181c;
}

.compare-products .product h4:hover, .compare-products .product .h4:hover,
.compare-products .product h4 a:hover,
.compare-products .product .h4 a:hover {
  color: var(--bs-primary);
}

.compare-products .buttons .btn-wish-list,
.compare-products .buttons .btn-compare {
  margin-top: 10px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 50px;
  height: 50px;
  border: solid 3px rgba(20, 24, 28, 0.1);
  background-color: transparent;
  color: #14181c;
}

.compare-products .buttons .btn-wish-list:hover,
.compare-products .buttons .btn-compare:hover {
  background-color: #14181c;
  color: #ffffff;
}

.form-extras {
  padding-bottom: 30px;
}

.form-extras .checkbox label {
  display: block;
  line-height: 18px;
  padding-left: 15px;
  color: #7f7f7f;
}

.reservation-now {
  padding-top: 30px;
  border-top: solid 1px #d3d3d3;
}

.reservation-now .checkbox label {
  line-height: 18px;
  color: #7f7f7f;
}

.reservation-now .btn + .btn {
  margin-right: 20px;
}

@media (max-width: 1199px) {
  .wishlist .form-sign-in .col-md-12.col-lg-6.text-right-lg {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .form-extras .left {
    position: relative;
  }
  .form-extras .left:after {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    right: -15px;
    top: 0;
    border-right: solid 1px #a5abb7;
  }
}

@media (min-width: 992px) {
  .form-sign-in .btn-block {
    max-width: 100%;
  }
}

/* Error page
  /* ========================================================================== */
.error-page {
  padding-top: 40px;
}

.error-page h2, .error-page .h2 {
  margin: 0 0 30px 0;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #14181c;
}

.error-page h2 .fa, .error-page .h2 .fa {
  color: #ea0000;
}

.error-page h3, .error-page .h3 {
  margin: 0 0 15px 0;
  color: #ea0000;
  font-size: 160px;
  line-height: 160px;
  font-weight: bold;
}

.error-page .error-icon .fa {
  width: 100px;
  height: 100px;
  font-size: 50px;
  text-align: center;
  line-height: 100px;
  border-radius: 50px;
  margin-bottom: 30px;
  background-color: #14181c;
  color: #ffffff;
}

/* 5.4 */
/* Social icons line
/* ========================================================================= */
.social-icons {
  margin: -15px 0 20px 0;
  line-height: 30px;
  overflow: hidden;
}

.social-icons li {
  padding: 15px 10px 0 0;
  display: inline-block;
}

.social-icons a {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: transparent;
  border: solid 1px #14181c;
  color: #14181c;
}

.social-icons a:hover {
  background-color: #4d0000;
  color: #ffffff;
}

.map-marker-icon {
  font-size: 60px;
}

/* 5.11 */
/* Tabs
/* ========================================================================== */
.tabs {
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.tabs ul {
  display: inline-block;
  position: relative;
  margin-top: -10px;
}

.tabs li {
  display: inline-block;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  background-color: #14181c;
}

.tabs li a {
  color: #a5abb7;
  display: block;
  padding: 15px 45px;
}

.tabs li a:hover {
  color: #a5abb7;
}

.tabs li a.active, .tabs li a:focus {
  background-color: var(--bs-primary);
  color: #ffffff;
}

.tabs.awesome-sub {
  overflow: visible;
}

.tabs.awesome-sub > .nav {
  width: 100%;
}

.tabs.awesome-sub ul {
  margin-top: 0;
}

.tabs.awesome-sub li {
  margin: 0;
  display: block;
  background-color: transparent;
  text-transform: none;
  font-weight: normal;
  text-align: left;
}

.tabs.awesome-sub li + li {
  border-top: solid 1px #d3d3d3;
}

.tabs.awesome-sub li a.active {
  background-color: var(--bs-primary);
  color: #ffffff;
}

.tabs.awesome-sub li a.active:before {
  content: '' !important;
  display: block !important;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid var(--bs-primary);
  border-bottom: 35px solid transparent;
  border-right: none;
  left: auto;
  right: 0;
  top: 0;
  margin-right: -35px;
  z-index: 2;
}

.tabs.awesome-sub li a {
  padding: 25px 15px;
  line-height: 20px;
  position: relative;
}

.tabs.awesome-sub li:first-child:before,
.tabs.awesome-sub li:last-child:before {
  display: none;
}

@media (max-width: 767px) {
  .tabs ul {
    padding-bottom: 15px;
    border-bottom: solid 1px #a5abb7;
  }
}

@media (min-width: 768px) {
  .tabs.awesome li:first-child a:before {
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    left: -24px;
    width: 0;
    height: 0;
    border-top: 26px solid transparent;
    border-right: 24px solid #14181c;
    border-bottom: 25px solid transparent;
  }
  .tabs.awesome li:first-child a.active::before {
    border-right: 24px solid var(--bs-primary);
  }
  .tabs.awesome li:last-child a:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -24px;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 25px solid #14181c;
    border-bottom: 25px solid transparent;
  }
  .tabs.awesome li:last-child a.active::before {
    border-left: 25px solid var(--bs-primary);
  }
  .tabs.awesome li + li {
    position: relative;
  }
  .tabs.awesome-sub li.active:first-child:before {
    border-right: none;
  }
  .tabs.awesome-sub li.active:last-child:before {
    border-left: 35px solid var(--bs-primary);
  }
}

@media (max-width: 767px) {
  .tabs li {
    display: block;
  }
}

@media (max-width: 1199px) {
  .tabs.awesome li:first-child:before,
  .tabs.awesome li:last-child:before {
    display: none;
  }
}

@media (max-width: 991px) {
  .tabs.awesome-sub li.active:before {
    display: none !important;
  }
}

/* Thumbnail banner
/* ========================================================================== */
.thumbnail.thumbnail-banner .media,
.thumbnail.thumbnail-banner .media .media-link,
.thumbnail.thumbnail-banner .media .caption {
  height: 100%;
}

.thumbnail.thumbnail-banner + .thumbnail.thumbnail-banner {
  margin-top: 30px;
}

.thumbnail.thumbnail-banner .media-link {
  text-align: left;
}

.thumbnail.thumbnail-banner .caption {
  padding: 0;
}

.thumbnail.thumbnail-banner .caption-wrapper {
  padding: 15px;
}

.thumbnail.thumbnail-banner .caption-inner {
  border: solid 1px #ffffff;
  padding: 15px;
}

.thumbnail.thumbnail-banner .btn {
  margin-top: 10px;
}

.thumbnail.thumbnail-banner .btn-theme {
  border-color: #14181c;
  background-color: #14181c;
}

.thumbnail.thumbnail-banner .btn-theme:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.thumbnail.thumbnail-banner.size-1x1 {
  height: 170px;
}

.thumbnail.thumbnail-banner.size-2x3 {
  height: 270px;
}

.thumbnail.thumbnail-banner.size-3x3 {
  height: 570px;
}

.thumbnail.thumbnail-banner.size-1x1-b {
  height: 270px;
}

.thumbnail.thumbnail-banner.size-1x3-b {
  height: 270px;
}

.col-md-3 .thumbnail.thumbnail-banner .caption-inner {
  padding: 15px 2px;
}

.col-md-3 .thumbnail.thumbnail-banner .caption-sub-title span {
  padding: 8px 7px;
}

.thumbnail.thumbnail-banner .caption-title,
.thumbnail.thumbnail-banner .caption-sub-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0 0 1px 0;
  color: #14181c;
}

.thumbnail.thumbnail-banner .caption-title span,
.thumbnail.thumbnail-banner .caption-sub-title span {
  display: inline-block;
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.8);
}

.thumbnail-banner .media img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  position: relative;
}

.thumbnail-banner .media-link .img-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url("");
  background-position: 50% 50%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.thumbnail-banner .media-link:hover .img-bg {
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}

.thumbnail-banner .caption {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
  background-color: rgba(230, 0, 0, 0);
}

.thumbnail-banner.alt-font .caption-title {
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
}

.thumbnail-banner.alt-font .caption-title span {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.3);
}

.thumbnail-banner.alt-font .caption-sub-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.thumbnail-banner.alt-font .caption-sub-title span {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.3);
}

.thumbnail-banner.alt-font .btn-theme-sm {
  font-size: 11px;
}

.thumbnail-banner.alt-font.big-text .caption-title {
  font-size: 48px;
}

.thumbnail-banner.alt-font.big-text .caption-sub-title {
  font-size: 44px;
}

.thumbnail-banner.hover .media img,
.thumbnail-banner:hover .media img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.thumbnail-banner.hover .btn-theme,
.thumbnail-banner:hover .btn-theme {
  -webkit-animation: pulse .4s;
  animation: pulse .4s;
}

.thumbnail-banner.hover .caption,
.thumbnail-banner:hover .caption {
  background-color: rgba(230, 0, 0, 0.3);
}

@media (min-width: 992px) and (max-width: 1999px) {
  .thumbnail.thumbnail-banner.size-1x1 {
    height: 190px;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .thumbnail.thumbnail-banner.size-1x1 {
    max-width: 280px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .thumbnail.thumbnail-banner.size-1x1 {
    height: 190px;
  }
}

.thumbnail.thumbnail-banner.size-1x3 {
  height: 170px;
}

@media (min-width: 992px) and (max-width: 1999px) {
  .thumbnail.thumbnail-banner.size-1x3 {
    height: 190px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .thumbnail.thumbnail-banner.size-1x3 {
    height: 190px;
  }
}

@media (max-width: 991px) {
  .thumbnail.thumbnail-banner {
    max-width: 555px;
  }
}

@media (max-width: 640px) {
  .thumbnail-banner.alt-font.big-text .caption-sub-title {
    font-size: 24px;
  }
}

@media (max-width: 640px) {
  .thumbnail-banner.alt-font.big-text .caption-title {
    font-size: 28px;
  }
}

/* Thumbnail counto
/* ========================================================================== */
.thumbnail.thumbnail-counto .caption {
  padding: 30px 20px;
  text-align: center;
}

.thumbnail.thumbnail-counto .caption-icon {
  margin-bottom: 30px;
  text-align: center;
  color: #7f7f7f;
}

.thumbnail.thumbnail-counto .caption-icon .fa {
  font-size: 60px;
}

.thumbnail.thumbnail-counto .caption-number {
  font-size: 18px;
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
  line-height: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #7f7f7f;
}

.thumbnail.thumbnail-counto .caption-title {
  font-size: 13px;
  font-weight: 900;
  line-height: 13px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #7f7f7f;
}

.thumbnail.thumbnail-counto.hover .caption-icon,
.thumbnail.thumbnail-counto:hover .caption-icon,
.thumbnail.thumbnail-counto.hover .caption-number,
.thumbnail.thumbnail-counto:hover .caption-number,
.thumbnail.thumbnail-counto.hover .caption-title,
.thumbnail.thumbnail-counto:hover .caption-title {
  color: var(--bs-primary);
}

/* Thumbnail hot deal
  /* ========================================================================== */
.thumbnail-hot-deal .caption {
  padding-top: 30px;
}

.thumbnail-hot-deal .caption-title {
  font-weight: 500;
  margin-bottom: 15px;
}

.thumbnail-hot-deal .price {
  margin-top: 15px;
  font-size: 24px;
  font-weight: 900;
}

.thumbnail-hot-deal .price del {
  font-size: 22px;
  font-weight: 400;
}

.hot-deals-carousel .owl-theme .owl-controls .owl-dots .owl-dot span {
  background-color: #a5abb7;
  border-radius: 0;
  margin-left: 1px;
  margin-right: 2px;
}

.hot-deals-carousel .owl-theme .owl-controls .owl-dots .owl-dot:hover span,
.hot-deals-carousel .owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background-color: #14181c;
}

/* Thumbnail featured
/* ========================================================================== */
.thumbnail.thumbnail-featured {
  background: #e9e9e9;
}

.thumbnail.thumbnail-featured .caption {
  padding: 35px 20px;
}

.thumbnail.thumbnail-featured .caption.hovered {
  opacity: 1;
  z-index: 10;
  background-color: #e9e9e9;
}

.thumbnail.thumbnail-featured .caption.hovered .caption-icon {
  color: #14181c;
}

.thumbnail.thumbnail-featured .caption-wrapper {
  padding: 0;
}

.thumbnail.thumbnail-featured .caption-inner {
  padding: 0;
}

.thumbnail.thumbnail-featured .caption-icon {
  margin-bottom: 20px;
  text-align: center;
}

.thumbnail.thumbnail-featured .caption-icon .fa {
  font-size: 90px;
}

.thumbnail.thumbnail-featured .caption-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #14181c;
}

.thumbnail.thumbnail-featured .caption-text {
  font-size: 15px;
  line-height: 24px;
}

.thumbnail.thumbnail-featured .buttons {
  margin-top: 30px;
}

.thumbnail.thumbnail-featured .buttons .btn {
  font-size: 14px;
  padding: 15px 45px;
}

.thumbnail.thumbnail-featured span {
  padding: 0 8px;
  background-color: rgba(0, 0, 0, 0);
}

.thumbnail.thumbnail-featured .btn-theme {
  border-width: 1px;
  border-color: #3c3c3c;
  color: #3c3c3c;
}

.thumbnail.thumbnail-featured.hover,
.thumbnail.thumbnail-featured:hover {
  background: var(--bs-primary);
}

.thumbnail.thumbnail-featured.hover .caption-icon,
.thumbnail.thumbnail-featured:hover .caption-icon,
.thumbnail.thumbnail-featured.hover .caption-title,
.thumbnail.thumbnail-featured:hover .caption-title,
.thumbnail.thumbnail-featured.hover .caption-text,
.thumbnail.thumbnail-featured:hover .caption-text {
  color: #ffffff;
}

.thumbnail.thumbnail-featured.hover .btn-theme,
.thumbnail.thumbnail-featured:hover .btn-theme {
  border-width: 1px;
  border-color: #ffffff;
  color: #ffffff;
}

.thumbnail.thumbnail-featured.hover .caption.hovered,
.thumbnail.thumbnail-featured:hover .caption.hovered {
  opacity: 0;
}

/* Thumbnail product
/* ========================================================================== */
.thumbnail .rating {
  display: inline-block;
  font-size: 13px;
}

.thumbnail .rating span.star:before {
  padding-right: 3px;
}

.thumbnail .price {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 15px;
}

.thumbnail .price del {
  font-weight: normal;
  font-size: 14px;
  color: #14181c;
}

.thumbnail .price ins {
  padding-right: 5px;
  text-decoration: none;
  color: var(--bs-primary);
}

.thumbnail .buttons .btn {
  padding: 10px 12px;
  font-size: 12px;
  line-height: 18px;
}

.thumbnail .buttons .btn-wish-list {
  margin-right: 10px;
}

.thumbnail .buttons .btn-compare {
  margin-left: 10px;
}

.thumbnail .btn-wish-list {
  opacity: 0;
}

.thumbnail .btn-compare {
  opacity: 0;
}

.thumbnail.category .media-link:after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.thumbnail.category .caption {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
}

.thumbnail.category .caption-wrapper {
  padding: 5px;
}

.thumbnail.category .caption-inner {
  padding: 0;
}

.thumbnail.category .sale {
  font-size: 36px;
  font-weight: 900;
  line-height: 40px;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: #ffffff;
}

.thumbnail.category .caption-title {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ffffff;
}

.thumbnail.category .items {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-style: italic;
  color: #ffffff;
}

.thumbnail.category .media img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  position: relative;
}

.thumbnail.category span {
  padding: 0 8px;
  background-color: rgba(0, 0, 0, 0);
}

.thumbnail.category .btn-theme {
  border-width: 1px;
  border-color: #ffffff;
  color: #ffffff;
}

.thumbnail .buttons .btn .fa,
.thumbnail .buttons .btn .glyphicon {
  font-size: 16px !important;
}

.thumbnail.hover .btn-wish-list,
.thumbnail:hover .btn-wish-list {
  opacity: 1;
}

.thumbnail.hover .btn-compare,
.thumbnail:hover .btn-compare {
  opacity: 1;
}

.thumbnail.category.hover .media img,
.thumbnail.category:hover .media img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.thumbnail.category.hover span,
.thumbnail.category:hover span {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
}

.thumbnail.category.hover .btn-theme,
.thumbnail.category:hover .btn-theme {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #14181c;
}

.thumbnail.category.hover .media-link:after,
.thumbnail.category:hover .media-link:after {
  background-color: rgba(0, 0, 0, 0);
}

.top-products-carousel .buttons .btn {
  padding: 5px;
  font-size: 10px;
}

.top-products-carousel .buttons .btn-wish-list {
  margin-right: 5px;
}

.top-products-carousel .buttons .btn-compare {
  margin-left: 5px;
}

.top-products-carousel .owl-theme .owl-controls {
  margin-top: 0;
}

.top-products-carousel .owl-theme .owl-controls .owl-nav [class*="owl-"] {
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.top-products-carousel .owl-theme .owl-controls .owl-dots {
  line-height: 1;
}

.top-products-carousel .owl-prev {
  left: -50px;
  margin-top: -16px !important;
}

.top-products-carousel .owl-next {
  right: -50px;
  margin-top: -16px !important;
}

.top-products-carousel .owl-prev,
.top-products-carousel .owl-next {
  position: absolute;
  top: 50%;
  border: solid 3px #e9e9e9;
  color: #e9e9e9;
  height: 37px;
  width: 37px;
  line-height: 37px;
  text-align: center;
}

.top-products-carousel .owl-prev .fa,
.top-products-carousel .owl-next .fa {
  color: #e9e9e9;
  font-size: 24px !important;
  line-height: 30px;
}

.top-products-carousel .owl-prev:hover,
.top-products-carousel .owl-next:hover {
  border-color: #000000;
  color: #000000;
}

.top-products-carousel .owl-prev:hover .fa,
.top-products-carousel .owl-next:hover .fa {
  color: #000000;
}

.product-single .badges {
  position: absolute;
  top: 20px;
  right: 35px;
  width: 50px;
  z-index: 2;
}

.product-single .badges div {
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  margin-bottom: 10px;
  border: solid 3px #14181c;
  text-transform: uppercase;
  text-align: center;
  color: #14181c;
}

.product-single .badges div.hot {
  border-color: #ea0000;
  color: #ea0000;
}

.product-single .btn-zoom {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  padding: 0;
  font-size: 12px;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #ffffff;
  color: #ffffff;
}

.product-single .btn-zoom .fa {
  width: 30px;
}

.product-single .btn-zoom:hover {
  background-color: rgba(20, 24, 28, 0.9);
  border-color: #14181c;
  color: #ffffff;
}

.product-single .owl-theme .owl-controls {
  margin: 0 !important;
}

.product-single .owl-theme .owl-controls .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin: -20px 0 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  font-size: 30px;
  line-height: 30px;
  border: solid 3px #14181c;
  background: transparent;
  color: #14181c;
}

.product-single .owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
  background: #14181c;
  color: #ffffff;
}

.product-single .owl-theme .owl-controls .owl-nav .owl-prev {
  left: 30px;
}

.product-single .owl-theme .owl-controls .owl-nav .owl-next {
  right: 30px;
}

.product-single .owl-theme .owl-controls .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.product-single .owl-theme .owl-controls .owl-dots .owl-dot span {
  background-color: #a5abb7;
}

.product-single .product-thumbnails {
  margin-left: 0;
  margin-right: -10px;
}

.product-single .product-thumbnails > div {
  padding: 0;
}

.product-single .product-thumbnails a {
  display: block;
  margin: 10px 10px 0 0;
}

.product-single .product-thumbnails img {
  width: 100%;
  max-width: 100%;
  display: block;
}

.product-single .back-to-category {
  overflow: hidden;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: solid 1px #d3d3d3;
}

.product-single .back-to-category .link {
  display: inline-block;
  margin-top: 12px;
  color: #14181c;
}

.product-single .back-to-category .btn-next {
  margin-left: 9px;
}

.product-single .product-title {
  margin-top: 0;
  font-size: 30px;
  font-weight: normal;
  color: #14181c;
}

.product-single .product-rating .rating {
  margin-right: 15px;
}

.product-single .product-availability {
  color: #14181c;
}

.product-single .product-availability strong {
  color: var(--bs-primary);
}

.product-single .product-text {
  font-size: 14px;
  line-height: 18px;
}

.product-single .product-price {
  font-size: 36px;
  font-weight: 900;
  line-height: 36px;
  color: #14181c;
}

.product-single .product-qty {
  margin-bottom: 15px;
}

.product-single .product-qty .form-control {
  display: inline-block !important;
}

.product-single .product-qty .form-control.qty {
  height: 36px;
  width: 50px;
  text-align: center;
  margin-right: 5px;
}

.product-single .product-qty .form-group {
  display: inline-block;
  margin-bottom: 10px;
}

.product-single .product-qty label {
  display: block;
  line-height: 35px;
  margin-right: 5px;
}

.product-single .product-qty .btn {
  vertical-align: top;
}

.product-single form.variable label {
  text-transform: uppercase;
  color: #14181c;
}

.product-single form.variable .filter-option {
  color: #14181c;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
}

.product-single .buttons {
  margin-top: -10px;
  overflow: hidden;
}

.product-single .buttons .quantity {
  overflow: hidden;
  margin-right: 5px;
}

.product-single .buttons .quantity .btn {
  margin-top: 10px;
  margin-right: 1px;
  background-color: #14181c;
  color: #7f7f7f;
  height: 60px;
}

.product-single .buttons .quantity .btn:hover {
  color: #ffffff;
}

.product-single .buttons .quantity .qty {
  margin-top: 10px;
  margin-right: 1px;
  width: 40px;
  height: 60px;
  background-color: #14181c;
  border-color: #14181c;
  color: #ffffff;
}

.product-single .buttons .btn-cart {
  margin-top: 10px;
  margin-right: 5px;
  max-width: 100%;
  background-color: #14181c;
  border-color: #14181c;
  border-width: 3px;
  color: #ffffff;
  height: 60px;
  width: 270px;
}

.product-single .buttons .btn-cart:hover {
  background-color: transparent;
  color: #14181c;
}

.product-single table .title {
  color: #14181c;
}

.product-single table td {
  padding: 0 10px 0 0;
}

.product-single .social-icons a {
  border-width: 2px;
  line-height: 25px;
}

.product-single .owl-theme .owl-controls .owl-dots .owl-dot:hover span,
.product-single .owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background-color: #14181c;
}

.product-single .back-to-category .btn-previous,
.product-single .back-to-category .btn-next {
  padding: 0;
  font-size: 30px;
  line-height: 30px;
  color: #e9e9e9;
}

.product-single .back-to-category .btn-previous .fa,
.product-single .back-to-category .btn-next .fa {
  width: 30px;
}

.product-single .reviews,
.product-single .add-review {
  color: #14181c;
}

.product-single .reviews:hover,
.product-single .add-review:hover {
  color: var(--bs-primary);
}

.product-single .buttons .btn-wish-list,
.product-single .buttons .btn-compare {
  margin-top: 10px;
  margin-right: 5px;
  width: 60px;
  height: 60px;
  border: solid 3px rgba(20, 24, 28, 0.1);
  background-color: transparent;
  color: #14181c;
}

.product-single .buttons .btn-wish-list:hover,
.product-single .buttons .btn-compare:hover {
  background-color: #14181c;
  color: #ffffff;
}

.bootstrap-select.btn-group .dropdown-menu {
  border-width: 3px;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  font-size: 11px;
  text-transform: uppercase;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: var(--bs-primary);
}

@media (max-width: 639px) {
  .product-single .owl-theme .owl-controls {
    display: none;
  }
}

@media (max-width: 767px) {
  .product-single .product-rating .rating {
    float: none;
    display: block;
    margin-right: 0;
  }
}

/* Products list view
  /* ========================================================================== */
.products.list .thumbnail + .thumbnail {
  margin-top: 30px;
}

.products.list .thumbnail .caption {
  padding-top: 0;
}

.products.list .thumbnail .caption-title {
  font-weight: 400;
  margin-bottom: 15px;
}

.products.list .thumbnail .reviews {
  color: #14181c;
}

.products.list .thumbnail .reviews:hover {
  color: var(--bs-primary);
}

.products.list .thumbnail .overflowed {
  margin-top: 5px;
}

.products.list .thumbnail .availability strong {
  color: var(--bs-primary);
}

.products.list .thumbnail .price {
  margin-bottom: 0;
}

.products.list .thumbnail .caption-text {
  font-size: 14px;
  line-height: 18px;
  padding: 15px 0;
  margin: 15px 0;
  border-top: solid 1px rgba(20, 24, 28, 0.1);
  border-bottom: solid 1px rgba(20, 24, 28, 0.1);
}

.products.list .thumbnail .btn-wish-list {
  margin-left: 10px;
  margin-right: 0;
}

.products.wall {
  width: 1980px;
  height: 550px;
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.products.wall .isotope-item {
  margin-top: 10px;
  margin-right: 10px;
}

.products.wall .col {
  margin-right: 10px;
}

.products.wall .w270 {
  width: 270px;
  padding: 0;
}

.products.wall .w370 {
  width: 370px;
  padding: 0;
}

.products.wall .caption.hovered {
  padding-left: 0;
  padding-right: 0;
  background-color: rgba(20, 24, 28, 0.6);
}

.products.wall .rating span.star:before {
  content: "\f005";
  color: #a5abb7;
}

.products.wall .price ins {
  color: #ffffff;
  font-size: 15px;
}

.products.wall .price del {
  color: #ffffff;
  font-size: 14px;
}

.products.wall .buttons .btn {
  color: #ffffff;
  padding: 7px 9px;
}

.products.wall .caption-title,
.products.wall .caption-title a {
  color: #ffffff;
}

.products.wall .rating span.star.active:before,
.products.wall .rating span.star:hover:before,
.products.wall .rating span.star:hover ~ span.star:before {
  content: "\f005";
  color: #ffffff;
}

/* 5.11 */
/* Thumbnails
/* ========================================================================== */
.thumbnail {
  width: fit-content;
  margin: 0 auto;
  border-radius: 0;
  background-color: transparent;
  position: relative;
}

.thumbnail.no-padding {
  padding: 0;
}

.thumbnail.hover,
.thumbnail:hover {
  border: solid 1px rgba(230, 0, 0, 0.3);
}

.thumbnail.no-border,
.thumbnail.no-border.hover,
.thumbnail.no-border:hover {
  border: none;
}

.row.thumbnails {
  margin-top: -30px;
}

.row.thumbnails .thumbnail {
  margin-top: 30px;
}

.row.thumbnails.no-padding {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.row.thumbnails.no-padding [class*='col-'] {
  padding: 0;
}

.row.thumbnails.no-padding .thumbnail {
  margin-top: 0;
}

@media (max-width: 991px) {
  .thumbnail {
    max-width: 450px;
  }
}

/* Thumbnail Media/Image
  /* ========================================================================================= */
.thumbnail .media {
  overflow: hidden;
  position: relative;
}

.thumbnail .media img {
  max-width: 100%;
  width: 100%;
}

/* Thumbnail caption
  /* ========================================================================================= */
.thumbnail .caption {
  padding: 15px 0 0 0;
  overflow: hidden;
  max-width: 550px;
}

.thumbnail .caption + .caption {
  padding-top: 10px;
}

.thumbnail .caption.no-padding-top {
  padding-top: 0;
}

.thumbnail .caption.no-padding-bottom {
  padding-bottom: 0;
}

.thumbnail .caption.hovered {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  overflow: hidden;
  padding: 15px;
  color: #ffffff;
  opacity: 0;
  z-index: 10;
}

.thumbnail.hover .caption.hovered,
.thumbnail:hover .caption.hovered {
  opacity: 1;
}

.caption-wrapper {
  width: 100%;
}

/* Caption elements
  /* ============================================================================================ */
.caption-title {
  font-size: 18px;
  font-weight: 900;
  line-height: 20px;
  margin: 0 0 5px 0;
  color: #14181c;
}

.caption-title a {
  color: #14181c;
}

.caption-title a:hover {
  color: var(--bs-primary);
}

.icon-view {
  width: 80px;
  height: 80px;
  padding: 5px;
  border: solid 1px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: inline-block;
}

.icon-view strong {
  display: block;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
}

.icon-view .fa,
.icon-view .glyphicon {
  font-size: 24px;
  line-height: 68px;
}

.thumbnail .media-link {
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.thumbnail .media-link .icon-view {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  opacity: 0;
  -webkit-animation: fadeOut .4s;
  animation: fadeOut .4s;
}

.thumbnail .media-link:hover .icon-view {
  opacity: 1;
  -webkit-animation: bounceIn .4s;
  animation: bounceIn .4s;
}

/* Thumbnail transition
/* ============================================================================================ */
.thumbnail,
.thumbnail:hover,
.thumbnail.hover,
.thumbnail span,
.thumbnail:hover span,
.thumbnail.hover span,
.thumbnail .media,
.thumbnail:hover .media,
.thumbnail.hover .media,
.thumbnail .media img,
.thumbnail:hover .media img,
.thumbnail.hover .media img,
.thumbnail .media-link:after,
.thumbnail:hover .media-link:after,
.thumbnail.hover .media-link:after,
.thumbnail .icon-view,
.thumbnail:hover .icon-view,
.thumbnail.hover .icon-view,
.thumbnail .icon-view .fa,
.thumbnail:hover .icon-view .fa,
.thumbnail.hover .icon-view .fa,
.thumbnail .icon-view .glyphicon,
.thumbnail:hover .icon-view .glyphicon,
.thumbnail.hover .icon-view .glyphicon,
.thumbnail .caption,
.thumbnail:hover .caption,
.thumbnail.hover .caption,
.thumbnail .caption-title,
.thumbnail:hover .caption-title,
.thumbnail.hover .caption-title,
.thumbnail .caption-icon,
.thumbnail:hover .caption-icon,
.thumbnail.hover .caption-icon,
.thumbnail .caption-text,
.thumbnail:hover .caption-text,
.thumbnail.hover .caption-text,
.thumbnail .caption-zoom,
.thumbnail:hover .caption-zoom,
.thumbnail.hover .caption-zoom,
.thumbnail .caption-link,
.thumbnail:hover .caption-link,
.thumbnail.hover .caption-link,
.thumbnail .caption-category,
.thumbnail:hover .caption-category,
.thumbnail.hover .caption-category,
.thumbnail .caption-price,
.thumbnail:hover .caption-price,
.thumbnail.hover .caption-price,
.thumbnail .caption-wrapper,
.thumbnail:hover .caption-wrapper,
.thumbnail.hover .caption-wrapper,
.thumbnail .buttons,
.thumbnail:hover .buttons,
.thumbnail.hover .buttons {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* Thumbnail team
/* ========================================================================== */
.thumbnail-team {
  background-color: #ffffff;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.thumbnail-team .caption {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.thumbnail-team .caption-title {
  padding-bottom: 20px;
  margin-bottom: 15px;
  position: relative;
}

.thumbnail-team .caption-title:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 1px;
  background-color: #d3d3d3;
}

.thumbnail-team .caption-title small, .thumbnail-team .caption-title .small {
  display: block;
  margin-top: 5px;
  color: #7f7f7f;
}

.thumbnail-team .social-icons {
  display: inline-block;
  margin-bottom: 10px;
}

.thumbnail-team .social-icons a {
  border-radius: 15px;
}

.thumbnail-team .caption-text {
  font-size: 15px;
}

.thumbnail-team .caption-text + .team-details {
  margin-top: 10px;
}

.thumbnail-team .team-details {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  color: #7f7f7f;
}

.thumbnail-team .team-details + .caption-text {
  margin-top: 10px;
}

/* 5.13 */
/* Widgets
/* ========================================================================== */
.widget-title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 900;
  line-height: 20px;
  text-transform: uppercase;
  color: #7f7f7f;
  overflow: hidden;
}

.widget-title-sub {
  margin: 0 0 20px 0;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: dashed 1px #ececec;
  color: #6e757f;
}

.widget.shadow {
  background-color: #ffffff;
  border: solid 1px #f0f0f0;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.widget.shadow .widget-title {
  padding: 25px 20px;
  margin: 0;
  background-color: #f5f6f8;
  color: #6c7481;
}

.widget.shadow .widget-title-sub {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.widget.shadow .widget-content {
  padding: 20px;
}

/* Widget colors
  /* ========================================================================== */
.widget.widget-colors {
  border: solid 3px rgba(20, 24, 28, 0.1);
}

.widget.widget-colors .widget-title {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.widget.widget-colors .widget-content {
  border-top: solid 1px rgba(20, 24, 28, 0.1);
  padding: 10px 15px;
}

.widget.widget-colors ul {
  overflow: hidden;
  margin-top: -7px;
}

.widget.widget-colors ul li {
  display: inline-block;
  margin: 7px 7px 0 0;
}

.widget.widget-colors ul li a {
  display: block;
  width: 35px;
  height: 35px;
  border: solid 3px rgba(20, 24, 28, 0.1);
  padding: 3px;
}

.widget.widget-colors ul li a span {
  display: block;
  width: 23px;
  height: 23px;
}

.widget.widget-colors ul li a:hover {
  border-color: #14181c;
}

/* Widget filter price
  /* ========================================================================== */
.widget.widget-filter-price .widget-content {
  border-top: solid 1px rgba(20, 24, 28, 0.1);
  padding: 20px 15px;
  overflow: hidden;
}

.widget.widget-filter-price #slider-range {
  margin-top: 5px;
  margin-bottom: 25px;
  border-radius: 0;
  border: none;
  background-color: #e9e9e9;
  background-image: none;
  height: 6px;
}

.widget.widget-filter-price #slider-range .ui-widget-header {
  background-color: var(--bs-primary);
  background-image: none;
  height: 4px;
}

.widget.widget-filter-price #slider-range .ui-slider-handle {
  background-image: none;
  background-color: #14181c;
  border: solid 3px #14181c;
  border-radius: 0;
  width: 8px;
  height: 15px;
  margin-left: 0;
}

.widget.widget-filter-price #amount {
  text-align: right;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 700;
  color: #7f7f7f;
  width: 120px;
  line-height: 33px;
}

.widget.widget-filter-price .btn-submit {
  margin-top: 10px;
}

.widget.widget-find .btn-submit {
  margin-top: 20px;
}

.widget.widget-find .btn-theme-dark:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: #ffffff;
}

.widget.widget-find .btn-theme-dark,
.widget.widget-find .btn-theme-dark:focus,
.widget.widget-find .btn-theme-dark:active {
  background-color: #14181c;
  border-color: #14181c;
  color: #ffffff;
}

/* Widget shop deals
  /* ========================================================================== */
.widget.widget-shop-deals .countdown-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.widget.widget-shop-deals .defaultCountdown {
  background-color: transparent;
  border-color: transparent;
  border: none;
  width: 100%;
}

.widget.widget-shop-deals .countdown-row {
  overflow: hidden;
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.widget.widget-shop-deals .countdown-section {
  background-color: rgba(0, 0, 0, 0.5);
}

.widget.widget-shop-deals .countdown-show4 .countdown-section {
  padding-top: 8px;
  padding-bottom: 9px;
  width: 25% !important;
}

.widget.widget-shop-deals .countdown-amount {
  margin: 0 0 0 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: var(--bs-primary);
}

.widget.widget-shop-deals .countdown-period {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #d3d3d3;
  clear: both;
  margin: 0 0 0 0;
  display: block;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .blog-tabs {
    text-decoration: none;
    position: relative;
  }
  .blog-tabs.active {
    background-color: #14181c !important;
  }
  .blog-tabs.active::before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    border-top: solid 5px var(--bs-primary);
  }
}

.widget.widget-tabs .nav-justified {
  border: solid 3px rgba(20, 24, 28, 0.1);
}

.widget.widget-tabs .nav-justified li a {
  color: #14181c;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.widget.widget-tabs .tab-content {
  overflow: hidden;
}

.widget.widget-tabs .tab-content + .btn-block {
  margin: 20px auto 0 auto;
}

.widget.widget-tabs.alt {
  margin-top: 9px;
}

.widget.widget-tabs.alt .nav-justified {
  margin-top: 2px;
  border: none;
}

.widget.widget-tabs.alt .nav-justified > li > a {
  color: #ffffff;
  background-color: #a5abb7;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
}

.widget.widget-tabs.alt .tab-content {
  margin-top: 20px;
  overflow: hidden;
}

.widget.widget-tabs.alt .tab-content + .btn-block {
  margin: 20px auto 0 auto;
}

.widget #widget-tabs li {
  display: table;
  width: 100%;
}

.widget #widget-tabs li a {
  display: table-cell;
  text-align: center;
  padding: 6px 0;
}

.widget #widget-tabs li a.active, .widget #widget-tabs li a:hover, .widget #widget-tabs li a:focus {
  position: relative;
}

.widget #widget-tabs li a.active::before, .widget #widget-tabs li a:hover::before, .widget #widget-tabs li a:focus::before {
  content: '';
  display: block;
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: 5px;
  border-top: solid 3px var(--bs-primary);
}

.widget #widget-tabs li a.active::after, .widget #widget-tabs li a:hover::after, .widget #widget-tabs li a:focus::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 5px;
  border-bottom: solid 3px var(--bs-primary);
}

.widget #tabs a {
  padding: 12px 30px;
}

.widget.widget-tabs .nav-justified > li > a.active,
.widget.widget-tabs .nav-justified > li > a:hover,
.widget.widget-tabs .nav-justified > li > a:focus {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  color: #ffffff;
}

.widget.widget-tabs.alt .nav-justified > li > a.active,
.widget.widget-tabs.alt .nav-justified > li > a:hover,
.widget.widget-tabs.alt .nav-justified > li > a:focus {
  border-color: #14181c;
  background-color: #14181c;
  color: #ffffff;
}

@media (min-width: 768px) {
  .widget.widget-tabs.alt .nav-justified > li > a.active {
    position: relative;
  }
  .widget.widget-tabs.alt .nav-justified > li > a.active :before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    border-top: solid 5px var(--bs-primary);
  }
}

@media (min-width: 768px) {
  .widget.widget-tabs.alt .nav-justified > li + li {
    border-left: solid 1px transparent;
  }
}

/* Widget search
  /* ========================================================================== */
.widget-search {
  max-width: 100%;
  position: relative;
}

.widget-search .form-control {
  width: 100%;
  height: 40px;
  line-height: 40px;
}

.widget-search button {
  position: absolute;
  right: 3px;
  top: 3px;
  background-color: #ffffff;
  border: none;
  padding: 2px 8px;
  line-height: 30px;
  font-size: 16px;
  color: #14181c;
}

.widget-search button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -34px;
  width: 34px;
  height: 34px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

/* Widget categories
  /* ========================================================================== */
.widget-categories ul li + li {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.widget-categories ul li a {
  display: block;
  padding: 5px 0 5px 20px;
  position: relative;
}

.widget-categories ul li a:before {
  content: '\f0a9';
  font-family: 'FontAwesome';
  position: absolute;
  left: 0;
  top: 5px;
}

.widget-categories ul li a:hover:before {
  -webkit-animation: bounceIn .4s;
  animation: bounceIn .4s;
}

/* Widget tabs
  /* ========================================================================== */
.widget.parking-categories .widget-content {
  border-top: solid 1px rgba(20, 24, 28, 0.1);
  padding: 20px 15px;
}

.widget.parking-categories ul {
  margin: -10px -15px -10px -15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  text-transform: uppercase;
}

.widget.parking-categories ul li {
  padding: 10px 15px;
  overflow: hidden;
}

.widget.parking-categories ul li + li {
  border-top: solid 1px rgba(20, 24, 28, 0.1);
}

.widget.parking-categories ul a {
  display: block;
  margin-right: 20px;
  color: #14181c;
}

.widget.parking-categories ul a:hover {
  color: var(--bs-primary);
}

.widget.parking-categories ul a .count {
  float: right;
  text-align: right;
  font-size: 12px;
  margin-right: 2px;
}

.widget.parking-categories ul ul.children {
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  text-transform: none;
  margin-top: 5px;
  padding-left: 30px;
}

.widget.parking-categories ul ul.children li {
  padding: 7px 15px 7px 0;
}

.widget.parking-categories ul ul.children a {
  margin-right: 0;
}

.widget.parking-categories ul .count {
  float: right;
  font-size: 12px;
  margin-right: 2px;
}

.widget.parking-categories ul .arrow {
  float: right;
  border: solid 1px #14181c;
  color: #14181c;
  width: 17px;
  height: 17px;
  cursor: pointer;
  text-align: center;
}

.widget.parking-categories ul .arrow .fa {
  font-size: 17px;
  line-height: 14px;
}

/* Widget Details Reservation
  /* ========================================================================== */
.widget.widget-details-reservation .widget-content {
  padding-top: 0;
}

.widget.widget-details-reservation .media .media-object {
  font-size: 14px;
}

.widget.widget-details-reservation .media .media-object .fa {
  font-size: 13px;
  vertical-align: top;
  margin-top: 5px;
}

.widget.widget-details-reservation .media + .media {
  margin-top: 0;
}

.widget.widget-details-reservation .widget-title-sub {
  padding-top: 30px;
}

.widget.widget-details-reservation .button {
  margin-top: 40px;
}

/* Widget Helping Center
  /* ========================================================================== */
.widget.widget-helping-center .widget-title-sub {
  border-top: dashed 1px #ececec;
  font-size: 24px;
  font-weight: 400;
}

.widget.widget-helping-center a:not(.btn) {
  color: #7f7f7f;
}

.widget.widget-helping-center a:not(.btn):hover {
  color: #3c3c3c;
}

.helping-center-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  background-color: #f5f6f8;
}

.helping-center-line h4, .helping-center-line .h4 {
  display: inline-block;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  color: #6d737f;
  float: left;
  margin-top: 15px;
}

.helping-center-line span {
  display: inline-block;
  font-size: 20px;
  margin-top: 5px;
}

/* Widget tag cloud
  /* ========================================================================== */
.widget-tag-cloud ul {
  overflow: hidden;
  margin-top: -7px;
}

.widget-tag-cloud ul li {
  display: inline-block;
  margin-top: 7px;
  margin-right: 5px;
}

.widget-tag-cloud ul li a {
  display: block;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 22px;
  color: #14181c;
  border: solid 3px rgba(20, 24, 28, 0.1);
}

.widget-tag-cloud ul li a:hover {
  color: #ffffff;
  background-color: #14181c;
  border-color: #14181c;
}

.widget-flickr-feed ul {
  overflow: hidden;
  margin-left: -10px;
  margin-bottom: -10px;
}

.widget-flickr-feed ul li {
  display: inline-block;
  margin: 0 0 10px 10px;
}

.widget-flickr-feed ul a {
  display: block;
  border: solid 3px rgba(20, 24, 28, 0.1);
  overflow: hidden;
  position: relative;
}

.widget-flickr-feed ul a:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
}

.widget-flickr-feed ul a:after {
  content: '\f002';
  font-size: 14px;
  font-family: 'FontAwesome';
  position: absolute;
  width: 14px;
  height: 14px;
  margin-left: -7px;
  margin-top: -9px;
  text-align: center;
  top: 50%;
  left: 50%;
  color: #ffffff;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.widget-flickr-feed ul a:hover {
  border-color: var(--bs-primary);
}

.widget-flickr-feed ul a:hover:before {
  background-color: rgba(230, 0, 0, 0.7);
}

.widget-flickr-feed ul a:hover:after {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.widget-flickr-feed ul a img {
  width: 74px;
  height: auto;
}

@media (max-width: 1199px) {
  .widget-flickr-feed ul a img {
    width: 58px;
  }
}

@media (max-width: 991px) {
  .widget-flickr-feed ul a img {
    width: 70px;
  }
}

@media (max-width: 767px) {
  .widget-flickr-feed ul a img {
    width: 70px;
  }
}

/* Recent Tweets Widget
  /* ========================================================================== */
/* ========================================================================== */
.recent-tweets .media .fa {
  color: var(--bs-primary);
}

.recent-tweets .media p {
  margin-bottom: 0;
}

.recent-tweets .media small, .recent-tweets .media .small {
  color: #d3d3d3;
}

/*# sourceMappingURL=style.css.map */